.about-us-page {
    display: flex;
    flex-direction: column;
    width:84.7vw;
    margin: 0 auto;
    position: relative;
    z-index: 0;
}


.about-us-first-logo-background {
    position: absolute;
    top: 0;
    left: 80vw;
    width: 90%;
    height: auto;
    z-index: 0; /* Place the image behind the content */
    opacity: 40%;
}

.about-us-second-logo-background {
    position: absolute;
    top: 130%;
    right: 50vw;
    width: 108%;
    height: auto;
    z-index: 0; /* Place the image behind the content */
    opacity: 40%;
}

.section-missao {
    margin-top: 11.5vh;
}

.about-header-div, .about-values-div {
    padding-bottom: 7.8rem;
}

.about-values-div, .about-about-div {
    display: flex;
    flex-direction: column;
    gap:3rem;
    width:100%;
    position: relative;
    margin-top: 9vh;
}

.about-about-div {
    padding-bottom: 6rem;
}
.about-about-div { 
    margin-top: 11.75vh;
}
.div-underline {
    border-bottom: 3px solid #CED3FE;
    width:100%;   
}

.about-us-page div h1 {
    color: var(--azul-azul-vibrante, #0B24FB);
    font-size: 3.5rem;
    margin-bottom: 0.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
}

.about-us-page div h2 {
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    
}
.about-page-subheader {
    color: var(--azul-azul-vibrante, #0B24FB);
}

.subtitle-color {
    color: var(--azul-azul-escuro-azul-8, #040E64);
}

.values-cards {
    display:flex;
    gap:2rem;
    justify-content: space-between;
}

.value-card {
  
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 0.5rem;
    width:25rem;
}

.value-card-text {
    color: #000;
   
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 125% */
}
.value-card-header {
    color: #000;
  
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.about-text-container {
    display:flex;
    flex-direction: column;
    gap: 1rem;
}
.about-about-first-container {
    display: flex;
    width:100%;
    gap: 2rem;
}

.about-text {
    color: #000;
    font-size: 1.125rem;
    line-height: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.85rem;
}

.about-about-second-container {
    margin-top: 10rem;
    display: flex;
    width:100%;
    gap:2rem;
}

.about-about-first-container span {
    width: 50%;
}


.strong-text {
    font-weight: 700 !important;
}

.stats-image {
  
   width:45%;
}

.laptop-image {
    
        /* Position the image a bit higher and to the left */
    position: absolute;
    top: -5rem; /* Adjust as needed */
    right: -300px; /* Adjust as needed */
    /* Other styles for the image */
    
    max-width: 60% !important; 
   
}

.big-image {
    position: absolute;
    top: 0;
    left: 65%; /* Position the image on the right side */
     /* Adjust the height to cover the entire container */
    width: 55%;
    height:55%; /* Allow the width to adjust proportionally */
    z-index: -1; /* Place the image behind the text content */
  }


@media (min-width: 2000px) {
    .about-text-container {
        justify-content: center;
    }
    .laptop-image {
        top: -100px;
      
    }
}

@media ( max-width: 1550px ) {
    .laptop-image {
        top: 10vh;
        right: -20vw;
    }
}

@media ( max-width: 1150px) {
    .laptop-image {
        top: 20vh;
        right: -15vw;
    }
}
@media (max-width:900px) {
    .about-us-page {
        width: 89.75vw;
    }
    .about-us-page div h1 {
        font-size: 1.75rem;
        line-height: 171.523%;
    }
    .about-us-page div h2 {
        font-size: 1.375rem;
        line-height: 171.523%;
    }

    .value-card-carousel {
        display: flex;
    }
    .value-card {
        height: 18.1rem;
        box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
        color: #FFF;
        padding: 2rem;
        width: 74vw;
    }
    .value-card-header {
        font-size: 1.125rem;
        text-align: left;
    }
    .value-card-text {
        width:80%;
        font-size: 1rem;
        line-height: 1.375rem;
        text-align: left;
    }

    .carousel-swipe {
        display: flex;
        justify-content: flex-end;
    }
    .carousel-swipe span{
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: rgba(0, 0, 0, 0.60);
        font-family: 'Lato';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .about-values-div, .about-header-div {
        
        padding-bottom: 2.5rem;
       
    }

    .about-values-div {
        gap: 1.375rem;
    }

    .about-about-first-container {
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    .about-text {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .about-text-container {
        gap: 0.5rem;
    }
    
    .laptop-image {
        position: static;
        max-width:25rem;
    }
    .about-about-first-container span {
        width:100%;
    }

    .about-about-second-container {
        flex-direction: column-reverse;
        align-items: center;
        gap: 2rem;
        margin-top: 0rem;

    }

    .stats-image {
        width: 100%;
    }

    .about-about-div {
        
        margin-bottom: 4rem;
    }

    
   
    
}

@media ( max-width: 600px) {
    .about-us-first-logo-background {
        position: absolute;
        top: 100vh;
        left: 50vw;
        width: 200%;
        height: auto;
        z-index: 0; /* Place the image behind the content */
    }

    .about-us-second-logo-background {
        position: absolute;
        top: 200vh;
        right: 30vw;
        width: 180%;
        height: auto;
        z-index: 0; /* Place the image behind the content */
    }
    
}