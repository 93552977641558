@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.home-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: relative; /* Add this */
    background: url(https://i.imgur.com/J16Ahf6.jpg) no-repeat center center ;
    background-size: cover;
    position: relative;

    
  } 
  

  .logo-container  button {
    color: var(--azul-azul-claro-azul-5, #EBEDFF);
    font-size: 1.75rem;
    font-family: 'Lato';
    font-weight: 600;
    background-color: transparent;
    border:2px solid var(--azul-azul-claro-azul-5, #EBEDFF);
    border-radius: 54px;
    padding: 1rem 2rem;
  }
  .home-page::before { /* Add this pseudo-element */
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(9, 29, 201, 0.7); /* Color with 60% opacity */
    z-index: 2;
  }
  
  .home-background-three {
    position: absolute;
    bottom: 10vw;
    left: 60vw;
    width: 105%;
    height: 120%;
    z-index: 10;
  }

  .home-background-three-inverse {
    position: absolute;
    bottom: 50vw;
    right: 42vw;
    width: 105%;
    height: 120%;
    z-index: 10;
  }

  .logo-container {
    height: 90%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    gap: 5rem;
  }
  
  .app-logo {
    width: 40%;
    opacity: 1;

  }

  .swipe {
    z-index: 2;
    display:flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  
  .swipe-up-arrow {
    fill: var(--azul-azul-claro-azul-5, #EBEDFF);
    height: 1.25rem;
    stroke: var(--azul-azul-claro-azul-5, #EBEDFF);
    stroke-width: 1;
  }

  .swipe-text {
    color: var(--azul-azul-claro-azul-5, #EBEDFF);
    text-align: center;
    font-family: 'Lato';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
  }
  
  

@media (max-width: 900px) {
  .home-background-three-inverse {
    display:none;
  }
  .app-logo {
    width: 80%;
    height: auto;
  }
  .home-background-three {
    bottom:50vh;
  }
  .home-background-second-three {
    position: absolute;
    top: 70vh;
    width: 110%;
    height: 120%;
    z-index: 10;
    overflow: hidden;
  }

  .logo-container { 
    gap: 0.5rem;
  }
}

@media (max-width: 600px) {
  .logo-container button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }
}