.webapp-donut{
    background-color: #FFFFFF;
    border: 3px solid #9DA7FD;
    border-radius: 1rem;
    padding: 2rem;
}

.webapp-donut-content {
    margin-top: 100px;
    width: 500px; 
    height: 300px;
    margin: 0 auto; 
}