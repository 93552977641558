.webapp-graphuc{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.webapp-graphuc-filters{
    display: flex;
    gap: 2rem;
    padding: 4rem 3rem;
    border-radius: 1.5rem 0 0 0;
    background-color: #CED3FE;
    border: 3px solid #9DA7FD;
}

.webapp-graphuc-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
    padding: 1.5rem 4rem;
    background-color: white;
    border: 3px solid #9DA7FD;
    overflow: scroll;
    overflow: hidden;
}

.webapp-graphuc-content:nth-child(2){
    border-radius: 0 1.5rem 0 0;
    border-left: none;
}

.webapp-graphuc-content:nth-child(3){
    border-radius: 0 0 0 1.5rem;
    border-top: none;
}

.webapp-graphuc-content:nth-child(4){
    border-radius: 0 0 1.5rem 0;
    border-top: none;
    border-left: none;
}