.webapp-create-training-page {
    width:100%;
    padding: 4vh 2vw;
    min-height: 92vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
}

.webapp-create-training-exercise-info-form-drawing {
    display: flex;
    gap: 2rem;
    width: 100%;
 
}

.webapp-create-training-header {
    display:flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.webapp-create-training-header-left-inputs {
    display:flex;
    gap: 2rem;
    width: 50%;
}

.webapp-create-training-header-left-inputs div {
    display:flex;
    flex-direction: column;
    gap: 1rem;
}
.webapp-create-training-header-left-inputs > div:nth-child(1) {
    flex: 1; 
}

.webapp-create-training-header-left-inputs > div:nth-child(2) {
    flex: 2;
}


.webapp-create-training-header label {
    color: var(--PRETO, #000);
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.webapp-create-training-header-left-inputs label span {
    color: var(--outros-vermelho, #ED4863);
}

.webapp-create-training-header-left-inputs input {
    width: 100%;
    height: 2.5rem;
    border-radius: 2px;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732);
    padding: 0 1rem;
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.webapp-create-training-header-competencias {
    display:flex ;
    flex-direction: row !important;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.webapp-create-training-header-competencias span{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid var(--azul-azul-escuro-azul-9, #020732);
    height: 1.8rem; /* Adjust the size of the circles */
    width:1.8rem;
    opacity: 50%;

}

.webapp-create-training-header-time {
    display:flex;
    flex-direction: column;
    gap: 1rem;
}


.webapp-create-training-header-time-box {
    border-radius: 3px;
    background: var(--azul-azul-claro-azul-4, #CED3FE);
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0.5rem;
}

.webapp-create-exercise-selected-exercise {
    background: white !important;
}

.webapp-create-training-exercise {
    width:100%;
}

.webapp-create-training-exercise h1{
    color: rgba(0, 0, 0, 0.60);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    opacity: 60%;
    margin-bottom: 1rem;
}

.webapp-create-training-exercise-container {
    width:100%;
    display: flex;
}

.webapp-create-training-exercise-card {
    width:10%;
    height: 100%;
}

.webapp-create-training-exercise-new-card {
    display:flex;
    justify-content: center;
    align-items: center;
    height: 4.2rem;
    border-bottom: 1px solid rgba(2, 7, 50, 0.20);
}

.webapp-create-training-exercise-new-card:hover {
    cursor:pointer;
}

.webapp-create-training-exercise-new-card span{
    opacity:60%;
}

.webapp-create-training-exercise-info {
    width:90%;
    display: flex;
    flex-direction: column;
    padding: 4vh 2vw;
    background-color: white;
    border-radius: 0px 6px 6px 6px;
    gap: 2rem;
}

.webapp-create-training-exercise-info-header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.webapp-create-training-exercise-info-header h2 {
    color: var(--azul-azul-claro-azul-2, #6978F9);

    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
}

.webapp-create-training-exercise-info-header button {
    background-color: white;
    border-radius: 6px;
    border: 1.5px solid #3C50FC;
    background: var(--Branco, #FFF);
    padding: 0.75rem 1rem;
    color: var(--Azul-Azul-Claro-Azul-1, #3C50FC);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}



.webapp-create-training-exercise-info-form {
    display: flex;
    flex-direction: column;
    width:100%;
    position: relative;
}

.create-training-info-form-step-header {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.create-training-info-form-step-header span {

    border-radius: 50%;
    border: 1.5px solid #6978F9;
    background-color: white;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6978F9;
    font-size: 1rem;
    font-weight: 400;
    cursor:pointer
}

.create-training-info-form-selected-step {
    background-color: var(--azul-azul-claro-azul-4, #CED3FE) !important; 


}

.create-training-info-form-step-header h3 {
    color: var(--azul-azul-escuro-azul-8, #040E64);
    font-size: 1.25rem;
    font-weight: 800;
    text-transform: uppercase;
}

.create-training-info-form-step-items  {
    margin-left: 1.5rem;
    border-left: 1px solid #6978F9; 
    min-height: 10vh;
    padding-left: 3.5rem;
}


.create-training-info-form-step-items:last-child {
    border-left: none;
}

.webapp-create-training-change-step-buttons {
    display:flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2rem;
}

.create-training-next-step-button {
    border-radius: 6px;
    background-color: var(--azul-azul-claro-azul-5, #EBEDFF);
    border: 1.5px solid var(--azul-azul-claro-azul-1, #3C50FC);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: 'Lato';
    color: var(--azul-azul-claro-azul-1, #3C50FC);
    padding: 0.5rem 0.75rem;

}

.create-training-previous-step-button {
    border:none;
    background: white;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: 'Lato';
    color: var(--azul-azul-claro-azul-1, #3C50FC);
    padding: 0.5rem 1rem;
}




.webapp-create-training-exercise-drawing {
    position: absolute;
    top:0;
    right:0;
    height: 25vh;
    width: 30%;
  
}

.webapp-create-training-selected-exercise-drawing{
    position: absolute;
    top: 0;
    right: 0;
    height: 10rem;
    aspect-ratio: 16/9;
}


.webapp-create-training-exercise-info-form-drawing img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position:relative;


}

.webapp-create-training-exercise-drawing button, .webapp-create-training-selected-exercise-drawing button {
    position:absolute;
    top:50%;
    left:50%;
    border-radius: 6px;
    border: 1.5px solid var(--azul-azul-claro-azul-1, #3C50FC);
    background: var(--Branco, #FFF);
    font-size: 1.125rem;
    font-weight: 400;
    font-family: 'Lato';
    color: var(--azul-azul-claro-azul-1, #3C50FC);
    padding: 0.5rem 1rem;
    transform: translate(-50%, -50%);

}

.create-training-save-button {
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: white;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0.75rem 1rem;
    border: none;
} 

.create-training-info-form-step-header div{
    display: flex;
    flex-direction: column;
    gap:1rem;

}
.webapp-create-training-exercise-info-form-drawing button:hover, .create-training-save-button:hover, .create-training-next-step-button:hover, .create-training-previous-step-button:hover, .webapp-paint-return button:hover{
    cursor: pointer;
}


.webapp-create-training-paint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index:100;
   
} 

.create-training-stop-overflowing {
    overflow: hidden;
    height:100vh;
}

.create-training-drawing-selected {
    height:20vh !important;
}



@media (max-width: 1200px) {
    .webapp-create-training-header {
        justify-content: flex-start; 
        align-items: center;  
        gap: 2rem;
    }
    .webapp-create-training-header-left-inputs {
        width: 70%;
    }
    

}
