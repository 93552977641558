.webapp-player-detail-page{
    width:100%;
    padding: 0vh 2vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-top:8vh;
}

.webapp-player-detail-page-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width:100%;
  }

  .webapp-player-detail-page-player-name {
    display: flex;
    justify-content: center;
    font-weight: 700 !important; 
  }

  .webapp-player-detail-page-header h1 {
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%; 
}

.webapp-player-detail-page-header button{
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: white;
    padding: 0.75rem 1.365rem;
    font-size: 1.125rem;
    font-family: 'Lato';
    font-weight: 600;
    border:none;
}

.webapp-player-detail-page-top-bar {
  background-color: #FFFFFF;
  font-weight: bold;
  max-width: 100%;
  border-bottom: solid 3px #CED3FE;
  border-spacing: 0px !important;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1vh;
  margin-bottom: 2rem;
}



.webapp-player-detail-top-bar-button {
  border-radius: 6px;
  background: var(--azul-azul-claro-azul-1, #3C50FC);
  color: white;
  padding: 0.7rem 1rem;
  border-radius: 0.5rem;
  font-size: 1.125rem;
  font-family: 'Lato';
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-left: auto; 
}


  .webapp-player-detail-page-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
  }
  
  .webapp-player-detail-row {
    display: flex;
    flex-direction: row;
    width: 100%;
 
  
  }

  .webapp-player-detail-row:nth-child(odd) {
    background-color: #EBEDFF;
  }
  .webapp-player-detail-row:nth-child(even) {
    background-color: #fff;
  }

  .webapp-player-detail-row-last {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #fff;
    
  }
  
  .webapp-player-detail-row-data {
    width: 11.1%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2% 0;
  }

  .webapp-player-detail-row-data span {
    width:70%;
    display: flex;
    justify-content: flex-start;
  }
  
  .webapp-player-detail-row-data-label {
    font-family: Lato;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0em;
    color: 000000
  }
  
  .webapp-player-detail-row-data-info {
    font-family: Lato;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0em;
    color: #000000;
    padding-top: 2vh;
  }
  

  .webapp-player-detail-page-zvalues-warning {
    color: red;
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  /* Adjust the styles based on your design preferences */
  









