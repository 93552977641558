.webapp-games-page-scroll {
    display: flex;
    max-width: 100%;
    overflow: none;
}

.webapp-games-page {
    width: 100%;
    padding: 4vh 2vw;
    min-height: 92vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
    overflow: hidden;
}

.games-folder-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.games-folder-container-header button {
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3c50fc);
    color: white;
    padding: 0.75rem 1.365rem;
    font-size: 1.125rem;
    font-family: "Lato";
    font-weight: 600;
    border: none;
}

.games-folder-container-header h1 {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Lato";
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;
}

.games-folder-container-header button:hover {
    cursor: pointer;
}

.webapp-games-page-games-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.webapp-games-collective-page-table-mods {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 2.5rem;
}

.webapp-games-collective-page-table-mods2 {
    gap: 2rem;
    display: flex;
    flex-direction: row;
}

.views-container {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: flex-end;
}

.view-page-games {
    width: fit-content;
    height: 2.3rem;
    gap: 0.4rem;
    align-items: center;
    display: flex;
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #ced3fe);
    color: #091dc9;
    padding: 0.55rem 0.6rem;
    font-family: "Lato";
    font-weight: 600;
    text-align: right;
    cursor: pointer;
}

.webapp-games-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.webapp-games-table-row {
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 1.125rem 0;
}

.webapp-games-table-header {
    background-color: #ffffff;
    font-weight: 600;
    max-width: 100%;
    border-bottom: solid 3px #ced3fe;
}

.webapp-games-table-column {
    width: 14%;
    text-align: center;
}

.game-asterisc {
    color: #3c50fc;
}

/* Tive que fazer isso sinto muito quem ter que ver isso */
.align-asterisc {
    opacity: 0;
}

.webapp-games-table-column-select {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ced3fe;
    border-radius: 100%;
    cursor: pointer;
}

.webapp-games-row-popup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    border-radius: 6px;
    z-index: 1;
    right: 15px;
    top: 30px;
    background-color: #fff;
    padding: 20px 10px;
}

.webapp-games-row-popup-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #ced3fe;
}

.games-row-popup-option {
    display: flex;
    align-content: center;
    gap: 5px;
    width: 100%;
    border: 1.5px solid black;
    border-radius: 3px;
    text-align: left;
    padding: 5px;
}

.games-row-popup-option img {
    width: 1rem;
    height: 1rem;
    align-self: center;
}

.games-row-popup-option:hover {
    background-color: #ebedff;
    cursor: pointer;
}

.webapp-games-popup-delete {
    display: flex;
    border-radius: 3px;
    text-align: left;
    color: #ed4863;
    padding: 5px;
    cursor: pointer;
}

.webapp-games-popup-delete img {
    width: 1rem;
    height: 1rem;
    align-self: center;
}

.webapp-games-popup-delete:hover {
    background-color: #ed486339;
}

.hidden-popup {
    display: none;
}

.webapp-games-page-top-bar-scroll {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.2rem;
    background-color: #ffffff;
    border-bottom: solid 3px #ced3fe;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
}

.webapp-games-page-top-bar-scroll img:hover {
    background-color: #6978f9;
    border-radius: 6px;
    width: 1rem;
}

.webapp-games-page-top-bar-text {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.game-info-container-display {
    display: flex;
    padding: 2rem;
    gap: 1rem;
    flex-wrap: wrap;
}
