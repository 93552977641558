.webapp-import-items-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(1, 3, 20, 0.50);

    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2%;

}

.webapp-import-items-popup-inner {
    width: 74%;
    height: 85%;
    border-radius: 8px;
    background:  #F4F5FF;
    padding: 1rem;
    z-index: 200;
    padding: 4rem 2rem

}


.webapp-import-items-popup-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    gap: 5%;
}

.webapp-import-items-popup-division {
    width: 100%;
    background: #CED3FE;
    height: 2px;
}
.webapp-import-items-popup-main-div h2{
    color: rgba(0, 0, 0, 0.60);
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%; /* 43.9px */

}
.webapp-import-items-popup-main-div{
    display: flex;
    flex-direction: column;
    height:100%;
    gap: 1rem;
}

.webapp-import-items-popup-grid {
    display:flex;
    width: 100%;
    flex-wrap: wrap;
    gap:3%;  
    overflow-y: scroll; 
    height: 75%;   /* 18% e gap 2.5% */
}

.webapp-import-items-popup-grid::-webkit-scrollbar-track {
    background-color: #FFF;
    border-radius:6px
     /* adjust the margin to compensate for the padding */
  }
  
  /* Style the scrollbar thumb */
  .webapp-import-items-popup-grid::-webkit-scrollbar-thumb {
    background-color: #CED3FE;
    border-radius: 6px;
  }
  
  /* Change the scrollbar width */
  .webapp-import-items-popup-grid::-webkit-scrollbar {
    width: 12px;

  }
  
  /* Adjust the scrollbar track width to compensate for the padding */
.webapp-import-items-popup-grid::-webkit-scrollbar-track-piece {
    background-color: #fff;
    border-radius:6px
}

.webapp-import-items-popup-grid-item {
    width:22%;
    height: 40%;
    border-radius: 8px;
}


.webapp-import-items-selected-item {
    padding: 0.5rem;
    border-radius: 8px;
    background: var(--Azul-Azul-Claro-Azul-4, #CED3FE);
    position: relative;
}


.webapp-import-items-selected-check {
    position: absolute;
    bottom:0%;
    right:0%;
    padding: 0.30rem;
    border-radius: 8px 0px 8px 0px;
    background: var(--Azul-Azul-Claro-Azul-1, #CED3FE);
    height:100%;
    width: fit-content !important;
    height: fit-content !important;

}
.webapp-list-popup-exercise {
    width:100%;
    display: flex;
    flex-direction: column;
    border-radius: 5.359px;
    background: var(--Branco, #FFF);
    box-shadow: 0px 0px 8.932px 0px rgba(1, 3, 20, 0.05);
    height: 100%;   
    cursor:pointer;

}

.webapp-list-popup-exercise img {
    height:75%;
}

.webapp-list-popup-exercise > div:first-child {
    height: 75%;
   
}

.webapp-list-popup-exercise div{

    width:100%;
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: center;
    height:25%;
}

.webapp-list-popup-exercise div span {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}

.webapp-import-items-popup-buttons {
    width:100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.webapp-import-items-popup-cancel-button {
    background: #F4F5FF;
    border:none;
    color: var(--Azul-Azul-Claro-Azul-1, #3C50FC);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 1rem;
    cursor:pointer
}

.webapp-import-items-popup-import-button {
    border-radius: 6px;
    background: var(--Azul-Azul-Claro-Azul-1, #3C50FC);
    color: #FFF;
    border:none;
    padding: 0.5rem 1rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor:pointer;
}


@media (max-width: 1024px) {
    .webapp-import-items-popup-grid-item {
        width: 30%;
        height: 45%;
    }

    .webapp-list-popup-exercise div span {
        font-size: 0.75rem;
    }
}