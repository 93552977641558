.webapp-games-subpage-changer {
    display:flex;
    gap: 2rem;
    align-items: center;
}



.webapp-games-subpage-changer a{
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    opacity: 0.6;
    transition: background-color 0.2s ease-in-out;
}

.webapp-games-subpage-changer :not(.game-subpage-changer-selected) a:hover{
    cursor: pointer;
    background-color: #3c50fc33;
}

.webapp-games-subpage-changer hr{
    opacity: 0;
}

.webapp-games-subpage-changer hr{
    height: 4px;
    background-color: var(--azul-azul-claro-azul-1, #3C50FC);
    width: 105%;
    border-radius: 10px;
}


.webapp-games-subpage-changer-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}

.game-subpage-changer-selected a, .game-subpage-changer-selected hr{
    cursor: pointer;
    opacity: 1 !important;
}