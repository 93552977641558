

.questions-background {
    z-index: -1;
    background: var(--azul-azul-escuro-azul-6, #091DC9);
    
}
.questions-page {
    width: 84.7vw;
    margin: auto;
    padding: 6rem 0;
    display:flex;
    flex-direction: column;
    gap: 3rem;
    z-index: 1;
    position: relative;
}

.questions-first-logo-background {
    position: absolute;
    top: 30vh;
    left: 71vw;
    width: 80%;
    height: auto;
    z-index: 0; 
    opacity: 40%;
}


.questions-page h4 {
    color: #FFF;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4rem;
}


.questions-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5rem; 
    margin-bottom: 4rem;  
}

.question-card {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: var(--azul-azul-claro-azul-5, #EBEDFF);
    /* shadow2 */
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    width: 26.7%;
    padding: 1.563rem 1.875rem;
    z-index: 10;
}

.hidden{
    opacity: 0;
    margin-bottom: -7rem; /* To last dont get in the way of next tag */
}


.question-card p {
    color: #000;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6rem;
}

.joel-card {
    border-radius: 20px;
    background: var(--azul-azul-claro-azul-5, #EBEDFF);
    padding: 2rem 4rem ;
    display:flex;
    justify-content: space-between;
    align-items: center;
    /* shadow2 */
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    z-index: 10;
}

.joel-card-image {
    width: 9.565rem;
}


.joel-card-info {
    display:flex;
    gap: 2rem;
    align-items: center;
}

.joel-card-info-container {
    display:flex;
    flex-direction: column;
    gap: 0.5rem;
}

.joel-card-info-container h5 {
    color: #000;
    font-family: 'Lato';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.joel-text-color {
    color: #000
}
.joel-card p {
   
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.joel-card-quote {
    color: rgba(0, 0, 0, 0.70);
}

.questions-carousel {
    margin-bottom: 2.4rem;
}



@media (max-width: 1120px) {
    .question-card {
        width: 18rem;
        
    }
}
@media (max-width: 900px) {
    .joel-text-color {
        width: 100%;
    }
    .questions-page {
        width: 89.75vw;
    }
    .questions-page h4 {
        font-size: 1rem;
        line-height: 1.375rem;
        width:100%;
        margin-bottom:2.4rem;
    }
    .question-card {
        height:5.5rem;
    }

    .control-dots {
        position:relative !important;
    }

    .carousel.carousel-slider {
        display: flex;
        flex-direction: column-reverse;
    }
    .question-card {
        border-radius: 8px;
        padding: 0.5rem 0.5rem;
        display:flex;
        gap:0.5rem;
        align-items: center;

    }

    .question-card p {
        font-size: 0.938rem;
        text-align: left;
        flex: 1;
    }

    .number-circle {
        width:38px;
        height:38px;
        border-radius: 50%;
        background: #FFF;
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight:bold;
    }
    
    .carousel * {
        box-sizing: content-box !important;
    }

    .joel-card {
        border-radius: 8px;
        flex-direction: column;
        padding: 2rem 1rem;
    }

    .joel-card p {
        font-size: 1rem;
        text-align: left;
        line-height: 1.375rem;
        }

    .joel-card-info {
        display:flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .joel-card-info-container {
        display:flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        margin-bottom:1.5rem;
    }
    


}

@media ( max-width: 1250px) {
    .joel-text-color {
        width: 80%;
    }
    .joel-card {
        padding: 2rem 2rem;
    }
}

@media (max-width: 600px) {
    .questions-first-logo-background {
        width: 120%;
        left: 60vw;
        top: 20vh;
    }
}

@media (max-width: 450px) {
    .questions-carousel .carousel .slider {
        width: 90%;
    }
    .question-card {
        width: 80%;
    }

    .question-card p {
        line-height: normal;
    }
} 
@media ( max-width: 340px) {
    .question-card {
        max-width: 90%;
    }
}