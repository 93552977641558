.webapp-sessions-page-scroll {
    display: flex;
    max-width:100%;
    overflow:none;
  }

.webapp-sessions-page {
    width:100%;
    padding: 4vh 2vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

.webapp-sessions-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width:100%;
  }

.webapp-sessions-page-header h1 {
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%; 
}

.webapp-sessions-page-header button{
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: white;
    padding: 0.75rem 1.365rem;
    font-size: 1.125rem;
    font-family: 'Lato';
    font-weight: 600;
    border:none;
    cursor: pointer;
}

.webapp-sessions-page-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.webapp-sessions-page-top-bar {
  background-color: #FFFFFF;
  border-bottom: solid 3px #CED3FE;
  text-align: center; 
  max-width: 100%;
  border-spacing: 0px !important;
  display:flex;
  align-items: center;
  height: 8vh;
}

.webapp-sessions-page-top-bar-items {
  display: flex;
  width: 90%;
  font-family: Lato;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0em;
  color: #00000099;
  padding-left: 2vw;
  justify-content: space-between;
}

.webapp-sessions-page-top-bar-items span{ 
  color: rgba(0, 0, 0, 0.60);

  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.webapp-sessions-page-table-rows{
  display: flex;
  flex-direction: column;
}

.webapp-sessions-page-table-row-items{
    display: flex;
    border-bottom: 1px solid #02073233;
    padding: 3vh 0vw 3vh 2vw;
    width:100%;
    background: var(--Fundo-Website, #F4F5FF);
}

.webapp-sessions-page-table-row-items > a{
  text-decoration: none;
}

.webapp-sessions-page-table-row-items span{
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  align-items: center;

}

.webapp-sessions-page-table-row-items-info {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.webapp-sessions-page-table-row-items > a > button{
  background-color: #FFFFFF;
  color: #000000;
  border: none;
  font-size: 1.125rem;
  font-family: 'Lato';
  cursor: pointer;
  width: 84px;
  height: 35px;
  top: 315px;
  left: 1375px;
  border-radius: 6px;
}


.webapp-sessions-page-top-bar-items-25w {
  width:25%;
  display: flex;
  align-items: center;
  gap: 5%;
}

.webapp-sessions-page-top-bar-items-15w {
  width:15%;
}

.webapp-sessions-page-top-bar-items-10w {
  width:10%;
}

.webapp-sessions-page-top-bar-items-30w {
  width:25%;
}

.webapp-session-page-quizzes-values{
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.value{
  text-align: right;
  display: inline-block;
  width: 30px; 
  color: #01031499 !important;
}

.value1{
  text-align: right;
  display: inline-block;
  width: 30px; 
  color: #01031499 !important
}

.value2{
  text-align: right;
  display: inline-block;
  width: 30px; 
  color: #01031499 !important
}

.user-icon{
  margin-left: 5px;
  color: #01031499 !important
}

.ongoing-box{
  display: flex;
  align-items: center;  
  width: 9rem;
  height: 2.25rem;
  border-radius: 100px;
  border: 1px solid;
  background-color: #FF7D06;
  gap: 4%;
  color: #FFFFFF !important;
  padding-left: 10px;
  cursor: pointer;
}

.ongoing-box:hover{
  background-color: #ff7e06e2;
}

.completed-box{
  display: flex;
  width: 9rem;
  height: 2.25rem;
  border-radius: 100px;
  border: 1px solid;
  background-color: #7FC035;
  gap: 4%;
  color: #FFFFFF !important;
  padding-left: 10px;
  cursor: pointer;
}

.completed-box:hover{
  background-color: #7fc035ef;
}



