.webapp-forms-page-scroll {
    display: flex;
    max-width: 100%;
    flex-direction: column;

}

.webapp-forms-page {
    width: 100%;
    min-height: 100vh;
    background: var(--Fundo-Website, #F4F5FF);
    display: flex;
    flex-direction: column;
    padding: 8vh 5vw 0 5vw;
    gap: 2rem;
}

.webapp-forms-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
}

.webapp-forms-page-header h1 {
    color: var(--PRETO, #000);
    font-family: 'Lato';
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;
}

.webapp-forms-page-header button {
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: white;
    padding: 0.75rem 1.365rem;
    font-size: 1.125rem;
    font-family: 'Lato';
    font-weight: 600;
    border: none;

}

.webapp-forms-page-table {
    width: 100%;
    display: flex;
    flex-direction: column;


}

/* Style for the top bar table */
.webapp-forms-page-top-bar {
    background-color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    /* Center-align the text in table headers */
    width: 100%;
    border-bottom: solid 3px #CED3FE;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vh 0 2vh 5vw;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.2rem;
    gap: 1rem;
}

.webapp-forms-page-top-bar-fixed-column {
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: solid 3px #CED3FE;
    padding: 1vh;

}

.webapp-forms-page-player-answers-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20vw;
}

.webapp-forms-page-player-answers-row {
    display: flex;
    gap: 1rem;
}

.webapp-forms-player-filter {
    margin-left: 5vw;
    margin-top: 5rem;
    margin-bottom: 1rem;
    color: var(--PRETO, #000);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
}

.webapp-forms-page-player-row-info-answers{
    text-align: start;
}

.webapp-forms-page-top-bar-column-name-date, .webapp-forms-page-top-bar-column-name-final {
    width: 210px;
    text-align: left;
}

.webapp-forms-page-warning{
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .webapp-forms-page{
        padding: 8vh 5vw 4vh 5vw;
    }
    .webapp-forms-page-header h1{
        font-size: 1.5rem;
    }

    .webapp-forms-page-top-bar{
        flex-direction: column;
        width: 50%;
        padding: 0;
        gap: 0;
        border: none;
    }

    .webapp-forms-page-table{
        flex-direction: row;
        border-top: 2px solid #CED3FE;
    }

    .webapp-forms-page-top-bar h4{
        text-align: left;
        width: 100%;
        font-size: 1rem;
        padding: 4vh 5vw;
        border-bottom: 1px solid #CED3FE;
        
    }

    .webapp-forms-page-top-bar-column-name-final{
       display: flex;
       align-items: center;
    }

    .webapp-forms-page-answers-button{
        margin-top: -2rem;
        width: 50%;
        align-self: end;
        padding: 1rem 0.5rem;
        border: none;
        background-color: #FFFFFF;
        border-radius: 0 0 6px 6px;
    }

    
}