.webapp-coach-paint-page {

    height: 100vh;
    background: var(--Fundo-Website, #F4F5FF);
    display: flex;
    justify-content: center;
    margin: 0;

    align-items: center;

}

.webapp-coach-page-container {
    height: 94%;
    width: 96%;
    display: flex;
    flex-direction: column;
    gap:2%;
}

.webapp-coach-page-paint-container{
    height: 80%;
    width: 100%;
    padding: 2rem 0rem;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.webapp-coach-paint-canvas {
    display: flex;
    justify-content: center;
    width: 80%;
}


.webapp-paint-page {
    flex: 1;
    height: 100vh;
    background: var(--Fundo-Website, #a8a8ac);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0;

}

.webapp-coach-paint-exercise-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 10%;
    align-items: center;
}

.webapp-coach-paint-exercise-cards > div {
    width: 4rem;
    margin: 0 auto;
    height: 4rem;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;

}

.webapp-coach-paint-exercise-cards > div > img {
    width: 140%;
}

.webapp-coach-paint-exercise-new-card {
    border: 3px solid #6978F9;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6978F9;
    font-size: 2rem;
    font-weight: 400;
}


.webapp-coach-paint-feature-buttons {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15%;
}

.webapp-coach-paint-feature-buttons > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.paint-button-delete {
    border-radius: 6px;
    border: 1.5px solid var(--Outros-Vermelho, #ED4863);
    background: var(--Branco, #FFF);
    color: var(--Outros-Vermelho, #ED4863);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor:pointer;
}

.paint-button-save {
    border-radius: 6px;
    background: var(--Azul-Azul-Claro-Azul-1, #3C50FC);
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    width: 100%;
    cursor:pointer;

}

.paint-button-download {
    border-radius: 6px;
    border: 1.5px solid var(--Azul-Azul-Claro-Azul-1, #3C50FC);
    background: var(--Branco, #FFF);
    color: var(--Azul-Azul-Claro-Azul-1, #3C50FC);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}

.paint-cursor-eraser-style {
    cursor: url('./CoachPaintAssets/eraser.png' ), auto;
}


@media (max-width: 900px) {

    .webapp-coach-paint-canvas{
        width: 100%;
    }

    .webapp-coach-page-paint-container{
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
    }

    .webapp-coach-paint-feature-buttons{
        width: 100%;
        flex-direction: row;
    }

    .webapp-coach-paint-feature-buttons > button{
        width: 10rem;
    }

    .webapp-coach-paint-feature-buttons > div{
        flex-direction: row;
    }
}
