.webapp-vertical{
    grid-column: span 2;
    display: flex;
    flex-direction: row;
}

.webapp-vertical-filters{
    display: flex;
    gap: 2rem;
    padding: 4rem 3rem;
    border-radius: 1.5rem 0 0 1.5rem;
    background-color: #CED3FE;
    border: 3px solid #9DA7FD;
}

.webapp-vertical-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-height: 30rem;
    overflow-y: auto;
    padding: 1.5rem 4rem;
    border-radius: 0 1.5rem 1.5rem 0;
    background-color: white;
    border: 3px solid #9DA7FD;
    border-left: none;
    overflow: scroll;
}

.webapp-vertical-table{
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.webapp-vertical-table-body-bars{
    position: relative;
    height: 100%;
}

.webapp-vertical-table-body-element{
    position: relative;
    vertical-align: bottom;
    text-align: center;
}

.webapp-vertical-scale{
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
    background: white;
    z-index: 1;
    text-align: right;
    opacity: 0;
}

.webapp-vertical-scale p{
    color: #040E64;
    font-size: 0.75rem;
    line-height: 0%;
    text-align: center;
    height: 1px;
    display: flex;
    justify-content: end;
}

.webapp-vertical-bar-lines{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.webapp-vertical-bar-lines div{
    height: 1px;
    width: 100%;
    background-color: #02073222;
}

.webapp-vertical-bar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: #040E6400;
    text-align: center;
}

.webapp-vertical-bar div{
    background-color: #3C50FC;
    border-radius: 0.25rem 0.25rem 0 0;
    position: relative;
    cursor: default;
    height: 100%;
    width: 1rem;
}

.webapp-injurieschart-bar-number{
    padding: 0;
    background-color: #6978F9;
    border-radius: 0 0.25rem 0.25rem 0;
    display: flex;
    align-items: center;
    position: relative;
    cursor: default;
    color: #00000000;
}

.webapp-vertical-bar-tooltip{
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.49);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.25rem 0.5rem;

    /* Position the tooltip */
    position: absolute;
    left: calc(50% + 1rem);
    top: 0;

    z-index: 100;
}

.webapp-vertical-bar-tooltip::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.49) transparent transparent;
  }

.webapp-injurieschart-bar-number:hover .webapp-injurieschart-bar-tooltip {
    visibility: visible;
}

.webapp-vertical-table-body-labels{
    text-align: center;
    padding: 0 0.5rem;
}