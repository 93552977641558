.webapp-register-page {
    width:100%;
    overflow: hidden;
    background: #F4F5FF;
    min-height:100vh;
    z-index: 0;
    position:relative;
}

.webapp-register-left-background-three {
    rotate: 90deg;
    position:absolute;
    z-index:1;
    right: 80vw;
    bottom: 60vh;
    width: 70%;
    opacity: 20%;

}

.webapp-register-right-background-three {
    rotate: 270deg;
    position: absolute;
    z-index:1;
    width:70%;
    left:80vw;
    bottom: 20vh;
    opacity: 20%;

}
.webapp-register-container {
    width:100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.webapp-register-icons {
    display:flex;
    gap: 2rem;
    height: 8vh;
    align-items: center;
    padding-left: 2rem;
    padding-top: 1rem;
    z-index: 2;
}

.webapp-register-icons img {
    height: 80%; 
    z-index: 2;
}


.webapp-register-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
    margin: 10vh 0;
    z-index: 2;
}

.webapp-register-form-container h1 {
    color: var(--azul-azul-escuro-azul-8, #040E64);
    font-family: 'Lato';
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 2;
}

.webapp-register-form {
    width: 30.5%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    z-index: 2;
}

.webapp-register-form-input-divs {
    display: flex;
    flex-direction: column;
    width:100%;
    gap: 0.5rem;
    z-index: 2;
}




.webapp-register-form-input-divs input {
    height: 2.625rem;
    border-radius: 2px;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732);
    background-color: #F4F5FF;
    padding-left: 2rem;
    z-index: 2;
}

.webapp-register-form-input-divs input::placeholder {
    color: rgba(0, 0, 0, 0.40);
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.webapp-register-form-input-divs input:focus {
    outline-color: #3C50FC;
    z-index: 2;
}

.webapp-register-submit-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
    z-index: 2;
    
}

.webapp-register-submit-button button {
    width: 80%;
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    outline:none;
    border:none;
    height:3rem;
    color: #FFF;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: 'Lato';
    cursor: pointer;
    z-index: 2;
    
}

.submit-button-link {
    width: 80%;
}


.webapp-register-login {
    color: #000;
    font-family: 'Lato';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    z-index: 2;
}

.webapp-register-form-input-divs {
    position:relative;
}

input [type="password"] {
    padding-right: 2rem;
}

.password-toggle {
    position: absolute;
    right: 0.75rem;
    top: 2.78rem;
    cursor:pointer;
    z-index: 2;
}



@media (max-width: 500px) {
    .webapp-register-form-container h1 { 
        font-size: 1.825rem;
    }
    .webapp-register-form-input-divs input {
        padding-left: 1rem;
    }
}

@media (max-width:380px) {
    .webapp-register-form-container h1 { 
        font-size: 1.5rem;
    }
    .webapp-register-submit-button .black-16px-400 {
        display:flex;
        align-items: center;
        flex-direction: column;
    }
}