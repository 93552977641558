.webapp-horizontal{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 1.5rem;
    gap: 1rem;
    background-color: white;
}

.webapp-horizontal-table-body-row-header{
    color: #040E64;
    text-align: left;
    padding: 1rem 0;
}

.webapp-injurieschart-table-body-row:first-child .webapp-horizontal-table-body-row-header{
    padding: 2rem 0 1rem 0;
    width: 25%;
}


.webapp-injurieschart-bar-lines > .webapp-injurieschart-bar {
  position: absolute;
  right: 0;
}