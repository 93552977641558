.webapp-forms{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    color: #212529;
    background-color: #EBEDFF;
    border-radius: 1rem;
    gap: 1rem;
}

.webapp-forms-title{
    font-size: 1.5rem;
    font-weight: 500;
    align-self: flex-start;
}

.webapp-forms-form{
    display: flex;
    justify-content: center;
    width: 80%;
}

.webapp-forms-form-input{
    width: 100%;
    padding: 1rem;
    border-top: 1px solid rgba(11, 36, 251, 0.70);
    border-bottom: 1px solid rgba(11, 36, 251, 0.70);
    border-left: 1px solid rgba(11, 36, 251, 0.70);
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
}

.webapp-forms-form-input:first-child{
    border-radius: 5px 0 0 5px;
}

.webapp-forms-form-input:last-child{
    border-right: 1px solid rgba(11, 36, 251, 0.70);
    border-radius: 0 5px 5px 0;
}

.webapp-forms-form-input:hover{
    background-color: #CED3FE;

}

.webapp-forms-form-selected{
    background-color: #9DA7FD;
}

.webapp-forms-form-selected:hover{
    background-color: #9DA7FD;
}

.webapp-forms-labels{
    display: flex;
    justify-content: space-between;
    width: 80%;
    text-align: center;
}

.webapp-forms-label{
    width: 10rem;
}

.webapp-forms-label-left{
    text-align: left;
}

.webapp-forms-label-right{
    text-align: right;
}

@media (max-width: 767px) {
    .webapp-forms{
        padding: 1rem;
    }

    .webapp-forms-form, .webapp-forms-labels {
        width: 100%;
    }


    .webapp-forms-form-input{
        padding: 0.25rem;
    }
}