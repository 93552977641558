.webapp-create-injury-info-grid {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: space-between;
    width: 100%;

    margin: 2rem 0;
}

.webapp-create-injury-dropdown>label {
    color: var(--PRETO, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
}

.webapp-create-injury-dropdown {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
    cursor: pointer;
}

.injury-dropdown-input {
    width: 100%;
    height: 2.5rem;
    border-radius: 2px;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0.5rem 0.5rem 0.5rem 0rem;

}


.injury-dropdown-input-placeholder {
    display: flex;
    align-items: center;
    width: 80%;
    padding-left: 0.5rem;
    overflow: hidden;
}

.injury-dropdown-input-placeholder > img {
    width: 2rem;
    height: 2rem;
    -webkit-user-drag: none;
}

.injury-form-multiple-choice-dropdown {
    margin-top: 1px;
    padding: 0.5rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-height: 25vh;
    border: 0.5px solid black;
    border-radius: 1px;
    overflow-y: auto;
    background-color: white;
    top: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.20);
    z-index: 20;
}

.injury-form-multiple-choice-dropdown>div {
    padding: 0.5rem 0rem 0.5rem 0.5rem;
    border-radius: 2px;
    cursor: pointer;
}

.injury-form-multiple-choice-dropdown>div:hover {
    background-color: #EBEDFF;
}

.injury-form-multiple-choice-dropdown>div:not(:first-child){
    border-top: 0.5px solid rgba(2, 7, 50, 0.2);
}

.selected-option-dropdown {
    background-color: #CED3FE;
}

.webapp-create-injury-time-form {
    display: flex;

    width: 100%;
    align-items: flex-start;
    margin: 4rem 0;
    gap: 4rem;
    height: 20vh;
}

.webapp-create-injury-time-form>div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
}

.date-input-holder {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732)
}

/* date-wrapper is used as a overlay to the calendar icon in firefox */
.date-wrapper {
    position: relative;
    width: fit-content;
}

.date-wrapper::after {
    display: none;
    width: 18px;
    height: 18px;
    background: white;
    position: absolute;
    content: "";
    right: 0px;
    top: 2px;
}

/* Show overlay of calendar icon only in firefox */
@supports not selector(::-webkit-calendar-picker-indicator) {
    @-moz-document url-prefix() {
        .date-wrapper::after {
            display: block;
            content: "";
        }
    }
}


.injury-info-date {
    outline: none;
    border: none;
    appearance: none;
}

.injury-info-date:focus {
    outline: none;
    border: none;
}

/* Hides calendar icon (Chrome & Safari) */
.injury-info-date::-webkit-inner-spin-button,
.injury-info-date::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.webapp-create-injury-time-form>div>div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
}

.injury-dropdown-text-holder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.webapp-injuries-flex-row{
    display: flex;
    align-items: center;
}

.webapp-injuries-flex-row>img{
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    -webkit-user-drag: none;
}

.webapp-create-injury-time-form>div>div>label {
    color: var(--PRETO, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
}


.webapp-injuries-no-input-img >img,
.webapp-injuries-date-input-icon{
    display: inline;
    width: 2rem;
    height: 2rem;
    -webkit-user-drag: none;
}

.webapp-injuries-date-input-icon{
    cursor: pointer;
}

.webapp-create-injury-microcycle{
    display: flex;
    gap: 2rem;
    padding: 1.5rem 5rem;
}

.webapp-injurry-microcycle-flex-column{
    display: flex;  
    flex-direction: column;
    gap: 1.25rem;
}

.webapp-injurry-microcycle-flex-column>label{
    color: var(--PRETO, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
}