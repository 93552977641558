.navbar {
    height: 11.5vh;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    z-index: 0;
   
}

.navbar-container {
    height: 100%;
    display: flex;
    width: 84.7vw;
    margin: 0 auto;
    justify-content: space-between;
}

.navbar-logo {
    height: 100%;
    display: flex;
    align-items: center;
}
.navbar-logo img {
    max-height: 7vh;
}

.active {
    text-decoration: underline !important;
    text-decoration-color: #0B24FB !important;
    text-underline-offset: 0.5rem;
}

.white-active {
    text-decoration: underline !important;
    text-decoration-color: #fff !important;
    text-underline-offset: 0.5rem;
}


.navbar-links  {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.navbar-links a {
    text-decoration: none;
    color: rgba(1, 3, 20, 0.90);
   
    font-family: 'Lato';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
}

.website-languages {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.website-languages span {
    color: rgba(1, 3, 20, 0.50);
  
    font-family: 'Lato';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
}

.website-languages span:hover {
    cursor: pointer;
}

.selected-language {
  color: var(--azul-azul-claro-azul-2, #6978F9) !important;
  text-decoration: underline !important;
  text-decoration-color: var(--azul-azul-claro-azul-2, #6978F9) !important;
  text-underline-offset: 0.5rem;
}



.mobile-hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--azul-azul-escuro-azul-6, #091DC9);
  
}
.white-logo {
  width: 41.5%;
}
.mobile-hamburger-menu-items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
  gap: 10vh;
}

.hamburger-menu-links {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  
}

.hamburger-menu-links a {
  text-decoration: none;
  color: var(--azul-azul-claro-azul-5, #EBEDFF);
  text-align: center;
  font-family: 'Lato';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 171.53%;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}

.mobile-hamburger-close {
  margin-top:2rem;
  padding-left: 2rem;
}

.mobile-hamburger-close img:hover {
  cursor: pointer;
}

@media ( max-width:1400px) {
  .navbar-links a{
    font-size: 1rem;
  }
  .navbar-logo img {
    max-height: 5vh;
}
}


@media (max-width:1100px) {
  
  .mobile-navbar-container {
      height: 100%;
      display: flex;
      width: 89.75vw;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
  }

  .navbar-hamburger {
      height: 1.5rem;
      width: 1.7rem
  }

  .language-dropdown {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
    }

    .current-language {
      cursor: pointer;
      display:flex;
      align-items: center;
      padding: 0.2rem;
      border-bottom: solid 2px rgba(2, 7, 50, 0.60);
    
    }
.ocult {
  display: none !important;
}
    .language-options {
      position: absolute; /* Position the options absolutely within the container */
      top: 100%; /* Position below the button */
      left: 0; /* Align with the left edge of the button */
      display: none; /* Initially hide the options */
      background: var(--azul-azul-claro-azul-5, #EBEDFF);
      border-radius: 1px;
      border: none;
      box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
      z-index: 100; 
      padding: 0.8rem 0.4rem;
      
    }
    .language-options.open {
      display:flex;
      flex-direction: column;
      gap: 0.2rem;/* Display the options when the dropdown is open */
    }
    
    .language-options span {
      display:block;
      cursor: pointer;
      font-family: 'Lato';
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.17px;
      z-index: 10;

    }

    .current-language span {
      cursor: pointer;
      font-family: 'Lato';
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.17px;
    }
    
    .language-options span:hover {
      background-color: #f0f0f0;
    }
    
    .dropdown-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0;
      border-color: #333 transparent transparent transparent;
      margin-left: 5px;
    }
    
    .open-dropdown {
      display: block !important;
    }


    
    .navbar-hamburger {
      cursor: pointer;
    }
    
    .menu-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vh;
      height: 100vh;
      background-color: black;
      
    
      z-index: 100000; /* Place the menu above other content */
      overflow-y: auto; /* Enable scrolling within the menu */
    }
    
    .open-menu .menu-overlay {
      transform: translateX(0);
      overflow: hidden; /* Disable scrolling on the body */
    }
}