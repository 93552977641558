
.pretreino-page {
  display: flex;
  
}

.pre-treino-page-components{
  display: flex;
  flex-direction: row;
  margin-top: 7.5vh;
  padding-left: 1vh;
}

.chartComponents {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4rem;
  align-items: flex-start;
}

.chartComponent2 {
  margin-left: 1vw;
}

.content-charts-pretrain {
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
  margin-top: 3vh;
}

.titulos-sessao{
  color: rgba(0, 0, 0, 0.60);
  font-family: Lato;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 137.188%; /* 2.74375rem */
  margin-left: 0vh;
  
}

.info-treino-numero{
  color: rgba(0, 0, 0, 0.60);
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 137.188%; 
  margin-left:5vh;
}
.info-treino-data{
  color: rgba(0, 0, 0, 0.60);
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 137.188%; 
  margin-left:5vh;

}

.arrow-icon {
  vertical-align: text-bottom; 
  
}
  
  



