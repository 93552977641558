.text-placeholder{ 
    position: relative;
    width: 100%;
    height: 1.5rem;
    padding: 0;
    margin: 0;
    border-radius: 0.5rem;
    background-color: rgba(206, 211, 254, 0.5);
    overflow: hidden;
}

.text-placeholder::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%; /* Set left to 0 to start from the left edge of the .text-placeholder */
    width: 100%; /* Set width to 100% to cover the entire .text-placeholder */
    height: 100%;
    background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.433) 50%, transparent 100%);
    animation: shine 2s infinite linear;
}

@keyframes shine {
    to {
        left: 100%;
    }
}