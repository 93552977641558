.webapp-trainings-training-div  {
    height:14rem;
    width:100%;
}

.webapp-trainings-folder-grid-item a{
    width: 100%;
}

.webapp-trainings-folder-grid-item:hover{
  background-color: #CED3FE;
}

.webapp-training-card-image {
    height: 60%;
    width: 100%;
}

.create-session-popup-selected-item {
    border: 0.5rem solid var(--Azul-Azul-Claro-Azul-1, #CED3FE);
    border-radius: 8px;
}

.abc {
    border: 0.5rem solid transparent;
}

.webapp-folder-component{
    width: 100%;
}

.webapp-exercise-card-content{
  position: relative;
}

.webapp-exercise-name-display{
  height: fit-content;
  white-space: nowrap;
  width: fit-content;
  cursor: default;
}

.webapp-exercise-name-overflow{
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0s all;
}

.webapp-exercise-name-overflow:hover > span{
  visibility: visible;
  transition-delay: 0.5s;
}

.webapp-trainings-training-div-bottom-overflow{
  top: -5rem;
  right: -9rem;
}

.text-tooltip{
  visibility: hidden;
  position: absolute;
  background: #091DC9;
  color: white;
  z-index: 1;
  top: 2rem;
  left: 1rem;
  font-size: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  max-width: 10rem;
  white-space: wrap;
}

.webapp-selected-training {
    position: absolute;
    background: var(--Azul-Azul-Claro-Azul-1, #CED3FE);
    height: 1.5rem;
    width: 1.5rem;
    bottom: 0%;
    right: 0%;
    padding: 0.25rem;
    border-radius: 8px 0px 0px 0px;
}
