.search-bar-box {
    width: 20rem;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    
    border: 2px solid var(--azul-azul-claro-azul-1, #3C50FC);
    border-radius: 0.5rem;
    
    padding: 0.7rem 1rem;
    gap: 2rem;
    position: relative;
}

.search-bar-input{
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;

    /* padding-: -2rem; */

}

.search-bar-input::placeholder{
    /* text-align: center; */
    opacity: 0.5;
}