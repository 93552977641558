.create-evaluation-form-item-container {
    display: flex;
    width: 100%;
    gap: 2rem;
    margin: 4vh 0;
}

.create-evaluation-form-final-step {
    padding-left: 5rem;
}

.create-evaluation-form-input-container {
    display:flex;
    flex-direction: column;
    gap: 0.5rem;
}

.create-evaluation-form-input-container > label {
    color: var(--PRETO, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
}

.create-evaluation-form-input-container > div > input {
    width: 100%;
    height: 2.5rem;
    border-radius: 2px;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732);
    padding: 0 1rem;
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.create-evaluation-form-input-missing {
    border-color: var(--outros-vermelho, #ED4863) !important;
}