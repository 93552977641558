.webapp-players {
    display: flex;
}

.webapp-players-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4rem 2rem;
    gap: 2rem;
    overflow: hidden;
}

.webapp-players-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.webapp-players-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #3c50fc;
    color: white;
    text-decoration: none;
}

.webapp-players-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.webapp-players-table {
    border-collapse: collapse;
    border-spacing: 0;
}

.webapp-players-table th {
    font-weight: 500;
    text-align: left;
}

.webapp-players-table th:first-child {
    border-right: 1px solid #ced3fe;
}

.webapp-players-table-header th {
    background-color: white;
    padding: 1rem 1rem;
    border-bottom: 2px solid #ced3fe;
}

/* .webapp-players-table-body{
    background-color: #f4f5ff;
} */

.webapp-players-table td,
.webapp-players-table-body th {
    padding: 2rem 1rem;
    border-bottom: 1px solid #ced3fe;
}
.webapp-players-table-body .webapp-players-table-row:hover {
    background-color: #ebedff;
    cursor: pointer;
}
