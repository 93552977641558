.webapp-fullhistory{
    display: flex;
}

.webapp-fullhistory-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4rem 2rem;
    gap: 4rem;
}

.webapp-fullhistory-section-pastpurchases{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}