.webapp-create-game-page {
    width: 100%;
    padding: 4vh 2vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.webapp-menu-return {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.webapp-menu-return button {
    border-radius: 50%;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    border: none;
    width: 2.75rem;
    height: 2.75rem;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.webapp-menu-return h1 {
    color: rgba(0, 0, 0, 0.60);
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;
}

.container {
    padding-left: 8rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
}

.webapp-game-number {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 4rem;
    width: 12%;
    background-color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.webapp-game-number h1 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 137.19%;
    color: #3C50FC;
}

.webapp-game-info {
    padding-top: 4rem;
    padding-left: 5.5rem;
    width: 85%;
    height: fit-content;
    background-color: white;
    gap: 2rem;
}

.create-game-info-form-step-header {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.create-game-info-form-step-header span {
    border-radius: 50%;
    border: 1.5px solid #6978F9;
    background-color: white;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6978F9;
    font-size: 1rem;
    font-weight: 400;
}

.create-game-info-form-selected-step {
    background-color: var(--azul-azul-claro-azul-4, #CED3FE) !important;

}

.create-game-info-form-step-items {
    margin-left: 1.5rem;
    border-left: 1px solid #6978F9;
    min-height: 10rem;
    padding-left: 5.5rem;
    display: flex;
}

.create-game-info-form-step-items2 {
    margin-left: 2.2rem;
    min-height: 10rem;
    padding-left: 5.5rem;
}

.create-game-info-form-step-buttons {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: right;
    padding-right: 5rem;
    padding-bottom: 3rem;
}

.games-buttons {
    display: flex;
    gap: 2rem;
}

.anterior-button {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    border-radius: 10px;
    /* Azul/Azul Claro/Azul 1 */
    color: #3C50FC;
    padding: 1rem;
    cursor: pointer;
}

.anterior-button:hover {
    background-color: #EBEDFF;
}

.proximo-button {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    border-radius: 10px;
    color: #3C50FC;
    padding: 1rem;
    border: 1px solid #3C50FC;
    cursor: pointer;
}

.save-game-button{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    border-radius: 10px;
    color: white;
    padding: 1rem;
    background-color: #0B24FB;
    cursor: pointer;
}

.proximo-button:hover {
    background-color: #EBEDFF;
}