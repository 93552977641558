.webapp-games-page-scroll {
    display: flex;
    max-width: 100%;
    overflow: none;
}

.webapp-games-page {
    width: 100%;
    padding: 4vh 2vw;
    min-height: 92vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
}

.webapp-games-folder-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.webapp-games-folder-container-header button {
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3c50fc);
    color: white;
    padding: 0.75rem 1.365rem;
    font-size: 1.125rem;
    font-family: "Lato";
    font-weight: 600;
    border: none;
}

.webapp-games-folder-container-header h1 {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Lato";
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;
}

.webapp-games-folder-container-header button:hover {
    cursor: pointer;
}

.webapp-games-page-games-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.webapp-games-page-table-mods {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: calc(50% + 10rem);
    height: 2.5rem;
}
