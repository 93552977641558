.webapp-db-filter{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
    border: 2px solid #9DA7FD;
    color: #040E64;
    background-color: #FFFFFF;
    max-height: 20rem;
    overflow-y: auto;
}

.webapp-db-filter-name{
    width: 100%;
    border-bottom: 2px solid #9DA7FD;
    display: flex;
    justify-content: center;
    padding: 0.25rem 4rem;
}

.webapp-db-filters-clickables{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding-bottom: 0.5rem;
}

.webapp-db-select-all{
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #EBEDFF;
    width: 100%;
    padding: 0.5rem 0;
    cursor: pointer;
    opacity: 0.5;
}

.webapp-db-select-all-input{
    display: none;
}

.webapp-db-filter-clickable{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.webapp-db-filter-clickable:hover .webapp-db-filter-checkbox:has(input:checked){
    border: none;
}

.webapp-db-filter-clickable:hover .webapp-db-filter-checkbox{
    border: 1px solid #020732;
}

.webapp-db-filter-checkbox{
    border: 1px solid #02073299;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
}

.webapp-db-filter-checkbox:has(input:checked){
    border: none;
}

.webapp-db-filter-clickable .checkbox{
    height: 1.5rem;
    width: 1.5rem;
    accent-color: #6978F9;
    opacity: 0;
}

.webapp-db-filter-clickable .checkbox:checked{
    opacity: 1;
}

.webapp-db-filter-radio{
    border: 1px solid #02073299;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
}

.webapp-db-filter-clickable .radio{
    height: 1.5rem;
    width: 1.5rem;
    accent-color: #6978F9;
    opacity: 0;
}

.webapp-db-filter-clickable .radio:checked{
    opacity: 1;
}

