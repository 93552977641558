.webapp-gamestats {
    display: flex;
    width: 100%;
}

.webapp-gamestats-return {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    width: 100%;
}

.webapp-gamestats-return button{
    border-radius: 50%;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    border: none;
    width: 2.75rem;
    height: 2.75rem;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.webapp-gamestats-return span{
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.webapp-gamestats-status-toplay {
    border: 1px solid #FF7D06;
    border-radius: 6.25rem;
    background-color: #FF7D06;
}

.webapp-gamestats-status-scheduled {
    border: 1px solid #3C50FC;
    border-radius: 6.25rem;
    background-color: #3C50FC;
}

.webapp-gamestats-status-finished {
    border: 1px solid #7FC035;
    border-radius: 6.25rem;
    background-color: #7FC035;
}

.webapp-gamestats-status-toplay span, .webapp-gamestats-status-scheduled span, .webapp-gamestats-status-finished span{
    font-family: 'Lato';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
    color: white;
}

.webapp-gamestats-status-toplay div, .webapp-gamestats-status-scheduled div, .webapp-gamestats-status-finished div {
    display: flex;
    gap: 0.5rem;
    padding: 0.4rem 1.125rem;
}

.webapp-gamestats-return{
    display: flex;
    gap: 1.5rem;
    align-items: center;
    width: 100%;
}

.webapp-gamestats-return button{
    border-radius: 50%;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    border: none;
    width: 2.75rem;
    height: 2.75rem;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.webapp-gamestats-return span{
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.webapp-gamestats-status-toplay {
    border: 1px solid #FF7D06;
    border-radius: 6.25rem;
    background-color: #FF7D06;
}

.webapp-gamestats-status-scheduled {
    border: 1px solid #3C50FC;
    border-radius: 6.25rem;
    background-color: #3C50FC;
}

.webapp-gamestats-status-finished {
    border: 1px solid #7FC035;
    border-radius: 6.25rem;
    background-color: #7FC035;
}

.webapp-gamestats-status-toplay span, .webapp-gamestats-status-scheduled span, .webapp-gamestats-status-finished span{
    font-family: 'Lato';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
    color: white;
}

.webapp-gamestats-status-toplay div, .webapp-gamestats-status-scheduled div, .webapp-gamestats-status-finished div {
    display: flex;
    gap: 0.5rem;
    padding: 0.4rem 1.125rem;
}

.webapp-gamestats-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.375rem 2rem 0 2rem;
    gap: 2rem;
}

.webapp-gamestats-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.webapp-gamestats-game-info {
    display: flex;
    flex-direction: column;
    border: 1px solid #9DA7FD;
    border-radius: 0.5rem;
}

.webapp-gamestats-game-info h2 {
    font-size: 1rem;
    color: #040E64;
}

.webapp-gamestats-game-info>h2 {
    font-size: 1rem;
    width: 100%;
    background-color: #EBEDFF;
    border-bottom: 1px solid #9DA7FD;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 0.5rem 1.5rem;
}

.webapp-gamestats-game-teams {
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.webapp-gamestats-game-teams-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}

.webapp-gamestats-game-teams-home-color {
    background-color: #B0D487;
    height: 1rem;
    width: 1rem;
    border-radius: 0.5rem;
}

.webapp-gamestats-game-teams-away-color {
    background-color: #C7A5EA;
    height: 1rem;
    width: 1rem;
    border-radius: 0.5rem;
}

.webapp-gamestats-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.webapp-gamestats-body-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.webapp-gamestats-body-menu-title {
    padding: 1.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    font-size: 1rem;
    cursor: pointer;
}

.webapp-gamestats-body-menu-title:hover {
    background-color: #ffffff80;
    background-blend-mode: lighten;
}

.webapp-gamestats-body-menu-title-selected {
    background-color: #FFFFFF;
}

.webapp-gamestats-body-menu-title-selected:hover {
    background-color: #ffffff;
}

.webapp-gamestats-body-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 3rem 2rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: #FFFFFF;
    height: 100%;
}

.webapp-gamestats-body-content-button{
    font-size: 1rem;
    align-self: flex-end;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: #CED3FE;
    border: 2px solid #3C50FC;
    color: #3C50FC;
    cursor: pointer;
}

.webapp-gamestats-body-content-score {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 4rem;
}

.webapp-gamestats-body-content-points {
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    border: 1px solid #6978F9;

}

.webapp-gamestats-body-content-home {
    background-color: #B0D487;
}

.webapp-gamestats-body-content-adversary {
    background-color: #C7A5EA;
}

.webapp-gamestats-body-content-points-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 0.25rem 0.25rem 0 0;
    border-bottom: 1px solid #6978F9;
    padding: 0.5rem 1rem;
    background-color: #ffffff80;
}

.webapp-gamestats-body-content-points-title-location {
    opacity: 0.5;
}

.webapp-gamestats-body-content-points-title-name {
    color: #040E64;
}

.webapp-gamestats-body-content-points-content {
    display: flex;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    padding: 2rem 1rem;
    align-items: center;
}

.webapp-gamestats-body-content-points-content-button {
    font-size: 3rem;
    line-height: 2.4rem;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border-radius: 2rem;
    border: 3px solid #EBEDFF;
    color: #EBEDFF;
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.webapp-gamestats-body-content-points-content-button:hover {
    background-color: #FFFFFF;
}

.webapp-gamestats-body-content-points-content-points {
    font-size: 5rem;
    text-align: center;
    color: #FFFFFF;
    width: 5.5rem;
    display: flex;
    justify-content: center;
}

.webapp-gamestasts-body-periods-time{
    display: flex;
    flex-flow: row;
    gap: 1rem;
}

.webapp-gamestasts-body-periods-time input{
    width: 35%;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
}

/* Chrome, Safari, Edge, Opera */
.webapp-gamestasts-body-periods-time input::-webkit-outer-spin-button,
.webapp-gamestasts-body-periods-time input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.webapp-gamestasts-body-periods-time input[type=number] {
    -moz-appearance: textfield;
}

.webapp-gamestats-period-clock{
    width: 1.2rem;
    margin-left: -1.5rem;
    margin-bottom: -0.25rem;
}

.webapp-gamestats-body-content-subtitle {
    margin-top: 3rem;
}

.webapp-gamestats-body-content-input {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
}

.webapp-gamestats-finish {
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    background-color: #0B24FB;
    border: none;
    align-self: flex-end;
    margin: 0 2rem 2rem 2rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    width: 10%;
    text-wrap: nowrap;
    cursor: pointer;
}



.webapp-gamestats-individual-page-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0rem;
}

.webapp-gamestats-individual-page-top-bar {
    background-color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    max-width: 100%;
    background-color: #EBEDFF;
    border: 1px solid #9DA7FD;
    border-bottom: 2px solid #6978F9;
    border-spacing: 0px !important;
    display: flex;
    align-items: center;
}

.webapp-gamestats-individual-page-itens {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.webapp-gamestats-individual-scroll-items {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    width: 85%;
}

.webapp-gamestats-individual-scroll-items img {
    align-self: center;
}

.webapp-gamestats-page-items {
    display: flex;
    justify-content: space-around;
    align-content: center;
    height: 40%;
    flex-grow: 1;
}

.webapp-gamestats-individual-table-item {
    background-color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    max-width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
}

.webapp-gamestats-individual-table-item-fixed-column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    height: 100%;
    padding: 1rem;
    border-right: solid 3px #CED3FE;
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.webapp-gamestats-column-name{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    
}

.webapp-gamestats-column-name img{
    width: 1.125rem;
    height: 1.125rem;
    cursor: default;
}

.hidden-button{
    opacity: 0;
}

.webapp-gamestats-individual-counter-input{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
    background-color: #EBEDFF;
    border: none;
}

.webapp-gamestats-column-name input::-webkit-outer-spin-button,
.webapp-gamestats-column-name input::-webkit-inner-spin-button {
    display: none; 
    -webkit-appearance: none;
    margin: 0; 
}

.webapp-gamestats-column-name input[type=number] {
    appearance: textfield;
}


.webapp-gamestats-column-name:focus-within > img{
    opacity: 1!important;
    cursor: pointer;
}

.webapp-gamestats-column-name:focus-within > h3{
    background-color: #CED3FE;
}


.webapp-gamestats-column-item-container {
    display: flex;
    align-items: center;
    width: 18%;
}

.game-stats-table-indivual {
    width: 18% !important;
    justify-content: center !important;
}

.game-stats-custom-input {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.game-stats-custom-input select, .game-stats-custom-input > input, .game-stats-counter-container > span{
    background-color: #EBEDFF;
    border: none !important;
    padding: 0.5rem 0.25rem;
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 2px;
    width: 100%;
    text-align: center;
    height: 100%;

}

.game-stats-counter-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.game-stats-custom-checkbox {
    border-radius: 50%;
    border: 2px solid #6978F9;
    height:1.5rem;
    width: 1.5rem;
    opacity: 50%;
    
    
}