.contactos-page {
    width: 84.7vw;
    margin: 14.5vh auto;
    display:flex;
    flex-direction: column;
    position: relative;
}

.contactos-background-logo {
    position: absolute;
    bottom: 2vh;
    left: 75vw;
    width: 80%;
    height: auto;
    z-index: -2;
    opacity: 40%;
}


.contactos-contactos {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.contactos-contactos h2 {
    margin-bottom: 3rem;
}
.contactos-container {
    display:flex;
    gap: 10rem;
    padding-bottom: 4rem;
}
.contactos-page-telemovel {
    display:flex;
    flex-direction: column;
    gap: 0.5rem;
}

.contactos-page-telemovel span {
    font-family: 'Lato';
    font-size: 1.25rem;
    font-style: normal;
    line-height: 1.5rem
}
.asterisco {
    color: var(--outros-vermelho, #ED4863);
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: 0.25rem;
}

.contactos-page-logos {
    display: flex;
    gap: 1.625rem;
}

.contactos-page-logos img {
    width: 3rem;
    height: 3rem;
}

/* AQUI JA E PARA O FORMULARIO */

.contactos-formulario {
    display:flex;
    flex-direction: column;
    margin-top: 4rem;
}

.formulario-inputs input,
.contactos-formulario select {
    width: 41vw;
    border-radius: 3px;
    border: 1.5px solid #000;
    background: #F4F5FF;
    height: 3.9rem;
    padding: 1rem; /* Add your desired padding value here */
    box-sizing: border-box; /* Include padding and border within the width */
    color: #000;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.formulario-dropdown option:first-child {
    display:none;
}

.contactos-formulario input::placeholder,
.contactos-formulario select::placeholder,
.formulario-textarea textarea::placeholder,
.formulario-dropdown  {
    color: rgba(0, 0, 0, 0.50);
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contactos-formulario h4 {
    margin-top: 1.5rem;
    margin-bottom: 4rem;
}

.contactos-formulario-container {
    margin-top: 2rem;
    display:flex;
    width: 100%;
    flex-direction: column;
    gap:2rem;
}

.formulario-inputs {
    display:flex;
    justify-content: space-between;
}

.formulario-inputs div, .formulario-textarea {
    display:flex;
    flex-direction: column;
    gap: 0.5rem;
    
}

.contactos-formulario label {
    color: #000;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.5rem;
}

.formulario-textarea textarea {
    padding: 0.5rem 1rem;
    color: #000;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #F4F5FF;
}

.formulario-textarea {
    height: 13.625rem;
}

.declaracao-submissao {
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
}

.formulario-declaracao {
    display:flex;
    flex-direction: column;
    gap: 1rem;
}

.formulario-declaracao h5 {
    color: #000;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.formulario-checkbox-container {
    display:flex;
    gap: 1rem;
    align-items: center;
    width: 60vw;
}

.formulario-custom-checkbox{
    border: 1.5px solid #020732;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;

    background-color: #F4F5FF;
}

.formulario-checkbox {
    opacity: 0;
    margin: 0;
    height: 3rem;
    width: 3rem;
}

.formulario-checkbox:checked{
    opacity: 1;
    padding: 0.5rem;
    height: 3rem;
    width: 3rem;
    accent-color: #F4F5FF;
}

.form-error{
    border: 1.5px solid #ED4863!important;
    border-radius: 3px!important;
}

.error-message{
    display: none;
    margin-top: 0.5rem;
    color: #ED4863;
}

.error-message.checkbox{
    margin-top: -1.5rem;
}

.visible{
    display: block!important;
}

.formulario-checkbox:hover {
    cursor: pointer;
}

.declaracao-submissao button {
    border-radius: 6px;
    background: #3C50FC;
    border: none;
    width: 25rem;
    height: 3.9rem;
    color: white;
    font-family: 'Lato';
    align-items: center;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
    display: flex;
    justify-content: center;
    gap: 0.2rem;
}

.declaracao-checkbox-label{
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    width: 80%;
}

.submit-arrow-right {
    font-size: 1.375rem;
}
.declaracao-submissao button:hover {
    cursor: pointer;
}


.contactos-page input:focus, textarea:focus, select:focus {
    outline: #3C50FC auto 2px;

}




@media (max-width:900px) {
    .formulario-checkbox:checked {
        height:2.5rem;
        width:2.5rem;   
    }
    .contactos-page {
        width: 89.75vw;
    }
    .contactos-contactos h2 {
        font-size: 1.375rem;
        margin-bottom: 2rem;
    }
    .contactos-container {
        flex-direction: column;
        gap: 2rem;       
    }
    .contactos-formulario {
        margin-top: 2rem;
    }
    .contactos-formulario p {
        font-size: 1rem;
    }
    .contactos-formulario h4 {
        font-size: 1.125rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .contactos-formulario label {
        font-size: 1rem;
    }
    .formulario-inputs input,
    .contactos-formulario select {
        width: 100%;
        height: 2.7rem;
        padding: 0.5rem;
        font-size: 1rem;
    }

    .contactos-formulario input::placeholder,
    .contactos-formulario select::placeholder,
    .formulario-textarea textarea::placeholder,
    .formulario-dropdown {
        font-size: 1rem;
        
    }

    .formulario-textarea textarea {
        font-size: 1rem;
    }
    .contactos-formulario-container {
        margin-top: 1rem;
        gap: 1rem;
    }
    .formulario-inputs {
        display:flex;
        flex-direction: column;
        gap: 1rem;

    }
    .formulario-inputs div, .formulario-textarea {
        gap: 0.25rem;
    }

    .formulario-declaracao h5 {
        font-size: 1rem;
    }
    .formulario-declaracao p {
        font-size: 0.875rem;
        line-height: 1rem;
        
    }
    .declaracao-submissao {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .declaracao-submissao button {
        margin-top: 1rem;
        width: 100%;
        height: 2.5rem;
        font-size: 1rem;
    }

    .submit-arrow-right {
        font-size: 1rem;
    }

    .formulario-declaracao {
        width: 100%;
        margin-bottom: 2rem;
    }

    .formulario-checkbox-container {
        width: 100%;
        align-items: flex-start;
        
    }

    .formulario-checkbox {
        height: 2.5rem;
        width: 2.5rem;
    }
    .formulario-custom-checkbox {
        height: 2.5rem;
        width: 2.5rem;
    }

    .asterisco {
        font-size: 1rem;
    }
    .contactos-page {
        margin-bottom: 8vh;
    }

    .contactos-page-telemovel span {
        font-size: 1.125rem;
    }

    .contactos-page-telemovel p {
        font-size: 1.125rem;
    }

    
}


@media ( max-width: 650px) {
    .formulario-checkbox {
        width: 2rem;
        height: 2rem;
    }
    .formulario-custom-checkbox {
        width: 2rem;
        height: 2rem;
    }
    .contactos-background-logo {
        width: 120%;
        left: 60vw;
    }
    .formulario-checkbox:checked {
        height:2rem;
        width:2rem;   
    }
    
}


@media (max-width: 400px) {

    .contactos-page-logos {
        gap: 1.2rem;
    }
    .contactos-page-logos img {
        width: 2.5rem;
        height: 2.5rem;
    }
}