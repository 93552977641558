.webapp-cardhistory{
    display: flex;
}

.webapp-cardhistory-section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.webapp-cardhistory-section-title{
    font-size: 1.5rem;
    opacity: 0.6;
}

.webapp-cardhistory-section-content{
    display: flex;
    gap: 2rem;
    align-items: flex-start;
}

.webapp-cardhistory-section-cards{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
}

.webapp-cardhistory-section-cards2{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start
}

.webapp-cardhistory-section-cardinfo{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #EBEDFF;
    width: 40vw;
    transition: outline 0.2s ease-in-out;
}

.webapp-cardhistory-section-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: outline 0.2s ease-in-out;
}

.webapp-cardhistory-section-card-changeicon-invert{
    transform: rotate(180deg);
}

.webapp-cardhistory-section-cardinfo-moreinfo{
    display: flex;
    gap: 0.5rem
}

.webapp-cardhistory-section-cardinfo-moreinfo-title{
    color: #9DA7FD;
}

.webapp-cardhistory-section-buttons{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
}

.webapp-cardhistory-section-buttons-change{
    flex-direction: row;
}

.webapp-cardhistory-section-card-selected{
    outline: 2px solid #3C50FC;
}
.webapp-cardhistory-section-card-change:hover{
    outline: 1px solid #3C50FC66;
    cursor: pointer;
}

.webapp-cardhistory-section-card-selected:hover{
    outline: 2px solid #3C50FC;
}

.webapp-cardhistory-section-cancel{
    background-color: transparent;
    color: #3C50FC;
}

.webapp-cardhistory-section-card-delete{
    cursor: pointer;
    color: #3C50FC;
}

.webapp-cardhistory-section-card-changeicon{
    cursor: pointer;
}

.webapp-cardhistory-section-button{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    width: max-content;
    border: none;
    cursor: pointer;
}

.webapp-cardhistory-section-change{
    color: #3C50FC;
    border: 1px solid #3C50FC;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
}

.webapp-cardhistory-section-change:hover{
    background-color: #3C50FC33;
}

.webapp-cardhistory-section-change-active{
    background-color: #3C50FC33;
}

.webapp-cardhistory-section-add{
    color: white;
    background-color: #3C50FC;
}

.webapp-cardhistory-section-remove{
    color: #ED4863;
    border: 1px solid #ED4863;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
}

.webapp-cardhistory-section-remove-active{
    background-color: #ED486333;
}

.webapp-cardhistory-section-remove:hover{
    background-color: #ED486333;
}

.webapp-cardhistory-section-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40vw;
}

.webapp-cardhistory-section-history{
    text-decoration: none;
    color: #3C50FC;
}