body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6,p {
  font-family: 'Lato';
  margin:0
}

*{
  font-family: 'Lato' !important;
}

.black-16px-600 {
  color: #000;
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.black-16px-700 {
  color: #000;
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dark-blue-16px-600 {
  color: var(--azul-azul-escuro-azul-10, #010314);
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.05em;
  text-decoration: none;
}

.dark-blue-16px-700 {
  color: var(--azul-azul-escuro-azul-10, #010314);
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.05em;
}



.dark-blue-15px-700 {
  color: var(--azul-azul-escuro-azul-9, #020732);
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gray-16px-400 {
  color: rgba(255, 255, 255, 0.65);
  text-align: center;
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.white-16px-400 {
  color: #FFF;
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.black-16px-400 {
  color: #000;
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.webapp-asterisco {
    color: var(--outros-vermelho, #ED4863);
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.black-18px-800 {
  color: #000;
  font-family: 'Lato';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 800 !important;
  line-height: normal;
}

.black-18px-400 {
  color: #000;
  font-family: 'Lato';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.gray-16px-700 {
  color: rgba(1, 3, 20, 0.50);

  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}
