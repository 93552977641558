.webapp-popup-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    overflow: hidden;
}

.webapp-popup{
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 4rem;
    gap: 2rem;
    min-width: 40vw;
}

.webapp-popup-warning{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.webapp-popup-title-close{
    font-size: 2rem;
    font-weight: 800;
    color: #ED4863;
}

.webapp-popup-title-advance{
    font-size: 2rem;
    font-weight: 800;
    color: #A3CF71;
}

.webapp-popup-title-normal{
    font-size: 2rem;
    font-weight: 800;
    color: #040E64;
}

.webapp-popup-message{
    width: 100%;
    max-width: 40vw;
    text-align: center;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.webapp-popup-line{
    width: 100%;
    border: 2px solid #CED3FE;
    border-radius: 10px;
}

.webapp-popup-buttons{
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 2rem;
    padding: 0 2rem;
}