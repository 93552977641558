.webapp-inside-folder{
    display: flex;
    width: 100%;
}

.webapp-inside-folder-main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 4vh 2vw;
    gap: 3rem;
}

.webapp-inside-folder-main-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.webapp-inside-folder-main-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.webapp-inside-folder-main-header-button{
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: white;
    padding: 0.75rem 1.365rem;
    font-size: 1.125rem;
    font-family: 'Lato';
    font-weight: 600;
    border: none;
    cursor: pointer;
    position: relative;
}

.webapp-inside-folder-main-header-button-dropdown{
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--click);
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: white;
    color: #3C50FC;
}

.webapp-inside-folder-main-header-button-dropdown-option{
    padding: 0.5rem 1rem;
    width: 100%;

    text-align: left;

    cursor: pointer;
}

.webapp-inside-folder-main-header-button-dropdown-option:hover{
    background-color: #EBEDFF;
}

.webapp-inside-folder-main-header-button-dropdown div:nth-child(1){
    border-bottom: 0.7px solid rgba(2, 7, 50, 0.2);
}

.webapp-inside-folder-main-folders{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.webapp-inside-folder-main-folders .webapp-trainings-folder-grid-item a{
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #000;
    text-decoration: none;
    width: 100%;
    padding: 1rem;
}

.webapp-inside-folder-main-exercises{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
    column-gap: 4%;
    row-gap: 6rem;
    gap: 2rem;
}

.webapp-inside-folder-main-exercises > div:nth-child(5n) .webapp-trainings-folder-menu-popup{
  right: 1rem;
}
.webapp-inside-folder-popup{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    overflow: hidden;
}

.webapp-inside-folder-add-training{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: calc(2 * (224px + 1rem));
    width: 50vw;
}

.webapp-inside-folder-add-training-elements{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    overflow-y: scroll;
}

.webapp-inside-folder-add-training-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}
