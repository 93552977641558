.games-form-container {
    display: flex;
    justify-content: center;
}

.games-forms-label {
    
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 5rem;
    padding-right: 5rem;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
}

.games-forms-label:first-child {
    border-radius: 5px 0 0 5px;
}

.games-forms-label:last-child {
    border-right: 1px solid black;
    border-radius: 0 5px 5px 0;
}

.games-forms-label:hover {
    background-color: #CED3FE;
}

.games-forms-label-selected {
    background-color: #9DA7FD;
}
.games-forms-label-selected:hover {
    background-color: #9DA7FD;
}