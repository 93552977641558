.webapp-create-injury-page {
    width: 100%;
    padding: 4vh 2vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
}

.webapp-create-injury-page .webapp-create-training-exercise-info {
    width: 100% !important;
}

.webapp-create-injury-header-inputs {
    display: flex;
    gap: 2rem;
    width: 50%;
}


.select-player-dropdown-container {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;

    width: 80%;
    height: 2.5rem;
    border-radius: 2px;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732);
    background-color: white;
    position: relative;
    line-height: normal;
    cursor: pointer;
}

.webapp-players-injury-options:hover {
    background-color: #EBEDFF;
}

.open-select-player-dropdown-container {
    border-radius: 2px 2px 0 0;
}

.select-player-dropdown-container>span {
    width: 80%;
    color: #000000;
    opacity: 0.6;
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    padding-left: 1rem;
}

.select-player-dropdown-container>div {
    margin-right: 1rem;
}

.webapp-create-injury-page .webapp-create-training-header-left-inputs>div:nth-child(1) {
    flex: 2 1;
}

.webapp-create-injury-page .webapp-create-training-header-left-inputs>div:nth-child(2) {
    flex: 1 1;
}

.select-player-dropdown {
    margin-top: 1px;
    padding: 0.5rem;
    top: 100%;
    overflow-y: auto;
    position: absolute;
    z-index: 3;
    display: flex;
    gap: 0rem !important;
    flex-direction: column;
    border: 0.5px solid rgba(2, 7, 50, 0.2);
    width: 100%;
    height: fit-content;
    max-height: 25vh;
    background-color: white;
    border-radius: 0 0 2px 2px;
}

.select-player-dropdown>div {
    display: flex;
    padding: 0.5rem 0rem 0.5rem 0.5rem;
    border-radius: 2px;
    color: var(--PRETO, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    cursor: pointer;
}

.select-player-dropdown>div:not(:first-child){
    border-top: 0.5px solid rgba(2, 7, 50, 0.2);
}

.selected-option-player-dropdown {
    background-color: #CED3FE;
}

.webapp-create-injury-container {
    width: 100%;
    background-color: white;
    height: 100%;
}

.injury-error {
    border-color: red !important;
}

.webapp-injury-delete-button {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    border-radius: 3px;
    text-align: left;
    width: fit-content;
    height: fit-content;
    align-content: center;
    color: #ED4863;
    padding: 5px;
    border: 8px;
    cursor: pointer;
}

.webapp-injury-delete-button:hover {
    background-color: #ed486339;
}

.webapp-injury-delete-button img {
    width: 1rem;
    height: 1rem;
    align-self: center;
}