.container-adversary {
    padding-top: 1rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    gap:0.5rem;
}

.container-adversary2 {
    display: flex;
    padding-top: 1rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:0.5rem;
}

.adversary-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.adversary-info label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
}

.adversary-info input {
    display: flex;
    gap: 0.1rem;
    width: 13rem;
    height: 2.5rem;
    align-items: center;
    padding-left: 0.8rem;
    padding-right: 1rem;
    border: 1px solid black;
}

.game-box-info {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid black;
    justify-content: space-between;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}

.game-box-info img {
    width: 1.5rem;
}

.team-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.5rem;
}

.team-info img {
    width: 4rem;
}

.team-info span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 13.4957px;
    line-height: 137.19%;
}

.webapp-add-game-teams-options {
    opacity: 0;
    position: absolute;
    top: 55%;
    width: 13rem; 
    max-height: 25vh;
    overflow-y: auto;
    background-color: white;
    border: 1px solid black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
    transition: opacity 0.05s ease;
}

.webapp-add-game-teams-option {
    padding: 0.5rem;
}

/* Show the options when input is focused */
.adversary-info input:focus + .webapp-add-game-teams-options {
    opacity: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
