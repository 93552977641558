.addplayer-session-exercise-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; 
    z-index: 100;
    background: rgba(1, 3, 20, 0.50);
    display: flex;
    justify-content: center;
    align-items: center;
}


.webapp-addplayer-session-exercise-popup{
    background: var(--Fundo-Website, #F4F5FF);
    border-radius: 0.5rem;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 60%;
}

.webapp-addplayer-session-exercise-popup-header p{
    font-family: Lato;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-bottom: 0.1875rem solid var(--Cinza-Icones, #CED3FE);
}


.webapp-addplayer-session-exercise-popup-body h3{
    font-family: Lato;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.webapp-addplayer-dropdown-textholder p{
    height: 1.5rem;
    overflow: hidden;
}

.webapp-addplayer-session-exercise-popup-body{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.webapp-addplayer-session-exercise-popup-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.webapp-addplayer-session-exercise-popup-body .multiple-choice-dropdown-component {
    position: relative !important;
    overflow-y: auto !important;
    height: 10rem; 
}

.webapp-addplayer-session-exercise-popup-dropwdown-holder {
    height: 10rem;
    margin-top: -2rem;
}

.webapp-addplayer-session-exercise-popup-buttons button{
    display: inline-flex;
    padding: 0.75rem 1.375rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.375rem;
    background: var(--Azul-Azul-Claro-Azul-1, #3C50FC);
    border: none;
    cursor: pointer;
    
    color: #FFF;
    font-family: Lato;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.cancel-button{
    background: none! important;
    color: var(--Azul-Primario, #3C50FC) !important;
}