.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 1000; /* Ensure the popup is on top of other elements */
  }

  .popup-inner {
    background-color: #fff; /* Background color of the popup content */
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05); /* Optional: Add a subtle shadow */
    min-width: 60%;
    min-height: 53%;
    /* Set a max width for the popup content */
  }

.popup-header {
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 5rem;
    background-color: var(--azul-azul-claro-azul-1, #3C50FC);
    border-radius: 8px 8px 0 0;
}

.popup-body {
    display:flex;
    flex-direction: column;
    padding: 2.5rem 5rem;
    gap: 2rem;
}

.popup-body-data {
    display:flex;
    flex-direction: column;
    gap: 2rem;
}

.popup-name-email {
    display:flex;
    justify-content: space-between;
    width: 80%;
    gap: 4rem;
}
.popup-name-email div{
    width: 50%;
}
.popup-header h2 {
    font-family: 'Lato';
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8rem; /* 100% */
    letter-spacing: 0.22px;    
    color: #FFF;
}

.popup-body h3 {
    font-family: 'Lato';
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 84.615% */
    letter-spacing: 0.26px;
    text-transform: uppercase;
}

.popup-body-data p {
    font-family: 'Lato';
    font-size: 1.375rem;
    font-style: normal;
    line-height: 1.8rem;
    letter-spacing: 0.22px;
    font-weight: 400;
    
    word-wrap: break-word;
}


.popup-buttons {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
}

.edit-button {
    color: var(--azul-azul-claro-azul-1, #3C50FC);
    font-family: 'Lato';
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
    }

.edit-button:hover, 
.submit-button:hover {
    cursor: pointer;
}

.submit-button {
    border:none;
    border-radius: 6px;
    background: #3C50FC;
    padding: 1rem 6rem;
    color: #FFF;
    font-family: 'Lato';
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}

.popup-success-inner {
    
    
    border-radius: 8px;
    background: #FFF;

    /* shadow2 */
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    padding: 4rem 4rem;
}

.success-icon {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
}

.success-text {
    display:flex;
    flex-direction: column;
    gap: 1rem;
}

@media (max-width: 1600px) {
    .popup-name-email {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2rem;
    }
    .popup-name-email div {
        width: 100%;
    }

    .popup-header {
        
        padding: 2rem;
       
    }
    
    .popup-body {
        padding: 2rem;
    }
}

@media (max-width: 1200px) {
    .popup-header h2 {
        text-align: center;
    }
    .popup-buttons {
        justify-content: space-between;
    }
    .submit-button {
        padding: 1rem 3rem;
        font-size:1rem;
    }
    .popup-body h3 {
        font-size: 1rem;
    }
    .popup-body-data p {
        font-size: 1rem;
    }
    .popup-header h2 {
        font-size: 1.125rem;
    }
    .edit-button {
        font-size: 1rem;
        line-height: normal;
    }
    .popup-success-inner { 
        padding: 1.5rem;
    }
    .success-text p {
        font-size: 1rem !important;
    
    }
}