.webapp-trainings-page-scroll {
    display: flex;
    max-width: 100%;
    overflow: none;

}

.webapp-trainings-hide-overflow {
    overflow: hidden;

}

.webapp-trainings-page {
    width: 100%;
    padding: 4vh 2vw;
    min-height: 92vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.webapp-trainings-page-folders-container{
    padding: 3rem 0rem;
}

.webapp-traings-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

}

.trainings-folder-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.trainings-folder-container-header button {
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: white;
    padding: 0.75rem 1.365rem;
    font-size: 1.125rem;
    font-family: 'Lato';
    font-weight: 600;
    border: none;

}

.trainings-folder-container-header h1 {
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;

}

.trainings-folder-container-header button:hover {
    cursor: pointer;
}

.webapp-trainings-page-div-division {
    width: 100%;
    background: #CED3FE;
    height: 3px;

}

.webapp-trainings-page-small-division {
    width: 100%;
    height: 1px;
    background: rgba(2, 7, 50, 0.20);

    z-index: 2;
}

.webapp-trainings-folder-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    gap: 1rem;
    padding-right: 8rem;
}


.webapp-trainings-folder-grid::-webkit-scrollbar-track {
    background-color: #FFF;
    border-radius: 6px
        /* adjust the margin to compensate for the padding */
}

/* Style the scrollbar thumb */
.webapp-trainings-folder-grid::-webkit-scrollbar-thumb {
    background-color: #CED3FE;
    border-radius: 6px;
}

/* Change the scrollbar width */
.webapp-trainings-folder-grid::-webkit-scrollbar {
    width: 12px;

}

/* Adjust the scrollbar track width to compensate for the padding */
.webapp-trainings-folder-grid::-webkit-scrollbar-track-piece {
    background-color: #fff;
    border-radius: 6px
}

.webapp-trainings-folder-grid-item {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 21.375rem;
    cursor: pointer;
}

.webapp-trainings-folder-grid div a, .create-session-popup-folders div a {
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #000;
    text-decoration: none;
    width: 100%;
    padding: 1rem;
}

.webapp-trainings-training-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.webapp-trainings-training-image {
    height: 12rem;

}

.webapp-trainings-trainings-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
    column-gap: 4%;
    row-gap: 6rem;
}

.webapp-trainings-training-time-kebab {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
}

.webapp-trainings-training-folder-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0px 0px 6px 6px;
    background: var(--Branco, #FFF);
    height: 40%;
    /* shadow2 */
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);

}

.webapp-trainings-training-timer {
    display: flex;
    gap: 0.2rem;
    align-items: center;

}

.webapp-trainings-training-timer {
    color: rgba(1, 3, 20, 0.60);

    font-family: 'Lato';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.webapp-trainings-page-create-folder-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: rgba(2, 7, 50, 0.40);


}

.webapp-create-folder-popup-container {
    padding: 1.8rem 1.5rem;
    height: 12rem;
    width: 25rem;
    z-index: 3;
    border-radius: 10px;
    background: var(--Branco, #FFF);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
     /* shadow2 */
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);

}

.webapp-create-folder-popup-container h1 {
    color: rgba(0, 0, 0, 0.60);

    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;
    /* 41.156px */
}

.webapp-create-folder-popup-container input {
    border-radius: 2px;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732);
    height: 18%;
    padding: 0.5rem 0.875rem;

}

.webapp-create-folder-popup-container div {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    align-items: center;

}

.webapp-create-folder-button {
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: #FFF;
    border: none;
    padding: 0.75rem 1.375rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.webapp-cancel-folder-button {
    color: var(--azul-azul-claro-azul-1, #3C50FC);

    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.webapp-create-folder-button:hover,
.webapp-cancel-folder-button:hover {
    cursor: pointer;
}

.webapp-trainings-folder-kebab-menu {

    position: relative;
}

.webapp-trainings-folder-kebab-menu img {
    cursor: pointer;
    padding: 0 1rem;
}

.webapp-trainings-folder-menu-popup {
    position: absolute;
    z-index: 1;
    border-radius: 6px;
    background: #FFF;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 10rem;
    /* shadow1 */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
}

.webapp-trainings-popup-overflow {
    right: 0;
}

.trainings-folder-clickable:hover {
    cursor: pointer
}

.webapp-trainings-training-menu-popup img {
    cursor: pointer;
    padding: 0 0.5rem;
}

.webapp-trainings-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0 0 0;
}

.webapp-trainings-pagination-page-changer {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    align-items: center;
    height: 1.5rem;
}

.webapp-trainings-pagination-page-changer svg {
    height: 1rem;
}

.webapp-trainings-pagination-page-changer svg:hover {
    cursor: pointer;
}

.webapp-trainings-pagination-numbers {
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    align-items: center;
    height: 100%;
}

.webapp-trainings-pagination-numbers button {
    border: none;
    background-color: transparent;
    color: #000;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.webapp-trainings-pagination-numbers button.trainings-paginator-active {
    border-radius: 3px;
    background: var(--azul-azul-claro-azul-4, #CED3FE);

    height: 2rem;
    width: 2rem;
}

.webapp-trainings-pagination .disabled {
    pointer-events: none;
    opacity: 0.5;
}

.trainings-left-paginator-arrow:hover,
.trainings-right-paginator-arrow:hover {
    cursor: pointer;
}

.webapp-trainings-pagination-page-counter {
    display: flex;
    gap: 0.375rem;
}

.trainings-left-paginator-arrow,
.trainings-right-paginator-arrow {
    height: 2rem;
}

.trainings-right-paginator-arrow {
    transform: rotate(180deg);
}



/* @media (max-width: 1600px) {
    .webapp-trainings-training-folder-info {
        padding: 1rem 1rem;
        gap: 0.8rem;
    }

} */

.webapp-trainings-training-folder-info {
    padding: 1rem 1rem;
    gap: 0.8rem;
}

@media (max-width: 900px) {
    .webapp-trainings-trainings-grid {
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    }
}
