.webapp-injury-detail-page {
    display: flex;
    width: 100%;
}

.webapp-injury-detail-page-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.2rem 2rem 2rem 2rem;
    gap: 2rem;
    overflow: hidden;
}

.webapp-injury-detail-menu-return {
    display: flex;
    gap: 1rem;
    width: 100%;
    height: fit-content;
}

.webapp-injury-detail-menu-return button {
    border-radius: 50%;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    border: none;
    width: 2.75rem;
    height: 2.75rem;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;
}

.webapp-injury-detail-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0rem 1rem;
    margin: 1rem 0rem;
    border-left: 5px solid #3C50FC;
}

.webapp-injury-detail-player{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

.webapp-injury-detail-content{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
}

.webapp-injury-detail-table{
    display: flex;
    flex-direction: column;
    width: fit-content
}


.webapp-injury-detail-table h3{
    padding: 1rem;
    border-bottom: 2px solid #9DA7FD;
}

.webapp-injury-detail-table > *:nth-child(odd){
    background-color: #FFFF;
}

.webapp-injury-detail-table > *:nth-child(even){
    background-color: #EBEDFF;
}

.webapp-injury-detail-table-row{
    display: flex;
}

webapp-injury-detail-table-row-cell{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
}

.webapp-injury-detail-table-row-cell{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 8.5rem;
    width: 13rem;
    gap: 0.5rem;
    padding: 1rem;
}

/* All childs off webapp-injury-details-table-row, excluding the first */
.webapp-injury-detail-table-row-cell:not(:first-child){
    border-left: 1px solid #9DA7FD;
}

.cell-header{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.25rem;
    font-size: 1rem;
    font-weight: bold;
}
