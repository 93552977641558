.edit-button button{
    outline: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    border: 1px solid var(--azul-azul-claro-azul-1, #3C50FC);
    border-radius: 0.5rem;
    background-color: transparent;

    padding: 0.7rem 1rem;
}

.edit-button-text{
    color: var(--azul-azul-claro-azul-1, #3C50FC);
}