.navbar-container {
    display: flex;
    width: 100%;
    padding: 2rem 4rem;
    justify-content: space-between;
    background: white;
    align-items: center;
}

.navbar-logo {
    height: 100%;
    display: flex;
    align-items: center;
}

.textDisplay {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    gap: 0.35rem;
    padding-left: 1rem;
}

.jogador-info {
    display: flex;
    align-items: center;
    gap: 0.55rem;
}

.jogador-info-title {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
    color: rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 630px) {
    .navbar-container{
        height: 100%;
        padding: 5%;
    }
}