.webapp-is{
    display: flex;
}

.webapp-is-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 2rem 2rem 2rem;
    gap: 2rem;
}

.webapp-is-header{
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.webapp-is-selected{
    display: flex;
    gap: 0.5rem;
    opacity: 0.4;
    flex-wrap: wrap;
}

.webapp-is-content-layout{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2rem;
}