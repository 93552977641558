.webapp-uc {
    display: flex;
}

.webapp-uc-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
    padding: 4rem 2rem;
    overflow: hidden;
}

.webapp-uc-content-title {
    font-size: 2rem;
    opacity: 0.6;
}

.webapp-uc-content-header {
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-between;
    align-items: center;
}

.webapp-uc-content-header-buttons {
    display: flex;
    gap: 1rem;
}

.webapp-uc-content-header-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    width: max-content;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #000;
    transition: background-color 0.2s ease-in-out;
}

.webapp-uc-content-header-button:hover {
    background-color: #3c50fc33;
}

.webapp-uc-content-header-button-selected {
    background-color: #3c50fc;
    color: #fff;
}

.webapp-uc-content-header-button-selected:hover {
    background-color: #3c50fc;
    color: #fff;
}

.webapp-uc-content-header-filters {
    display: flex;
    gap: 2rem;
}

.webapp-uc-content-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.webapp-uc-content-body-titles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.webapp-uc-total-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    opacity: 0.6;
}

.webapp-uc-content-body-table-name,
.webapp-uc-content-body-table-columns-indentifiers,
.webapp-uc-content-body-titles-content-day {
    background-color: #ffffff;
    border-bottom: 3px solid #9da7fd;
    padding: 1.5rem 0;
}

.webapp-uc-content-body-titles-content-day {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border: none;
    padding: 1.5rem 2rem;
}

.webapp-uc-content-body-titles-content-day:first-child {
    grid-column: 2;
}

.webapp-uc-content-body-arrow {
    border: none;
    background-color: transparent;
    font-size: 2rem;
    line-height: 1rem;
    color: #3c50fc;
    cursor: pointer;
}

.webapp-uc-content-body-arrow-invisible {
    visibility: hidden;
}

.webapp-uc-content-body-titles-content-day-info {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.webapp-uc-content-body-tables {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.webapp-uc-content-body-table-first {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #9da7fd;
}

.webapp-uc-content-body-table-name,
.webapp-uc-content-body-titles-content-day:first-child {
    border-radius: 1rem 0 0 0;
}

.webapp-uc-content-body-titles-content-day:last-child {
    border-radius: 0 1rem 0 0;
}

.webapp-uc-content-body-table-name,
.webapp-uc-content-body-table-first-value {
    padding: 1.5rem 2rem;
}

.webapp-uc-content-body-table-first-value,
.webapp-uc-content-body-table-columns-values {
    display: flex;
    justify-content: space-between;
    box-sizing: content-box;
    height: 1.25rem;
    align-items: center;
}

.webapp-uc-content-body-table-first-value:nth-child(even),
.webapp-uc-content-body-table-columns-values:nth-child(odd) {
    background: #f4f5ff;
}

.webapp-uc-content-body-table-first-value:nth-child(odd),
.webapp-uc-content-body-table-columns-values:nth-child(even) {
    background-color: #ffffff;
}

.webapp-uc-content-body-table-first-value:last-child {
    border-radius: 0 0 0 1rem;
}

.webapp-uc-content-body-table-columns {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.webapp-uc-content-body-table-column {
    grid-column: span 3;
}

.webapp-uc-content-body-table-column
    .webapp-uc-content-body-table-columns-indentifiers {
    padding: 0;
    height: 79.5px;
    align-items: center;
}

.webapp-uc-content-body-table-columns:nth-child(2),
.webapp-uc-content-body-table-columns:nth-child(3),
.webapp-uc-content-body-titles-content-day:first-child,
.webapp-uc-content-body-titles-content-day:nth-child(2) {
    border-right: 1px solid #9da7fd;
}

.webapp-uc-content-body-table-column:nth-child(2) {
    border: none;
}

.webapp-uc-content-body-titles-total {
    background-color: #ffffff;
    padding: 1.5rem 0;
    display: flex;
    justify-content: center;
    grid-column: 2 / span 3;
    border-radius: 1rem 1rem 0 0;
    gap: 1rem;
}

.webapp-uc-content-body-table-columns-indentifiers,
.webapp-uc-content-body-table-columns-values {
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.webapp-uc-content-body-table-columns-name,
.webapp-uc-content-body-table-columns-value {
    width: 25%;
}

.webapp-uc-content-body-table-columns-values-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.webapp-uc-content-body-table-columns-values {
    padding: 1.5rem 0;
    flex-grow: 1;
}

.webapp-uc-content-ucgym-warning {
    color: red;
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    bottom: 0rem;
}

.red {
    color: #ed4863;
}

.green {
    color: #7fc035;
}

.yellow {
    color: #ffd702;
}
