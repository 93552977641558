.footer {
    height: 14vh;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    
}

.footer-container {
    height: 100%;
    display: flex;
    width: 84.7vw;
    margin: 0 auto;
    justify-content: space-between;
}

.image-container {
    display:flex;
    align-items: center;
}
.footer-logo {
    height: 60%;
    display: flex;
    align-items: center;
}

.footer-contacto {
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.text-18px {
    color: var(--azul-azul-escuro-azul-10, #010314);
    text-align: right;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    line-height: 1.375rem; /* 127.778% */
}


@media (max-width: 900px) {
    .footer-container {
        width: 89.75vw;
    }
    .footer-logo {
        width: 80%;
    }
    .image-container {
        width: 50%;
    }
}

@media ( max-width: 400px) {
    .text-18px {
        font-size: 1rem;
    }
}