.academy-page {
    width: 84.7vw;
    min-height: 200px;
    margin: 14.5vh auto;
    position: relative;
    display:flex;
    flex-direction: column;
 
}


.academy-page h2 {
    margin-bottom: 3rem;
}
.academy-page p {
    color: #000;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6rem;
}

.academy-first-logo-background {
    position: absolute;
    bottom: 4vh;
    left: 70vw;
    width: 80%;
    height: auto;
    z-index: 0; 
}

@media (max-width:900px) {
    .academy-page {
        width: 89.75vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
    }
    .academy-first-logo-background {
        bottom: 90vh;
        width:180%;
        left: 50vw;
    }

    .academy-page h2 {
        font-size: 1.375rem !important;
        margin-bottom: 1rem;
    }

    .academy-page p {
        font-size: 1rem;
        line-height: 1.375rem;
    }

}