.webapp-coachprofile{
    display: flex;
    max-width:100%;
    overflow:none;
}

.webapp-coachprofile-content{
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 6rem 2rem;
    gap: 4rem;
    width: 100%;
    overflow: hidden;
}

.webapp-coachprofile-logout{
    position: absolute;
    top: 2rem;
    right: 2rem;
    background-color: #3C50FC;
    color: white;
    border: none;
    border-radius: 0.25rem;
    padding: 0.5rem 5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.webapp-coachprofile-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.webapp-coachprofile-header-img-null{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: #CED3FE;
    cursor: grab;
}

.webapp-coachprofile-img-null{
    display: inline;
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: grab;
}

.webapp-coachprofile-header-img-input{
    position: absolute;
    width: 36px;
    height: 36px;
    opacity: 0;
    cursor: grab;
}


.webapp-coachprofile-header-img{
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 50%;
    cursor: grab;
}

.webapp-coachprofile-header-img img {
    border-radius: 50%;
    width: 100%;
    min-height: 100%;
    height: auto
}

.webapp-coachprofile-header-text{
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
}

.webapp-coachprofile-header-text h1 {
    font-size: 2rem;
    opacity: 0.6;
}

.webapp-coachprofile-header-text p {
    font-size: 1rem;
}

.webapp-coachprofile-body{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 10%;
}

.webapp-coachprofile-body-section{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.webapp-coachprofile-body-sectiontitle{
    font-size: 2rem;
    opacity: 0.6;
}

.webapp-coachprofile-planinfo-footer{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.webapp-coachprofile-planinfo-footer-options {
    display: flex;
    gap: 1rem;
}

.webapp-coachprofile-planinfo-plan-warning{
    color: #2c2829;
    font-size: 0.7rem;
    font-weight: 500;
}

.webapp-coachprofile-data{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
}

.webapp-coachprofile-userinfo{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.webapp-coachprofile-userinfo-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.webapp-coachprofile-userinfo-label{
    color: #020732;
    opacity: 50%;
    font-size: 1.5rem;
}

.webapp-coachprofile-userinfo-input{
    padding: 0.5rem 1rem;
    width: 70%;
    border-radius: 0.5rem;
    border: none;
    background-color: #EBEDFF;
    color: black;
    outline: #3C50FC 2px solid;
}

.webapp-coachprofile-userinfo-input:disabled{
    outline: none;

}

.webapp-coachprofile-userinfo-input::placeholder{
    color: black;
}

.webapp-coachprofile-userinfo-img{
    cursor: pointer;
}

.webapp-coachprofile-password-button{
    align-self: flex-start;
    background-color: #CED3FE;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 2rem;
    color: #3C50FC;
    cursor: pointer;
    border-radius: 4px;
    transition: outline 0.2s;
    outline: 0px solid #3C50FC;
    min-width: 10rem;
    text-align: center;
    justify-content: center;
}

.webapp-coachprofile-password-button:hover{
    outline: 2px solid #3C50FC;
}

.webapp-coachprofile-password-background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.webapp-coachprofile-password{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    outline: 0px solid transparent;
    transition: outline 0.3s;
    padding: 4rem;
    width: 50vw;
}

.webapp-coachprofile-password-body{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
}

.webapp-coachprofile-password-body-input{
    padding: 1rem 2rem;
    width: 100%;

    border-radius: 0.5rem;
    border: none;
    background-color: #EBEDFF;
    color: black;
}

.webapp-coachprofile-password-body-input:focus{
    outline: #3C50FC 2px solid;
}

.webapp-coachprofile-password-error{
    color: #ED4863;
    font-size: 1rem;
    font-weight: 500;
}

.webapp-coachprofile-password-footer{
    display: flex;
    gap: 3rem;
}

.webapp-coachprofile-password-cancel{
    background-color: #ffffff;
    color: #3C50FC;
    cursor: pointer;
}

.webapp-coachprofile-password-accept{
    background-color: #3C50FC;
    color: white;
    cursor: pointer;
}

.webapp-coachprofile-languages-options{
    display: flex;
    gap: 0.5rem;
}

.webapp-coachprofile-userinfo-input-language{
    padding: 0.5rem 1rem;
    width: 100%;
    border-radius: 0.5rem;
    border: none;
    background-color: #EBEDFF;
    outline: none!important;
}

.language-selected{
    outline: #3C50FC 2px solid!important;
}


.webapp-coachprofile-planinfo-savechanges{
    background-color: #3C50FC;
    color: white;
    cursor: pointer;
}

.webapp-coachprofile-planinfo{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    background-color: #EBEDFF;
    border-radius: 0.5rem;
    outline: 0px solid transparent;
    transition: outline 0.3s;
    padding: 1rem;
}

.webapp-coachprofile-planinfo:hover{
    outline: 3px solid #3C50FC;
    transition: outline 0.3s;
}

.webapp-coachprofile-planinfo-header{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.webapp-coachprofile-planinfo-header-text{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.webapp-coachprofile-planinfo-name{
    font-size: 2rem;
    color: #040E64;
}

.webapp-coachprofile-planinfo-description{
    font-size: 1rem;
}

.webapp-coachprofile-planinfo-hr{
    border: 1.5px solid #CED3FE;
    border-radius: 0.5rem;
}

.webapp-coachprofile-planinfo-data{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.webapp-coachprofile-planinfo-data-section{
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: end;
}

.webapp-coachprofile-planinfo-subtitle{
    font-size: 1rem;
}

.webapp-coachprofile-planinfo-data-lastsection{
    margin-top: 1rem;
}

.webapp-coachprofile-planinfo-price{
    display: flex;
    align-items: baseline;
    gap: 0.25rem;

}

.webapp-coachprofile-planinfo-price > *{
    color:#040E64;
    opacity: 0.6;
}

.webapp-coachprofile-planinfo-value{
    font-size: 2rem;
}

.webapp-coachprofile-planinfo-rate{
    font-size: 1rem;
}

.webapp-coachprofile-planinfo-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;
}

.webapp-coachprofile-planinfo-button{
    padding: 0.5rem 2rem;
    width: auto;
    font-weight: 500;
    border-radius: 0.25rem;
    border: none;
}

.webapp-coachprofile-planinfo-acquired{
    background-color: #CED3FE;
    color: #3C50FC;
}

.webapp-coachprofile-planinfo-cancel{
    background-color: #ED4863;
    color: white;
    cursor: pointer;
}

.webapp-coachprofile-body-button{
    font-size: 1rem;
    border: none;
    background: transparent;
    grid-column: 2;
    color: #3C50FC;
    text-decoration: underline 0px transparent;
    text-align: right;
    align-self: end;
    transition: text-decoration 0.3s;
}

.webapp-coachprofile-body-button:hover{
    text-decoration: underline 2px #3C50FC;
    transition: text-decoration 0.3s;
}

.webapp-coachprofile-paymentdata{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: flex-end;
    width: 100%;
}

.webapp-coachprofile-paymentdata-info{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
}

.webapp-coachprofile-payment-input{
    width: 100%;
}

.webapp-coachprofile-paymentdata-button{
    width: max-content;
    text-decoration: none;
    color: #3C50FC;
    background-color: white;
    border: 2px solid #3C50FC;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    text-align: center;
    display: flex;
    gap: 0.5rem;
}
