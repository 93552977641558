.webapp-filter-is{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
    position: relative;
}

.webapp-filter-is-name{
    padding: 0.5rem 2rem;
    background-color: #EBEDFF;
    border: 1px solid #CED3FE;
    color: #040E64;
    border-radius: 4rem;
    width: max-content;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.webapp-filter-is-button{
    height: 0.75rem;
    width: 0.75rem;
}

.webapp-filter-is-button-open{
    transform: rotate(180deg);
}

.webapp-filter-is-filters{
    display: none;
    position: absolute;
    background-color: #EBEDFF;
    border: 1px solid #CED3FE;
    color: #040E64;
    border-radius: 0.5rem;
    padding: 1rem 0;
    box-shadow: 0 0 4px #00000020;
    z-index: 100;
    width: max-content;
}

.webapp-filter-is-filters-open{
    display: flex;
    flex-direction: column;
}

.webapp-filter-is-filter{
    padding: 0.5rem 2rem;
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
}

.webapp-filter-is-filter:hover{
    background-color: #CED3FE;
}