.multiple-choice-dropdown-component {
    position:absolute;
    display: flex;
    flex-direction: column;
    padding: 0.4rem 1rem;
    background-color: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
    top: 100%;
    width: 100%;
    gap: 0.4rem;
    z-index: 20;
}

.multiple-choice-dropdown-component-2 {
    position:absolute;
    display: flex;
    flex-direction: column;
    padding: 0.4rem 1rem;
    background-color: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
    top: 0;
    left: 100%;
    width: fit-content;
    max-height: 15rem;
    overflow-y: auto;
    gap: 0.4rem;
    z-index: 20;
}

.multiple-choice-dropdown-component div{
    display: flex;
    gap: 0.4rem;
}

.multiple-choice-dropdown-component label {
    color: var(--PRETO, #000);

    font-size: 15px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
}