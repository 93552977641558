/* Google Font Import - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}


:root {
    /* ===== Colors ===== */
    --sidebar-background-color: #ffffff;
    --primary-blue: #695CFE;
    --hover-color: #ecedff;

    --text-color: #020314;
    --secondary-text-color: #666666;

    --line-color: #cccdd6;

    /*====== Transition ======*/
    --tran-02: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.4s ease;
    --tran-05: all 0.5s ease;

    /* ====== Selected Tab====== */
    --line-size: 0.5rem;
}


body {
    height: 100%;
    width: 100%;
    background-color: #f4f5ff;
    transition: var(--tran-05);
}

::selection {
    background-color: var(--primary-blue);
    color: #fff;
}

/* ===== Sidebar =====*/

.webapp-sidebar {
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    width: 18rem;
    /* padding: 2rem 1.5rem; */
    background: var(--sidebar-background-color);
    transition: var(--tran-05);
    z-index:10;


    display: flex;
    flex-direction: column;
    justify-content:flex-start;
}

.webapp-sidebar.webapp-sidebar-close {
    width: 6rem;
}

/* ======  Header ========== */

.webapp-sidebar header {
    position: relative;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 3rem;
    border-bottom: 1px solid var(--line-color);
}

.webapp-sidebar header .webapp-sidebar-logo-text {
    display: flex;
    align-items: center;
    transition: var(--tran-05);
}

.webapp-sidebar.webapp-sidebar-close header{
    padding-left: 0;
    justify-content: center;
}


.webapp-sidebar.false header .webapp-sidebar-logo-text #dicon{
    display: none;
}

.webapp-sidebar.webapp-sidebar-close header #dlogo {
    display: none;
}

.webapp-sidebar.webapp-sidebar-close header .webapp-sidebar-logo-text #dicon{
    display: block!important;
    width: auto;
}

header .webapp-sidebar-image-text #dlogo {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 600;
}


/* ======  Chevron ========== */

.webapp-sidebar header .webapp-sidebar-toggle {
    position: absolute;
    top: 50%;
    right: 0;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--tran-03);
    z-index:50;
}

.webapp-sidebar-hide{
    opacity: 0.5;
}

.webapp-sidebar-hide:hover{
    opacity: 1;
}

/* ======  Menu ========== */

.webapp-sidebar .webapp-sidebar-menu {
    margin-top: 2rem;
}


ul.webapp-sidebar-menu-links {
    display: flex;
    flex-direction: column;
}

.webapp-sidebar li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 4.5rem;
    padding: 0 1.5rem;
}

@media screen and (max-height: 768px) {
    .webapp-sidebar .webapp-sidebar-menu {
        margin-top: 1rem;
    }
    
    .webapp-sidebar li {
        height: 3.5rem;
    }
    
}

.webapp-sidebar .webapp-sidebar-icon {
    height: 2rem;
    width: 2rem;
}

.webapp-sidebar .webapp-sidebar-text,
.webapp-sidebar .webapp-sidebar-icon {
    color: var(--text-color);
    transition: var(--tran-03);
}

.webapp-sidebar .webapp-sidebar-text {
    font-size: 1rem;
    font-weight: 500;
}

.webapp-sidebar.webapp-sidebar-close .webapp-sidebar-coach-info {
    display: none;
}

.webapp-sidebar.webapp-sidebar-close .webapp-sidebar-text {
    display: none;
}

.webapp-sidebar li a:hover {
    background-color: var(--hover-color);
}

.webapp-sidebar-nav-link{
    height: 4.5rem;
    border-left: var(--line-size) solid transparent;
    transition: var(--tran-02);
    cursor: pointer;
}

.webapp-sidebar-nav-link:hover{
    border-left: var(--line-size) solid var(--hover-color);
}

.webapp-sidebar-active-link{
    transition: var(--tran-05);
    border-left: var(--line-size) solid var(--primary-blue);
    background-color: var(--hover-color);
}

.webapp-sidebar .webapp-sidebar-menu-bar {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;
    overflow-x: hidden;
}

.webapp-sidebar-menu-bar::-webkit-scrollbar {
    display: none;
}

/* ======  bottom ========== */

.webapp-sidebar-bottom-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: var(--tran-02);
}

.webapp-sidebar-bottom-content > div{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-top: 1px solid var(--line-color);
}

.webapp-sidebar-bottom-excel-content{
    padding: 0.5rem 0;
    cursor: pointer;
}

.webapp-sidebar-bottom-excel-content:hover{
    background-color: var(--hover-color);
}

.webapp-sidebar-excel-extension{
    color: rgba(0, 0, 0, 0.3);
}

.webapp-sidebar-coach-holder{
    height: 6rem;
    border-left: var(--line-size) solid transparent;
}

.webapp-sidebar-bottom-cenas{
    transition: var(--tran-05);
    border-left: var(--line-size) solid var(--primary-blue);
    background-color: var(--hover-color);
}

.webapp-sidebar-coach-holder:hover{
    border-left:var(--line-size) solid var(--hover-color);
}

.webapp-sidebar-coach-photo-holder{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}

.webapp-sidebar-coach-photo{
    width: 100%;
    min-height: 100%;
    height: auto;
    border-radius: 50%;
}

.webapp-sidebar-coach-photo-default{
    width: 3rem;
    height: 100%;
    border-radius: 50%;
}

.webapp-sidebar-coach-info{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: left;
    
    padding: 0.5rem 0 0.5rem 0;
}


.webapp-sidebar-team-photo-holder {
    position: relative;
    width: 40px; 
    height: 40px;  
    border-radius: 50%; /* Make it circular */
    outline: 0.15rem solid rgba(132, 132, 132, 0.3); /* Add border-like effect */
    
}



.webapp-sidebar-team-photo:hover {
    opacity: 0.8; 
    transition: opacity 0.3s ease;
}

.webapp-sidebar-team-photo-overlay {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0; 
    transition: opacity 0.3s ease; 
}

.webapp-sidebar-team-photo-holder:hover .webapp-sidebar-team-photo-overlay {
    opacity: 1; /* Show overlay when hovered over parent container */
}
.webapp-sidebar-team-photo {
    width: 40px;
    height: 40px; 
    cursor: pointer;
}

.webapp-sidebar-team-photo-default {
    width: 40px;
    height: 40px; 
    
    cursor: pointer;
}

    
.webapp-sidebar-team-info{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: left;
    padding: 0.5rem 0 0.5rem 0;
}

  


/* .sidebar .menu-bar .mode {
    border-radius: 6px;
    background-color: var(--hover-color);
    position: relative;
    transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
    height: 50px;
    width: 60px;
}

.mode .sun-moon i {
    position: absolute;
}

.mode .sun-moon i.sun {
    opacity: 0;
}

body.dark .mode .sun-moon i.sun {
    opacity: 1;
}

body.dark .mode .sun-moon i.moon {
    opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
}

.toggle-switch .switch {
    position: relative;
    height: 22px;
    width: 40px;
    border-radius: 25px;
    background-color: var(--toggle-color);
    transition: var(--tran-05);
}

.switch::before {
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    background-color: var(--sidebar-background-color);
    transition: var(--tran-04);
}

body.dark .switch::before {
    left: 20px;
} */
