.webapp-evaluations-page-scroll {
  display: flex;
  max-width:100%;
  overflow:none;
 
}

.webapp-evaluations-page {
  width:100%;
  padding: 4vh 2vw;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top:8vh;
}

.webapp-evaluations-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width:100%;
}
.webapp-evaluations-page-header h1 {
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%; 
}

.webapp-evaluations-page-header button{
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: white;
    padding: 0.75rem 1.365rem;
    font-size: 1.125rem;
    font-family: 'Lato';
    font-weight: 600;
    border:none;
}

.webapp-evaluations-page-subheader{
  position: relative;
  width: fit-content;
}

.webapp-evaluations-page-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  

}
/* Style for the top bar table */
.webapp-evaluations-page-top-bar {
  background-color: #FFFFFF;
  font-weight: bold;
  text-align: center; /* Center-align the text in table headers */
  max-width: 100%;
  border-bottom: solid 3px #CED3FE;
  border-spacing: 0px !important;
  display:flex;
  align-items: center;
 
  
  
}
.webapp-evaluations-page-top-bar-fixed-column {
  height:4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 3px #CED3FE;
  width: 15%;
  padding: 1vh;
  
}



.webapp-evaluations-top-bar-scroll-left, .webapp-evaluations-top-bar-scroll-right {
  height:1.5rem;
  width: 7.5%;
  
}
.webapp-evaluations-top-bar-scroll-left:hover, .webapp-evaluations-top-bar-scroll-right:hover {
  cursor: pointer;

  
}


.webapp-evaluations-page-top-bar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:80%;
}

.webapp-evaluations-page-top-bar-column-name, .webapp-evaluations-page-top-bar-fixed-column  {
  color: rgba(0, 0, 0, 0.60);
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.webapp-evaluations-page-top-bar-column-name{
  width:12%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.webapp-evaluations-page-zvalues-warning {
  color: red;
  font-family: 'Lato';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1rem;
}
