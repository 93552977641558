.webapp-packages-page {
    width:100%;
    overflow: hidden;
    background: #0B24FB;
    min-height:100vh;
    z-index: 0;
}

.white-back-arrow {
    color: #F4F5FF;
}

.webapp-packages-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    min-height:calc(100vh - 8vh - 1rem);
}

.webapp-packages-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
}

.webapp-packages-items h1 {
    font-size: 2rem;
    color: #EBEDFF;
    font-weight: 700;
    line-height: 2.375rem;
}

.webapp-botao-comprar-package {
    height: 2.5rem;
    border-radius: 6px;
    background: #3C50FC;
    border: none;
    display:flex;
    align-items: center;
    color: #FFFFFF ;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0.75rem 1.375rem;
}

.webapp-botao-comprar-package:hover {
    border: 1.5px solid var(--azul-azul-claro-azul-1, #3C50FC);
    background: var(--azul-azul-claro-azul-5, #EBEDFF);

    color: #3C50FC ;
    cursor:pointer;
}

.webapp-package-carousel-swipe {
    display: flex;
    justify-content: flex-end;
}


.webapp-packages-grid {
    display:flex;
    justify-content: space-between;
    width: 100%;
}

.carousel .slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel .slide{
    display: flex;
    align-items: center;
}

.webapp-packages-current-subscription{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    gap: 9.95%;
}

.webapp-packages-current-subscription-title{
    color: #F4F5FF;
    font-family: 'Lato';
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    opacity: 0.8;
}

.month{
    grid-column: 1;
}

.semestre{
    grid-column: 2;
}

.year{
    grid-column: 3;
}

@media (max-width: 1100px) {
    .webapp-custom-package-carousel .carousel .slider {
        width: 30% !important;
    }
}



@media ( max-width: 700px) {
    .webapp-custom-package-carousel .carousel .slider {
        width: 40% !important;
    }
   
    .webapp-packages-items {
        gap: 2rem;
    }
}

@media ( max-width: 500px) {
    .webapp-packages-items {
        gap: 0rem;
    }
    .webapp-packages-items h1 {
        font-size: 1.825rem;
    }
   
    
}

@media ( max-width: 420px) {
    .webapp-register-icons {
        display:flex;
        gap: 0.8rem;
        height: 10vh;
        align-items: center;
        padding-left: 0.5rem;
        padding-top: 0.5rem;
    }
}

@media ( max-width: 380px) {
    .webapp-packages-grid .package-card {
        width: 86vw;
    }
    .webapp-packages-items h1 {
        font-size: 1.5rem;
    }
}