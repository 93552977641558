/* Style for player list in the filter */
.player-list-filtro {
  border: 1px solid #ccc;
  border-radius: 0.38rem;
  padding: 0.6vw;
  background-color: #fff;
  position: absolute;
  margin-left: 10vh;
  max-height: 24vh;
  overflow-y: auto; 
}

/* Style for player list title in the filter */
.player-list-title-filtro {
  margin-bottom: 5px;  
  line-height: normal;
  display: inline-block;
  color: rgba(0, 0, 0, 0.50);
  font-family: Lato;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left; /* Align to the left */
}

/* Style for position title in the filter */
.position-title-filtro {
  margin-bottom: 5px;  
  line-height: normal;
  display: inline-block;
  color: rgba(0, 0, 0, 0.50);
  font-family: Lato;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 51px; 
}

/* Style for individual player in the filter */
.player-filtro {
  margin-left: -4px; 
  padding: 8px;
  border-top: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: background-color 0.3s ease;
}

/* Hover effect for individual player in the filter */
.player-filtro:hover {
  background-color: #EBEDFF;
}

/* Style for player names in the filter */
.nomes-jogadores-filtro {
  color: var(--PRETO, #000); /* Text color */
  font-family: 'Lato', sans-serif; /* Font family */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 10px; /* Adjust the margin as needed */
  text-align: left; /* Align to the left */
}

/* Style for position acronyms in the filter */
.position-acronym-filtro {
  color: var(--PRETO, #000); /* Text color */
  font-family: 'Lato', sans-serif; /* Font family */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: right; /* Align to the right */
}

.filter-by-dashboard{
  align-self: flex-end;
}
