@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.webapp-home-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: relative; /* Add this */
    background-color: #091DC9;
    background-size: cover;
    position: relative;
    overflow:hidden;
    } 
.webapp-home-page::before { /* Add this pseudo-element */
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #091DC9; /* Color with 60% opacity */
    z-index: 2;
}

.webapp-home-background-three {
    position: absolute;
    bottom: 10vw;
    left: 60vw;
    width: 105%;
    height: 120%;
    z-index: 4;
}

.webapp-home-background-three-inverse {
    position: absolute;
    bottom: 10vh;
    right: 60vw;
    width: 105%;
    height: 120%;
    z-index: 4;
    rotate: -90deg;
}

.webapp-home-page-items {
    z-index:10;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6rem;
    width:100%;
}




.webapp-home-logo-container {
    height: 60%;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10;
}


.webapp-logo {
    width: 60%;
    opacity: 1;

}

.webapp-home-page-buttons {
    display:flex;
    flex-direction: column;
    gap: 2rem;
    width: 23%;
}

.webapp-home-page-buttons div {
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}



.webapp-home-page-register {
    color: #FFF;
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    letter-spacing: 0.02em;
}

.webapp-home-page-login-button {
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    width:100%;
    height: 2.6rem;
    color: var(--azul-azul-escuro-azul-7, #071697);
    text-align: center;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-4, #CED3FE);
    border:none;
}

.webapp-home-page-login-button:hover,
.webapp-home-page-register:hover {
    cursor: pointer;
}


@media (max-width: 850px) {
    .webapp-logo {
        width: 80%;
    }
    .webapp-home-page-buttons {
        width: 40%;
    }
}

@media ( max-width:500px) {
    .webapp-logo {
        width: 120%;
    }

    .webapp-home-page-buttons {
        width:60%;
    }
}