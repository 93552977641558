.webapp-folders-breadcrumb-header span{
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;
    padding: 0.5rem 1rem;
}

.webapp-breadcrumb-path-holder{
    display: inline-flex;
    align-items: center;
}

.webapp-breadcrumb-arrow{
    padding: 0rem !important;
}

.webapp-folders-breadcrumb-active-path{
    cursor: pointer !important;
}

.webapp-folders-breadcrumb-active-path:hover{
    background-color: #E3E5FF !important;
    border-radius: 2rem;
}