.webapp-session-players-header{
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
}

.webapp-session-players-exercises{
    height: 100%;
    display: flex;
    align-items: center;
}

.webapp-session-players-exercise{
    height: 100%;
    padding: 1.4rem 1.2rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border-radius: 0.375rem 0.375rem 0rem 0rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    cursor: pointer;
}

.webapp-session-players-exercise:not(.exercise-selected):hover{
    background-color: #EBEDFF;
}

.webapp-session-players-exercise span{
    font-weight: 600;
    font-size: 1rem;
}

.exercise-selected{
    background-color: white;
    border-bottom: none;
}

.webapp-session-players-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    height: 3.525rem;
}

.webapp-session-players-title h2 {
    color: rgba(0, 0, 0, 0.60);
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.webapp-session-players-player-selected-to-edit-popup {
    z-index: 100;
    width: 25.6875rem;
    height: 3.525rem;
    border-radius: 2.0625rem;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

/* Additional styles for better visibility */
.webapp-session-players-player-selected-to-edit-popup p {
    margin: 0;
    display: inline-block;
    color: rgba(0, 0, 0, 0.60);
}

.webapp-session-players-player-selected-to-edit-popup button {
    background-color: transparent;
    border: none;
    color: var(--Outros-Vermelho, #ED4863);
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.webapp-session-players-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    padding: 2rem 1rem;
    background-color: #fff;
}

.webapp-session-players-player{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
}

.webapp-session-players-player-selected-to-edit{
    background: var(--Azul-Azul-Claro-Azul-5, #EBEDFF);
}

.webapp-session-players-player-delete{
    width: 1.25rem;
    height: 1.25rem;
    background-color: transparent;
    border: none;
    align-self: flex-end;
}

.webapp-session-players-player-delete img{
    width: 1.25rem;
    height: 1.25rem;
}

.nondisplay{
    display: none;
}

.webapp-session-players-add{
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 0.5rem;
    background-color: transparent;
    color: #3C50FC;
    text-align: left;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    cursor: pointer;
}

.webapp-session-players-edit {
    padding: 0.62rem 1.38rem;
    align-self: center;
    gap: 0.375rem;
}

.webapp-session-players-edit img {
    width: 0.875rem;
    height: 0.875rem;
}

.webapp-session-players-edit-save {
    width: fit-content;
    flex-wrap: nowrap;
}