.webapp-login-page {
    width:100%;
    position: relative;
    overflow: hidden;
    background: #F4F5FF;
    min-height:100vh;
    z-index: 0;
}

.webapp-login-container {
    width:100%;
    
    display: flex;
    flex-direction: column;
}

.webapp-login-icons {
    display:flex;
    gap: 2rem;
    height: 8vh;
    align-items: center;
    padding-left: 2rem;
    padding-top: 1rem;
    z-index:2;
}

.webapp-login-icons img {
    height: 80%; 
    z-index:2;
}

.webapp-login-icons Link:hover {
    cursor: pointer;
}
.webapp-login-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
    margin: 10vh 0;
}

.webapp-login-form-forgotpass {
    display:flex;
    justify-content: flex-end;
    margin-top: -1rem;
    cursor: pointer;
}

.webapp-register-form-top-header-with-error{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.webapp-login-error-invalid{
    display: none;
    color: red;
    font-size: 0.8rem;
}

.show-error {
    display: block;
}

.webapp-login-form-forgotpass p {
    text-decoration: underline;
}

@media ( max-width:900px) {
    .webapp-register-form {
        width:60% !important;
    }
}

@media ( max-width: 500px) {
    .webapp-register-link-login {
        display: flex;
        justify-content: center;
    }
    .webapp-register-form {
        gap: 1rem;
    }
    .webapp-login-form-forgotpass {
        margin-top: -0.5rem;
    }
}
@media (max-width: 480px) {
    .webapp-login-icons {
        display:flex;
        gap: 0.8rem;
        height: 10vh;
        align-items: center;
        padding-left: 0.5rem;
        padding-top: 0.5rem;
    }
}


