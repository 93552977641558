.webapp-forms-table{
    border-collapse: collapse;
    border-spacing: 0;
}

.webapp-forms-table-header{
    background-color: white;
}

.webapp-forms-table-header th{
    padding: 1rem 0;
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Lato';
    font-size: 1.125rem;
    font-weight: bold;
    border-bottom: solid 3px #CED3FE;
}

.webapp-forms-table-center{
    text-align: center;
}

.webapp-forms-table-left{
    text-align: left;
}

.webapp-forms-table-date{
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--PRETO, #000);
    font-family: 'Lato';
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
}

.webapp-forms-table-body td{
    padding: 1rem 0;
    width: max-content;
}

.webapp-forms-table-body-row{
    border-bottom: solid 1px #CED3FE;
}

.webapp-forms-table-body-row td:first-child, .webapp-forms-table-body-row td:nth-child(2){
    font-weight: bold;
}

.webapp-forms-table-answers{
    display: flex;
    align-items: center;
    gap: 2em;
    text-align: center;
    width: max-content;
}

.webapp-forms-table-button{
    background-color: #FFFFFF;
    color: #000000;
    border: none;
    font-size: 1.125rem;
    font-family: 'Lato';
    font-weight: 600;
    cursor: pointer;
    border-radius: 6px;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2vh;
    padding-right: 2vh;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
}