.webapp-games-page-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Style for the top bar table */
.webapp-games-page-top-bar {
  background-color: #ffffff;
  font-weight: bold;
  text-align: center; /* Center-align the text in table headers */
  max-width: 100%;
  border-bottom: solid 3px #ced3fe;
  border-spacing: 0px !important;
  display: flex;
  align-items: center;
}

.webapp-games-page-top-bar-fixed-column {
  height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 3px #ced3fe;
  width: 15%;
  padding: 1vh;
}

.webapp-evaulations-page-top-bar-scroll-items {
  display: flex;
  justify-content: space-between;
  width: 75%;
  align-items: center;
}

.webapp-games-top-bar-scroll-left,
.webapp-games-top-bar-scroll-right {
  height: 1.5rem;
  padding: 0 0.8rem;
}

.webapp-games-top-bar-scroll-left:hover,
.webapp-games-top-bar-scroll-right:hover {
  cursor: pointer;
}

.webapp-games-page-top-bar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.webapp-games-page-top-bar-column-name,
.webapp-games-page-top-bar-fixed-column {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Lato";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.webapp-games-page-top-bar-column-name {
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-popup:hover{
  position: relative;
}

.image-popup[data]:hover::after {
  content: attr(data);
  font-weight: 400;
  text-transform: none;

  position: absolute;
  top: -100%;
  left: 50%;

  padding: 4px 8px;
  background: #091DC9;
  color: white;
  
  z-index: 2;
  
  white-space: nowrap;
  border-radius: 5px ;
}