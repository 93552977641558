.webapp-datarow-page-player-row {
    display: flex;
    max-width: 100%;
    border-bottom: 1px solid rgba(2, 7, 50, 0.2);
}

.selectable:hover {
    background-color: #ebedff;
    cursor: pointer;
}

.webapp-datarow-page-player-row-fix-column {
    width: 15%;
    border-right: solid 3px #ced3fe;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 5rem;
    padding: 0 1rem;
}

/* .webapp-datarow-page-player-row-fix-column:first-child {
    font-weight: bold;
  } */

.webapp-datarow-page-player-row-items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 5rem;
}

.webapp-datarow-page-player-row-space-filler {
    height: 1.5rem;
    padding: 0 1.3rem;
}

.webapp-datarow-page-player-row-columns {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.webapp-datarow-page-player-row-info {
    width: 12%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
}

.webapp-datarow-player-row-ver-mais {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
}
.webapp-datarow-player-row-ver-mais button {
    background-color: #ffffff;
    color: #071697;
    border: none;
    font-size: 1.125rem;
    font-family: "Lato";
    cursor: pointer;
    width: 84px;
    height: 35px;
    top: 315px;
    left: 1375px;
    border-radius: 6px;
}
