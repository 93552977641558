.db-page {
  display: flex;
}

.db-content{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 4rem 2rem;
}

.db-title{
  color: rgba(0, 0, 0, 0.60);
  font-size: 2rem;
}

.db-subtitle{
  display: flex;
  width: 100%;
  align-items: center;
}

.db-subtitle-line{
  height: 0;
  border: 2px solid #9DA7FD;
  width: 5%;
}

.db-subtitle-content{
  display: flex;
  align-items: center;
  min-width: max-content;
  height: 100%;
}

.db-subtitle-image{
  border: 2px solid #9DA7FD;
  border-radius: 999px 0 0 999px;
  padding: 0.5rem 1.25rem;
  background-color: #CED3FE;
  display: flex;
  align-items: center;
  height: 100%;
}

.db-subtitle-content h5{
  border: 2px solid #9DA7FD;
  border-radius: 0 999px 999px 0;
  border-left: none;
  background-color: #EBEDFF;
  padding: 0.5rem 1.25rem;
  height: 100%;
  color: #6978F9;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}

.db-subtitle-line2{
  width: 100%;
}

.db-section{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}