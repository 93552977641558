.create-session-content-items {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin-top: 2rem;
}

.create-session-content-items h3 {
    color: var(--Azul-Azul-Escuro-Azul-8, #040E64);
    text-align: center;
    font-family: Lato;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.session-general-data-inputs {
    display:flex;
    justify-content: center;
    gap: 2rem;
}

.session-general-data-inputs input, #session-competitions {
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #000000;
    border-radius: 2px;
}

.session-general-data-inputs > div {
    display:flex;
    flex-direction: column;
    gap: 0.75rem;
}


/* This two applies to the dropwdown to selected time */
.webapp-create-session > .dropdown{
  width: auto;
}

.webapp-create-session > div > .dropdown-btn{
  flex-direction: row;
  height: auto;
  width: auto;
  font-size: 0.95rem;
  padding: 0.5rem 1rem;
  border-radius: 2px;
}

#odeio-o-coimbra{
    gap: 0;
}

#session-competitions{
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

#session-data-competitions-dropdown{
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    gap: 0.5rem;
}

.session-data-competitions-dropdown-hidden{
    visibility: hidden;
}

#session-data-competitions-dropdown .selected{
    background-color: #CED3FE;
}

#session-data-competitions-dropdown div{
    cursor: pointer;
    border-radius: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#session-data-competitions-dropdown p{
    grid-column: 1;
    grid-row: 1;
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    width: 100%;
}

#session-data-competitions-dropdown input{
    width: 100%;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    opacity: 0;
    cursor: pointer;

}

#session-data-competitions-dropdown div:hover{
    background-color: #CED3FE;
}

.session-general-data-inputs>div>p{
    padding: 0.5rem 1rem;
    border: 1px solid #00000000;
    text-align: center;
}

.create-session-content-items-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}

.create-session-associate-training {
    margin-top:2rem;
    height: 50vh;
    border-top: 3px solid  #CED3FE;
    width: 100%;
    display: flex;
    flex-direction:  column;
    gap: 4rem;
    overflow-y: scroll;
}

.create-session-training-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

}

.create-session-training-items h5 {
    color: rgba(0, 0, 0, 0.60);
    font-family: Lato;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%; /* 43.9px */
}

.create-session-popup-folders {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    gap: 2rem;
    padding-right:8rem;
}

.create-session-popup-trainings {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
    column-gap: 4%;
    row-gap: 6rem;
}


.create-session-final-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.create-session-final-data h4 {
    color: var(--Azul-Azul-Escuro-Azul-9, #020732);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: uppercase;
}

.create-session-final-data > div {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
}

.create-session-final-data > div > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
}


.gray-16px-700 {
    color: rgba(1, 3, 20, 0.50);

    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
