.webapp-exercise-description {
    display: flex;
    flex-direction: column;
    padding: 4vh 2vw;
    background-color: white;
    border-radius: 0px 6px 6px 6px;
    gap: 2rem;
}

.webapp-exercise-description-title h2 {
    color: var(--azul-azul-claro-azul-2, #6978F9);

    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
}

.webapp-exercise-info-containers {
    display: flex;
    flex-direction: column;
}


.webapp-exercise-description-titles {
    display: flex;
    color: var(--Azul-Azul-Escuro-Azul-9, #020732);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    width: 100%;
}

.webapp-training-exercise-description-container {
    display: flex;

}

.webapp-exercise-left-info-container {
    display: flex;
    flex-direction: column;
    gap: 6vh;
    width: 50%;
}



.webapp-exercise-info {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.webapp-exercise-description-titles span, .exercise-description-info span {
    width: 5%;
}

.webapp-exercise-description-titles h3, .exercise-description-info div {
    width: 95%;
}

.webapp-exercise-info  .create-trainings-exercise-elements-dropdowns{
  width: 80%;
}

.webapp-exercise-info > .create-trainings-exercise-elements-forms-3{
  margin: 0;
}

.exercise-description-info {
    display: flex;
    align-items: center;
}

.exercise-description-info div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.exercise-description-info h4, .webapp-exercise-duration-info h5 {
    color: rgba(1, 3, 20, 0.50);

    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 50%;
}

.webapp-exercise-description-item{
    color: var(--Azul-Azul-Escuro-Azul-10, #010314);

    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.webapp-exercise-right-info-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 50%;
    align-items: flex-end;
}

.webapp-exercise-duration-info {
    width:100%;
    display: flex;
    justify-content: flex-end;
    height: 10%;
    align-items: center;
    gap: 1rem;
}

.webapp-exercise-info-drawing-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 10px;
    height: 65%;
    width: 100%;
    border: 1px solid var(--Azul-Azul-Vibrante, #0B24FB);
    background: var(--Azul-Azul-Escuro-Azul-7, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #071697);
    padding: 2vh 1vw;
}

.webapp-exercise-description-page-drawing{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50%;
}

.webapp-exercise-descriprions-graphs-container{
    display: flex;
    padding-top: 1rem;
    border-top: 4px solid rgba(255, 255, 255, 0.20);
    width: 100%;
}

.webapp-exercise-descriprions-graphs-container div{
    width: 50%;
    padding: 0.5rem;
}

.webapp-exercise-descriprions-graphs-container div:first-child{
    border-right: 1px solid rgba(255, 255, 255, 0.20);
}

.webapp-exercise-info-results-elements-container{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2vh 1vw;
    border-top: solid 4px rgba(255, 255, 255, 0.20);
}

.webapp-exercise-info-results-elements-container h6 {
    color: var(--Azul-Azul-Claro-Azul-5, #EBEDFF);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: uppercase;
}

.webapp-exercise-info-results-elements-container p {
    color: rgba(235, 237, 255, 0.60);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.webapp-exercise-info-ietcr10 {
    color: var(--Azul-Azul-Claro-Azul-5, #EBEDFF);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
    text-transform: uppercase;
}
