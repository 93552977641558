.filter-by{
    display: flex;
    position: relative;
    flex-direction: column;
}

.filter-by label{
  cursor: pointer;
  color: var(--PRETO, #000);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
  
.option-list {
  position: absolute;
  z-index: 10;
  top: 110%;
  width: max-content;
  max-width: 12.5rem;
  height: fit-content;
  max-height: 25vh;
  padding: 0.5rem 0.5rem;
  overflow-y: auto;
  border-radius: 8px;
  background-color: var(--BRANCO, #fff);
  box-shadow: 0px 0px 5px 0px var(--CINZA-CLARO, #e5e5e5);
  transition: display 0.3s;
}

/* This section changes the scrollbar style */
/* Style may vary depending on browser support to the scrollbar-width and scrollbar-color properties */
/* For information check https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width or https://developer.chrome.com/docs/css-ui/scrollbar-styling */

/* For browser that does not support the scrollbar-width and scrollbar-color properties, part of the CSS Scrollbars Styling Module Level 1 Specification. */
@supports (scrollbar-width: auto) {
  .option-list {
    scrollbar-color: gray transparent;
    scrollbar-width: thin;
  }
}

/* For browser that does not support the scrollbar-width and scrollbar-color properties, part of the CSS Scrollbars Styling Module Level 1 Specification. */
/* This includes Safari */
@supports selector(::-webkit-scrollbar) {
  .option-list{
    overflow-x: clip;
  }
  .option-list::-webkit-scrollbar {
    width: 1rem;
    background-color: white;
  }
  .option-list::-webkit-scrollbar-thumb {  
    border: 6px solid transparent;
    border-radius: 50px;
    background-clip: padding-box;
  }
}

.option-list > div{
  white-space: nowrap;
  width: 100%;
  padding: 0.5rem 0.5rem;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option-list > div:not(:first-child){
  border-top: 0.5px solid rgba(2, 7, 50, 0.2);
}

.selected-option-filter{
  background-color: #CED3FE;
}