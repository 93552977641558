.webapp-create-team {
    box-sizing: border-box;
    display: flex;
    gap: 0.5rem;
    justify-items: center;
    height: 100vh;
    padding: 2rem 2rem;
    overflow-x: hidden;
    flex-direction: column;
    position: relative;
}

.webapp-create-team-background-images{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

.webapp-create-team-background-images::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.webapp-create-team-bcklogo{
    position: absolute;
}

#webapp-create-team-bcklogo1{
    rotate: 30deg;
    top: -135vh;
    left: -45vw;
}

#webapp-create-team-bcklogo2{
    rotate: -90deg;
    top: -20vh;
    right: -90vw;
}

#webapp-create-team-bcklogo3{
    rotate: -90deg;
    top: -45vh;
    left: -90vw;
}

#webapp-create-team-bcklogo4{
    rotate: -90deg;
    top: -130vh;
    right: -90vw;
}

#webapp-create-team-bcklogo5{
    rotate: -90deg;
    top: -130vh;
    left: -80vw;
}

#webapp-create-team-bcklogo6{
    transform: scaleY(-1);
    rotate: 180deg;
    top: 10vh;
    right: -60vw;
    
}

.webapp-home-button {
    display: flex;
    align-items: center;
    justify-self: left;
    gap: 1.5rem;
    cursor: pointer;
    width: min-content;
    position: relative;
    z-index: 1;
}

.webapp-blue-arrow{
    width: 1.5rem;
    height: 1.5rem;
}

.webapp-home-text{
    font-size: 1rem;
    font-weight: 500;
    color: #0B24FB;
}

.webapp-create-team-content{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;
}

.webapp-create-team-players{
    gap: 2rem;
}

.webapp-create-team-progress{
    display: flex;
    align-items: center;
}

.webapp-create-team-progress-item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: #6978F9;
    border: 1px solid #6978F9;
}

.webapp-create-team-progress-bar{
    width: 1rem;
    border: 1px solid #6978F9;
}

.webapp-create-team-progress-item-active{
    background: #CED3FE;
}

.webapp-create-team-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    color: #040E64;
}

.webapp-create-team-form{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center; 
    gap: 0.5rem;
    width: 100%;
    min-width: 40rem;
}

.webapp-teamname-input{
    grid-column: 1/5;
    width: auto;
}

.webapp-createteam-inputs{
    height: 2.625rem;
    border-radius: 2px;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732);
    background-color: #F4F5FF;
    padding-left: 2rem;
    font-family: "Lato";
}

.webapp-createteam-inputs-error{
    border: 1px solid var(--outros-vermelho, #ED4863);
    color: var(--outros-vermelho, #ED4863);
}

.webapp-createteam-inputs-error::placeholder{
    color: var(--outros-vermelho, #ED4863);
}

.error-message-createteam{
    grid-column: span 4;
}

.webapp-createteam-button{
    width: 50%;
    justify-self: center;
    border-radius: 6px;
    outline: none;
    border: none;
    height: 3rem;
    font-weight: 400;
    font-family: 'Lato';
    cursor: pointer;
    font-size: 1.125rem;
}

.webapp-createteam-blue-button{
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: #FFF;
}

.webapp-createteam-white-button{
    background: #EBEDFF;
    color: #3C50FC;
    border: 1px solid #3C50FC;
}


.webapp-teamname-h4{
    grid-column: 1;
}

.webapp-createteam-teamname{
    color: #040E64A6;
    ;
}

.webapp-player-info-form{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    gap: 0.5rem 2rem;
    margin: 0;

}

.webapp-playerinfo-biginput{
    width: 45%;
    min-width: 10rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.webapp-playerinfo-smallinputs-holder{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 0.5rem;
    width: 45%;
}

.webapp-playerinfo-smallinput{
    grid-column: span 1;
    width: 50%;
    min-width: 5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.webapp-createteam-buttons-team-name{
    width: 50%;
    display: flex;
    justify-content: center;
    margin: 2rem 0 0 0;
    gap: 1rem;

}

.webapp-createteam-buttons{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 2rem 0 0 0;
    gap: 1rem;
}

.webapp-createteam-adding-players{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 66%;
    align-content: flex-end;
    flex-wrap: wrap;
}

.webapp-create-team-doitlater{
    display: flex;
    justify-content: flex-end;
    width: 33%;
    align-self: flex-start;
}

.webapp-createteam-doitlater-button{
    border-radius: 6px;
    outline: none;
    border: none;
    height: 3rem;
    font-weight: 400;
    font-family: 'Lato';
    text-decoration: solid underline;
    cursor: pointer;
    font-size: 1.125rem;
    background: transparent;
    color: #3C50FC;
}

.webapp-createteam-edit{
    align-self: flex-end;
    width: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.8rem 2rem;
    height: 100%;
}

.webapp-create-team-players-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    width: 80%;
}

.webapp-player-card{
    box-sizing: border-box;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    border-radius: 0.5rem;
    padding: 16px 14px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    position: relative;
    grid-column: span 1;
}

.webapp-player-card-open{
    grid-row: span 2;
    border: 1px solid black;
}

.webapp-player-card-button{
    position: absolute;
    top: 8px;
    right: 14px;
    cursor: pointer;
    color: black;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.webapp-player-card-header{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.webapp-player-card-header-email{
    color: rgba(7, 22, 151, 0.70);
}

.webapp-player-card-hidden{
    display: none;
}

.webapp-player-card-dropdown-text{
    display: flex;
    gap: 0.2rem;
    color:#020732;
}

.webapp-player-card-info-name{
    opacity: 0.6;
}

.webapp-player-card-entrydate{
    opacity: 0.45;
    align-self: flex-end;
}

.webapp-createteam-finish{
    grid-column: 1/4;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    width: 100%;
    margin: 0;
}

.webapp-createteam-finish-button{
    grid-column: 2;
}

.webapp-player-card-remove{
    position: absolute;
    border-radius: 50px;
    height: 2rem;
    width: 2rem;
    font-weight: 400;
    font-family: 'Lato';
    cursor: pointer;
    border: none;
    top: -1rem;
    right: -1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
