.dropdown {
    width: 15rem;
    position: relative;
}

.dropdown .dropdown-btn {
    display: flex;
    width: 13rem;
    height: 2.5rem;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.8rem;
    padding-right: 1rem;
    border: 1px solid black;
    background-color: white;
}

.dropdown .dropdown-content {
    max-height: 11rem;
    overflow-y: scroll;
    position: absolute;
    top: 110%;
    width: 13rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 50, 50, 0);
    border-radius: 8px;
    padding-top: 0.8rem;
    padding-right: 0.4rem;
    padding-bottom: 0.8rem;
    background-color: white;
    border: 1px solid black;
}

.dropdown .dropdown-content .dropdown-item {
    padding-left: 1rem;
    cursor: pointer;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.dropdown .dropdown-content .dropdown-item:hover {
    background-color: #CED3FE;
}

.item-selected{
  background-color: #CED3FE;
}

::-webkit-scrollbar {
    padding-top: 2rem;
    width: 0.5rem;
    background-color: #EEEEEE;
    border-radius: 6px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

::-webkit-scrollbar-thumb {
    height: 4rem;
    background: grey;
    border-radius: 6px;

}
