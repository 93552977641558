@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');



/* dashboard.css */
.chart-card1 {
  border-radius: 1.25rem;
  padding: 2rem;
  box-shadow: 0px 0px 10px 0px #0103140D; /* Add an inset shadow for the border */
  background: #FFF;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-template-rows: 1fr 9fr 9fr;
  gap: 1rem;
}

.doughnut-chart-styles-container, .combined-chart-styles{
  width: 300px;
}

.doughnut-chart-styles {
  width: 100%;
  height: 100%;
  grid-column: span 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
}

#doughnutChart{
  grid-column: 1;
  grid-row: 1;
}

#combinedChart{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
.bar-chart-styles {
  width: 600px; /* Adjust the width as needed */
  grid-column: span 2; /* Make the bar chart span both columns */
  margin-top: 2rem;
}

.title-styles.typography-settings {
  color: #000000;
  font-family: 'Lato';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.title-styles {
  color: #000000;
  font-family: Lato;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  grid-column: span 2;
}

/* Styles for the text inside the doughnut chart */
.doughnutChart-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1;
  grid-row: 1;
  margin-top: -20%;
}

.doughnut-text-styles {
  font-family: 'Lato';
  font-size: 26px;
  color: black;
  
  
}

.doughnut-subtext-styles {
  font-family: 'Lato';
  font-size: 11px;
  color: black;
  grid-column: 1;
    grid-row: 1;
}


.combinedChart-text{
  display: flex;
  flex-direction: column;
  grid-column: 1;
    grid-row: 1;
  margin-top: 20%;
  margin-right:67%;
  

}
.combinedchart-text-styles{
  font-family: 'Lato';
  font-size: 10px;
  font-weight: 1000;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 125%;
  color: rgba(0, 0, 0, 0.5);
  
  
}

.barChart-text {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: -1355%;
}

.barchart-text-styles{
  color: #000000;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 700;
  
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.barchart-text-styles2{
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 125%;
  color: rgba(0, 0, 0, 0.5);



  
}

