.packages-page {
    width: 84.7vw;
    margin: 0 auto;
    padding: 14.5vh 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
}
.package-name h2 {
    text-align: left;
}
.packages-background {
    z-index: -1;
    background: var(--azul-azul-escuro-azul-6, #091DC9);
}

.packages-background-three{
    position: absolute;
    top: 60%;
    right: 60%;
    width: 100%;
    height: auto;
    z-index: 0; /* Place the image behind the content */
    opacity: 40%;
    
}
.packages-grid {
    display:flex;
    justify-content: space-between;
    width: 100%;
    z-index: 10;
}

.package-card  {
    width: 26.7%; 
    border-radius: 10px;
    background: #EBEDFF;
    /* shadow2 */
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    padding: 1.563rem 0;
}

.packages-page h1 {
    color: var(--azul-azul-claro-azul-5, #EBEDFF);
    /* Títulos */
    font-family: 'Lato';
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.package-name {
    display:flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #EBEDFF;
    
}

.package-name h2 {
    padding-left: 1.875rem;
}
.package-description {
    padding-left:1.875rem;
    padding-right: 1.875rem;
    height: 6rem;
}

.package-info {
    padding-left: 1.875rem;
    padding-right: 1.875rem;

}


.package-info {
    padding-top: 1.5rem;
    display:flex;
    flex-direction: column;
}

.package-info-periodo {
    display:flex;
    row-gap: 3rem;
    column-gap: 0.5rem;
    align-items: flex-end;
    margin-bottom: 1rem;
}

.package-info-valor {
    display:flex;
    row-gap:3rem;
    column-gap: 0.5rem;
    margin-bottom: 3rem;
    align-items: center;
}
.package-info-compra {
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
}

.preco-final {
    width: 50%;
    color: rgba(4, 14, 100, 0.60);
    /* Títulos */
    font-family: 'Lato';
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 60%;
    text-align: left;
}

.package-info-key {
    width: 50%;
    color: rgba(0, 0, 0, 0.90);
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;

}

.free-trial-price {
    width: 50%;
}

.botao-comprar {
    height: 2.5rem;
    border-radius: 6px;
    background: #3C50FC;
    border: none;
    display:flex;
    align-items: center;
    color: #EBEDFF;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0.75rem 1.375rem;
    
}

.recommended {
    border: 10px solid var(--azul-azul-claro-azul-5, #CED3FE);
    background: #FFF;
    border-radius: 10px;
}
.recommended-package-name {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.recommended-text {
    background: rgba(4, 14, 100, 0.60);
    border-radius: 5px 0 0 5px;
    padding: 0rem 0.8rem;
    color: var(--azul-azul-claro-azul-5, #EBEDFF);
    text-overflow: ellipsis;
    font-family: 'Lato';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: 0.1rem;
}



@media (max-width:1515px) {
    .package-name p {
        font-size: 1rem;
        text-align: left;
    }
    .preco-final {
        font-size: 1.5rem;
        line-height: normal;
    }
    .package-card {
        width: 30%;
    }
}



@media (max-width: 1100px) {

   
    .control-dots {
        display: none;
    }
    .package-carousel-swipe {
        display: flex;
        justify-content: flex-end;
    }
    .package-name h2 {
        padding-left: 1.2rem;
    }
    .package-description {
        padding-left:1.2rem;
        padding-right: 1.2rem;
       
    }
    
    .package-info {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    
    }
    
    
    .package-info-key {
        font-size: 1rem;
        line-height: 1.25rem;
        text-align: left;
    }
    .free-trial-price {
        font-size: 1rem;
        line-height: normal;
    }
    .preco-final {
        font-size: 1.5rem;
        line-height: normal;
    }

    .botao-comprar {
        font-size: 1rem;
        line-height: normal;
        padding: 0.2rem 1.2rem;
    }
    .package-card {
        width: 40vw;
    }
    .preco-final p {
        font-size: 1rem;
    }

    .custom-package-carousel .carousel .slider {
        width: 25% !important;
    }
    .package-carousel-swipe {
        padding-bottom: 3rem;

    }
    .package-carousel-swipe span{
        color: rgba(255, 255, 255, 0.70);
    }
    .swipe-arrow-right {
        font-size: 0.875rem;
        line-height: normal;
        height:17px;
    }
}
@media (max-width: 900px) {
   
    .packages-background-three {
        top:80%;   
    }
    .packages-page {
        width: 89.75vw;
        gap: 1.5rem;
        padding: 5vh 0;
    }
    .packages-page h1 {
        font-size: 1.5rem;
    }
    .package-name h2 {
        font-size: 1.5rem;
    }
}

@media ( max-width: 600px ) {
    .package-card {
        width: 60vw;
    }
}
@media (max-width: 500px) {
    .package-card {
        width: 70vw;
    }
}

