.webapp-move-to-folder-popup-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    overflow: hidden;
}

.webapp-move-to-folder-popup{
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 4rem;
    gap: 2rem;
    min-width: 40vw;
}

.webapp-move-to-folder-popup-content{
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    padding: 0;
}

.webapp-move-to-folder-invisible{
    visibility: hidden;
}