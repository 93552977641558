.create-session-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(1, 3, 20, 0.50);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 10%;
    overflow: hidden;
}

.webapp-create-session-popup {
    width: 100%;
    min-height: 40%;
    background-color: #F4F5FF;
    z-index: 101;
    padding: 1.5rem 2rem;
    border-radius: 8px;
}

.webapp-create-session-popup-header {
    display: flex;
}

.webapp-create-session-popup-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -1.5rem;
}
.webapp-paint-return {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.webapp-create-session-popup-steps div {
    border-radius: 50%;
    border: 1.5px solid #6978F9;
    background-color: white;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6978F9;
    font-size: 1rem;
    font-weight: 400;
}

.webapp-create-session-popup-steps span {
    width: 1rem;
    height: 1.5px;
    background-color: #6978F9;
}

.webapp-paint-return  button{
    border-radius: 50%;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    border:none;
    width: 2.75rem;
    height: 2.75rem;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.create-session-cancel-button, .create-session-previous-button {
    border:none;
    background: #F4F5FF;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: 'Lato';
    color: var(--azul-azul-claro-azul-1, #3C50FC);
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.create-session-next-button {
    border-radius: 6px;
    background-color: var(--azul-azul-claro-azul-5, #EBEDFF);
    border: 1.5px solid var(--azul-azul-claro-azul-1, #3C50FC);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: 'Lato';
    color: var(--azul-azul-claro-azul-1, #3C50FC);
    padding: 0.5rem 0.75rem;
    cursor:pointer;
}

.create-session-save-button {
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: white;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0.75rem 1rem;
    border: none;
    cursor:pointer;
}

.create-session-popup-buttons {
    margin-top: 4rem;
    display:flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}