.webapp-session-training-info-page {
    display: flex;
    width: 100%;
    min-height: 100vh;
}

.webapp-session-trainings-header  {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.webapp-session-trainings-header > div:first-child{
    padding-bottom: 4vh;
    border-bottom: solid 3px #CED3FE;
}

.webapp-session-trainings-header-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


.session-trainings-header-titles {
    color: rgba(0, 0, 0, 0.60);


    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}


.session-training-name {
    color: rgba(0, 0, 0, 0.60);

    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%; /* 43.9px */
}

.session-trainings-header-titles-container, .session-trainings-header-info-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.session-trainings-header-titles-container h2:first-child,
.session-trainings-header-info-container p:first-child,
.session-trainings-header-titles-container h2:last-child,
.session-trainings-header-info-container p:last-child {
    width : 25% !important;
}

.session-trainings-header-titles-container >h2,
.session-trainings-header-info-container > p,
.session-training-total-duration {
    width: 20%;
}

.session-training-total-duration {
    display: flex;
    align-items: center;
}

.webapp-session-training-exercise-content{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.session-training-total-duration span {
    border-radius: 3px;
    background: var(--Azul-Azul-Claro-Azul-5, #EBEDFF);
    padding: 0.75rem 0.5rem;
    display: flex;
    align-items: center;
    gap:0.5rem;
}

.webapp-session-training-exercises-container h1 {
    color: rgba(0, 0, 0, 0.60);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    opacity: 60%;
    margin-bottom: 1rem;
}

.session-training-edit-holder{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.session-training-edit-holder > button{
  width: fit-content;
}
