.webapp-injurieschart{
    width: calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 1.5rem;
    gap: 1rem;
    background-color: white;
}

.webapp-injurieschart-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.webapp-injurieschart-header-title{
    color: #091DC9;
}

.webapp-injurieschart-table{
    border-collapse: collapse;
    border-spacing: 0;
}

.webapp-injurieschart-table-header-element{
    padding: 0.5rem;
    text-align: left;
    color: #040E64;
    text-align: left;
    width: max-content;
    border-bottom: 1px solid #02073222;
}

.webapp-injurieschart-table-body-row{
    border-bottom: 1px solid #02073222;
}

.webapp-injurieschart-table-body-row:last-child{
    border: none;
}

.webapp-injurieschart-table-body-row-header{
    color: #040E64;
    text-align: left;
}

.webapp-injurieschart-table-body-row:first-child .webapp-injurieschart-table-body-row-header{
    padding: 2rem 0 1rem 0;
    width: 25%;
}

.webapp-injurieschart-table-body-row:first-child .webapp-injurieschart-table-body-row-item{
    padding: 2rem 0.5rem 1rem 0.5rem;
}

.webapp-injurieschart-table-body-row-item{
    padding: 1rem 0.5rem;
    text-align: left;
    color: #040E64;
    text-align: left;
    position: relative;
}

.webapp-injurieschart-table-body-row-item:last-child, .webapp-injurieschart-table-header-element:last-child{
    padding: 0;
}

.webapp-injurieschart-table-body-row:first-child .webapp-injurieschart-table-body-row-item:last-child{
    padding: 2rem 0 0.5rem 0;
}

.webapp-injurieschart-scale{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    padding-top: 4px;
    background: white;
    z-index: 1;
}

.webapp-injurieschart-scale p{
    color: #040E64;
    font-size: 0.75rem;
    text-align: center;
    width: 1px;
    display: flex;
    justify-content: center;
}

.webapp-injurieschart-bar{
    padding: 0;
    background-color: #6978F9;
    color: #6978F9;
    border-radius: 0 0.25rem 0.25rem 0;
    display: flex;
    align-items: center;
    position: relative;
    cursor: default;
}

.webapp-injurieschart-bar-lines{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.webapp-injurieschart-bar-lines div{
    width: 1px;
    height: 100%;
    background-color: #02073222;
}

.webapp-injurieschart-bar-tooltip{
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.49);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.25rem 0.5rem;

    /* Position the tooltip */
    position: absolute;
    left: calc(100% + 0.5rem);

    z-index: 1;
}

.webapp-injurieschart-bar-tooltip::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.49) transparent transparent;
  }

.webapp-injurieschart-bar:hover .webapp-injurieschart-bar-tooltip {
    visibility: visible;
}


