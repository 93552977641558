.webapp-injuries-page{
    display: flex;
    font-family: Lato;
}

.webapp-injuries-page-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 2rem 2rem 2rem;
    gap: 2rem;
}

.webapp-injuries-page-content-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.webapp-add-injury-button{
    font-size: 1.125rem;
    font-family: 'Lato';
    font-weight: 600;
    height: fit-content;
    padding: 0.75rem 1.365rem;
    outline: none;
    border: none;
    border-radius: 6px;
    color: white;
    background-color: #3C50FC;
}

.webapp-injuries-filterby{
    width: fit-content;
}

.webapp-injuries-table{
    padding-top: 1rem;
}