@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
.chart-card-combined01 {
  border-radius: 1.25rem;
  padding: 2rem;
  box-shadow: 0px 0px 10px 0px #0103140D;
  background: #FFF;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.chart-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-column: span 2;
}

.charts{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.icon-person-text{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.chart-title {
  color: var(--PRETO, #000);
  font-family: Lato;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.chart-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 20vw;
}

.chart-column01-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.chart-column01-title{
  font-weight: 700;
}

.chart-column01-text-styles{
  font-family: 'Lato';
  font-size: 9px;
  font-weight: 1000;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 125%;
  color: rgba(0, 0, 0, 0.5);
}

.icon-person-text{
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
}