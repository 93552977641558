.webapp-ucstats-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1rem;
}

.webapp-ucstats-section {
    display: grid;
    grid-template-columns: 2fr repeat(7, 1fr);
    justify-content: center;
    width: 100%;
}

.webapp-ucstats-section-header {
    grid-column: span 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
    background-color: #ffffff;
    padding: 4rem 2rem;
}

.webapp-ucstats-section-header:first-child {
    grid-column: 2 / span 4;
    border-radius: 2rem 0 0 0;
    border-right: 1px solid #9da7fd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.webapp-ucstats-section-header h1 {
    text-align: center;
}

.webapp-ucstats-section-header-details {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.webapp-ucstats-section-header-details-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.webapp-ucstats-section-header-details-item-input {
    display: flex;
    justify-content: space-between;
    box-sizing: content-box;
    align-items: center;
    border-radius: 0.125rem;
    opacity: 0.5;
    background: var(--Azul-Azul-Claro-Azul-4, #ced3fe);
}

.webapp-ucstats-section-header-details-item-input input {
    border: none;
    background-color: transparent;
    text-align: center;
}

.webapp-ucstats-section-header-details-item-input input:focus {
    border: none;
    outline: none;
    background-color: transparent;
    text-align: center;
}

.webapp-ucstats-section-header h1 {
    opacity: 0.6;
}

.webapp-ucstats-section-header-details-item p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.webapp-ucstats-bottom-first {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #9da7fd;
}

.webapp-uc-stats-first-name {
    border-radius: 1rem 0 0 0;
}

.webapp-uc-stats-first-name,
.webapp-ucstats-bottom-columns-indentifiers {
    background-color: #ffffff;
    border-bottom: 3px solid #9da7fd;
    padding: 1.5rem 0;
}

.webapp-uc-stats-first-name,
.webapp-uc-stats-first-value {
    padding: 1.5rem 2rem;
}

.webapp-uc-stats-first-value,
.webapp-ucstats-bottom-columns-values {
    display: flex;
    justify-content: space-between;
    box-sizing: content-box;
    height: 1.25rem;
    align-items: center;
}

/* .webapp-uc-stats-first-value:nth-child(even) ,.webapp-ucstats-bottom-columns-values:nth-child(odd){
    background-color: #f4f5ff;
} */

.webapp-uc-stats-first-value:nth-child(odd),
.webapp-ucstats-bottom-columns-values:nth-child(even) {
    background-color: #ffffff;
}

.webapp-uc-stats-first-value:last-child {
    border-radius: 0 0 0 1rem;
}

.webapp-ucstats-bottom-columns {
    grid-column: span 3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uctotal-columns {
    grid-column: 2 / span 4;
}

.webapp-ucstats-bottom-columns:nth-child(2) {
    border-right: 1px solid #9da7fd;
}

.webapp-ucstats-bottom-columns-indentifiers,
.webapp-ucstats-bottom-columns-values {
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.webapp-uc-stats-bottom-columns-name,
.webapp-uc-stats-bottom-columns-value {
    width: 25%;
}

.webapp-ucstats-bottom-columns-values-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.webapp-ucstats-bottom-columns-values {
    padding: 1.5rem 0;
    flex-grow: 1;
}

.webapp-uc-stats-bottom-columns-value-input {
    width: 25%;
    text-align: center;
    border: none;
    border-radius: 0.125rem;
    opacity: 0.5;
    background: var(--Azul-Azul-Claro-Azul-4, #ced3fe);
    font-family: "Lato";
    font-size: 1rem;
}

.webapp-uc-stats-bottom-columns-value-input-time {
    padding: 0;
}

.webapp-uc-stats-bottom-columns-value-input::-webkit-outer-spin-button,
.webapp-uc-stats-bottom-columns-value-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
