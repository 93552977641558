.webapp-addplayer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 2rem 2rem 0 2rem;
    min-height: 100vh;
    gap: 4rem;
}

.webapp-addplayer-header {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
}

.webapp-addplayer-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-width: 80vw;
    flex-grow: 1;
}

.webapp-addplayer-content-title{
    font-size: 1.5rem;
    padding: 1rem 2rem;
    background-color: white;
    color: #3C50FC;
    align-self: start;
    border-radius: 1rem 1rem 0 0;
}

.webapp-addplayer-content-main{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr  2fr;
    gap: 2rem;
    padding: 4rem 3rem;
    background-color: white;
    height: 80vh;
    width: 100%;
}

.webapp-addplayer-content-main-inputs{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.webapp-addplayer-content-main-inputs label{
    font-weight: 500;
}

.webapp-addplayer-content-main-inputs input, .webapp-addplayer-content-main-inputs select{
    padding: 0.5rem 1rem;
    border: 1px solid black;
    border-radius: 0.25rem;
}

.webapp-addplayer-content-main-left{
    grid-column: 1 ;
}

.webapp-addplayer-content-main-right{
    position: relative;
    grid-column: 2 / 4;
}

.webapp-addplayer-error-field{
    border-color: red !important;
}

.webapp-addplayer-email-already-used{
    position: absolute;
    font-size: 0.75rem;
    top: 4rem;
    left: 0;
    color: red;
    border: none;
}

.webapp-addplayer-dates-error{
    position: relative;
    font-size: 0.75rem;
    left: 0;
    color: red;
    border: none;
}

.display-none{
    display: none;
}

.webapp-addplayer-content-main-small{
    grid-column: span 1;
}

.webapp-addplayer-content-button{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row: 4;
    grid-column: 4;
    align-self: center;
    justify-self: end;
    background-color: #3C50FC;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
}