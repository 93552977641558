@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
.chart-card3 {
  position: relative;
  border-radius: 1.25rem;
  padding: 2rem;
  box-shadow: 0px 0px 10px 0px #0103140D;
  background: #FFFFFF;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 9fr; /* Adjust the row sizes */
  gap: 1rem;
}

.chart-title {
  grid-column: 1 / 4;
  text-align: left; 
  color: #000000;
  font-family: 'Lato';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.chart-column11, .chart-column22, .chart-column33 {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}