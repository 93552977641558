.webapp-create-evaluation-page {
    width:100%;
    overflow-x: hidden;
    padding: 4vh 2vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
}

.webapp-create-evaluation-page .webapp-create-training-header-left-inputs>div:nth-child(1) {
    flex: 2 1;
}

.webapp-create-evaluation-page .webapp-create-training-header-left-inputs>div:nth-child(2) {
    flex: 1 1;
}