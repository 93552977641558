.webapp-sessioninfo{
    display: flex;
    max-width:100%;
    overflow:none;
}

.webapp-sessioninfo-content{
    display: flex;
    flex-direction: column;
    width: 100%;
}