.landing-page {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F4F5FF;
    overflow: hidden;
    overflow-x: hidden; 
}


/* Define the keyframes animation */
@keyframes slideFromTop {
    from {
      transform: translateY(-100%); /* Start off the screen (above) */
      opacity: 0; /* Optionally start with opacity 0 */
    }
    to {
      transform: translateY(0); /* Slide in to the normal position */
      opacity: 1; /* Optionally fade in as it slides */
    }
  }
  
  /* Apply the animation to your element */
  .slide-in-from-top {
    animation: slideFromTop 1s ease forwards; /* Adjust duration and timing function as needed */
  }
  
  /* Optionally set initial styles for the element */
  .slide-in-from-top {
    position: relative; /* Ensure proper positioning */
    opacity: 0; /* Optionally set initial opacity */
}

@keyframes fadeOut {
    from {
      opacity: 1; /* Start with full opacity */
    }
    to {
      opacity: 0; /* Fade out to completely transparent */
    }
  }
  
  /* Apply the fade-out animation to your element */
  .fade-out {
    animation: fadeOut 1s ease forwards; /* Adjust duration and timing function as needed */
  }
  
  /* Optionally set initial styles for the element */
  .fade-out {
    display: block;
    opacity: 1; /* Optionally set initial opacity */
  }
  

.text-36px {
    font-family: 'Lato';
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text-20px {
    font-family: 'Lato';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem
    }

.azul-8 {
    color: var(--azul-azul-escuro-azul-8, #040E64);

}

.cor-preta {
    color: #000
}

.text-22px {
    color: #000;
    font-family: 'Lato';
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.85rem;
}

.text-26px {
    color: #000;
    font-family: 'Lato';
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


html.menu-open .home-section,
html.menu-open .aboutus-section,
html.menu-open .questions-section,
html.menu-open .academy-section,
html.menu-open .packages-section,
html.menu-open .contactos-section,
html.menu-open .landing-footer
 {
    display: none;
}


.home-section{
    margin-bottom: 11.5vh;
}

.navbar-section {
    margin-top: -11.5vh;
}

.fixed-nav {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    margin-top: 0;
    width: 100%;
    background-color: #fff; /* You can set the background color you prefer */
    z-index: 1000; /* Adjust the z-index as needed */
    /* Add other styling as needed */
    
}

.visible {
    opacity: 1 !important;
    transform: translateX(0) !important;
    transition: 1.5s ease-in-out !important;
  }
  
  .aboutus-section,
  .questions-section,
  .academy-section,
  
  .contactos-section,
  .aboutus-about-section, 
    .section-missao,
    .packages-section-items,
    .questions-section-items {
    opacity: 0;
    transform: translateX(-100px);
    transition: .5 ease-in-out;
  }

.carousel1 .slider-wrapper.axis-horizontal .slider { 
  align-items: center;
  margin-left: -80.5%
}
.carousel2 .slider-wrapper.axis-horizontal .slider { 
  align-items: center;
}
.carousel3 .slider-wrapper.axis-horizontal .slider { 
  align-items: center;
  margin-left: -25.5%;
}