.webapp-maptable-page-table {
    width: 100%;
    display: flex;
    flex-direction: column;


}

/* Style for the top bar table */
.webapp-maptable-page-top-bar {
    background-color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    /* Center-align the text in table headers */
    max-width: 100%;
    border-bottom: solid 3px #CED3FE;
    border-spacing: 0px !important;
    display: flex;
    align-items: center;



}

.webapp-maptable-page-top-bar-fixed-column {
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: solid 3px #CED3FE;
    width: 15%;
    padding: 1rem;

}

.webapp-evaulations-page-top-bar-scroll-items {
    display: flex;
    justify-content: flex-start;
    width:100%;
    align-items: center;
    padding: 0 0.5rem;
}


.webapp-maptable-top-bar-scroll-left,
.webapp-maptable-top-bar-scroll-right {
    height: 2rem;
    width: 2.2rem;
    padding: 0 0.8rem;

}

.webapp-maptable-top-bar-scroll-left:hover,
.webapp-maptable-top-bar-scroll-right:hover {
    border-radius: 100%;
    background-color: #9DA7FD;
    cursor: pointer;
}


.webapp-maptable-page-top-bar-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4rem;
}

.webapp-maptable-page-top-bar-column-name,
.webapp-maptable-page-top-bar-fixed-column {
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Lato';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.webapp-maptable-page-top-bar-column-name {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hidden-arrow {
    visibility: hidden;
}