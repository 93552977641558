.webapp-evaluations-page-player-row {
  display: flex;
  max-width:100%;
  border-bottom: 1px solid rgba(2, 7, 50, 0.20); 
 
}

.webapp-evaluations-page-player-row-fix-column {
  width:15%;
  border-right: solid 3px #CED3FE; 
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 6vh;
  padding: 0 1vh;
}

  /* .webapp-evaluations-page-player-row-fix-column:first-child {
    font-weight: bold;
  } */

.webapp-evaluations-page-player-row-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:75%;
  height:6vh;
}

.webapp-evaluations-page-player-row-space-filler {
  height:1.5rem;
  padding: 0 1.3rem;
}

.webapp-evaluations-page-player-row-columns {
  display:flex;
  justify-content: space-between;
  width:80%;
  
}

.webapp-evaluations-page-player-row-info {
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000000;
}

.webapp-evaluations-player-row-ver-mais {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
}
.webapp-evaluations-player-row-ver-mais button{
  background-color: #FFFFFF;
  color: #071697;
  border: none;
  font-size: 1.125rem;
  font-family: 'Lato';
  cursor: pointer;
  width: 84px;
  height: 35px;
  top: 315px;
  left: 1375px;
  border-radius: 6px;
}



