.create-trainings-exercise-elements-forms-1, .create-trainings-exercise-elements-forms-2, .create-trainings-exercise-elements-forms-3 {
    display: flex;
    width: 100%;
    min-height: 10rem;
    align-items: center;
    gap: 2rem;
    margin: 4rem 0;

}

.create-trainings-exercise-elements-forms-2.vertical{
  flex-direction: column;
  align-items: flex-start;
}

.create-trainings-exercise-elements-forms-2.vertical > div{
  width: 80%;
}

.create-trainings-exercise-elements-forms label {
    white-space: nowrap;
}

.create-trainings-exercise-elements-dropdowns {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 30%;
    position: relative;
}

.create-trainings-exercise-elements-dropdowns label {
    color: var(--PRETO, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
}

.create-trainings-exercise-elements-custom-dropdown {
    display: flex;
    position: relative;
    width:100%;
    height: 2.5rem;
    border-radius: 2px;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732);
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    cursor: pointer;
}

.create-trainings-exercise-elements-custom-dropdown p{
    max-height: 100%;
    overflow: hidden;
    opacity: 50%;
}

.create-trainings-exercise-elements-custom-dropdown .selected{
    opacity: 100% !important;
}

.create-trainings-exercise-elements-custom-dropdown svg{
    min-width: 1rem

}


.create-trainings-exercise-density-elements-dropdowns {
    display: flex;
    width: 60%;
    justify-content: space-between;
}

.create-trainings-exercise-density-elements-dropdowns div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

.create-trainings-exercise-density-elements-time-input {
    display: flex;
    gap: 0.1rem;
    flex-direction: column;

}

.create-trainings-exercise-density-elements-time-input span {
    display: flex;
    gap: 0.2rem;
    align-items: center;

}

.create-trainings-exercise-density-elements-dropdowns label {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.create-trainings-exercise-density-elements-dropdowns input {
    padding: 0.2rem 0.5rem;
    width: 50%;
}

.create-trainings-exercise-density-elements-results {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 40%;
    height:100%;

}

.create-trainings-exercise-density-elements-results h3 {
    opacity: 60%;
}

.create-trainings-exercise-density-elements-results-container {
    display: flex;
    height: 9rem;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    border-radius: 3px;
    background: var(--Fundo-Website, #F4F5FF);
    /* shadow2 */
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);

}

.create-trainings-exercise-density-elements-results-container div {

    padding: 0.5rem 0;
}

.create-trainings-exercise-density-elements-results-container  p {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.create-trainings-exercise-density-elements-results-container  p span {
    display:flex;
    justify-content: center;
    align-items: center;
}
.create-trainings-exercise-density-elements-results-container div:nth-child(1) {
    border-right: 2px solid #9DA7FD;
    width:45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.create-trainings-exercise-density-elements-results-container div:nth-child(2) {
    width: 55%;
}




.internal-logic-elements-dropdown-columns {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.internal-logic-elements-dropdown-columns:first-child {
    width: 20%;
}

.internal-logic-elements-dropdown-columns:not(:first-child) {
    width: 40%;
}

.create-trainings-exercise-elements-custom-dropdown-groups{
    position:absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
    top: 100%;
    width: 100%;
    gap: 0.4rem;
    z-index: 20;
}

.create-trainings-exercise-elements-custom-dropdown-group{
    position: relative;
}

.create-trainings-exercise-elements-custom-dropdown-group>p{
    padding: 0.5rem 1rem;

}

.create-trainings-exercise-elements-custom-dropdown-group:hover{
    background-color: #0103140F;
}

.create-trainings-exercise-elements-custom-dropdown-group:first-child{
    border-left: 8px solid #6978F9;
}

.create-trainings-exercise-elements-custom-dropdown-group:nth-child(2){
    border-left: 8px solid #9DA7FD;
}

.create-trainings-exercise-elements-custom-dropdown-group:nth-child(3){
    border-left: 8px solid #CED3FE;
}

.create-trainings-exercise-elements-custom-dropdown-group:nth-child(4){
    border-left: 8px solid #EBEDFF;
}

.create-trainings-exercise-elements-forms-1 {
    display: flex;
    flex-direction: column ;
    width: 100%;
    min-height: 10rem;
    align-items: flex-start;
    gap: 2rem;
    margin: 4rem 0;

}

@media (max-width: 1200px) {
    .create-trainings-exercise-elements-forms-1, .create-trainings-exercise-elements-forms-2 {
        display: flex;
        flex-direction: column ;
        width: 100%;
        min-height: 10rem;
        align-items: flex-start;
        gap: 2rem;
        margin: 4rem 0;

    }

    .create-trainings-exercise-elements-dropdowns {
        width: 60%;
    }

    .create-trainings-exercise-density-elements-dropdowns {
        width: 80%;
    }

    .create-trainings-exercise-density-elements-results {
        width: 70%;
    }
    .internal-logic-elements-dropdown-columns:not(:first-child) {
        width: 35%;
    }
    .internal-logic-elements-dropdown-columns:first-child {
        width: 30%;
    }
    .webapp-create-training-exercise-info-form-drawing  {
        flex-direction: column;
    }

}


@media (max-width: 1000px) {
    .create-trainings-exercise-density-elements-dropdowns {
        width: 100%;
    }
}
