.webapp-trainings-page-changer {
    display:flex;
    gap: 2rem;
    align-items: center;
}


.webapp-trainings-page-changer a:hover{
    cursor: pointer;
    background-color: #3c50fc33;
}

.webapp-trainings-page-changer a{
    text-decoration: none;
    padding: 0.4rem 0.8rem;
    border-radius: 6px;
    border: none;
    transition: background-color 0.2s ease-in-out;
}

.trainings-changer-selected {
    background: var(--azul-azul-claro-azul-1, #3C50FC) !important;
    color: white !important;
    align-items: center;
    display: flex;
}