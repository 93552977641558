.webapp-coach-paint-menu {
    display:flex;
    justify-content: space-between;
    width: 100%;
    height:100%;
}

.webapp-coach-paint-menu > .webapp-coach-paint-menu-return {
    width: 20% !important;
}

.webapp-coach-paint-menu-return {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    width: 100%;

}

.paint-materials-dropdown-last-item {
    border: none !important
}

.webapp-coach-paint-menu-return button {
    border-radius: 50%;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    border:none;
    width: 2.75rem;
    height: 2.75rem;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.webapp-coach-paint-menu-name h1 {
    color: rgba(0, 0, 0, 0.60);
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;
}

.webapp-coach-paint-menu-name {
    display:flex;
    flex-direction: column;
    gap:1rem;
    align-items: flex-start;
}

.webapp-coach-paint-menu-name h2 {
    color: var(--Azul-Azul-Claro-Azul-2, #6978F9);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
}

.webapp-coach-paint-menu-name p {
    color: rgba(1, 3, 20, 0.60);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 137.188%;
    align-self: flex-start;
}


.webapp-coach-paint-menu-items {
    width: 75%;
    display: flex;
    height:100%;
    border-radius: 8px 8px 0px 0px;
    background: #FFFFFF;
    padding: 0.4rem 0;
    border-bottom: 3px solid var(--Azul-Azul-Claro-Azul-4, #CED3FE);
}



.webapp-coach-paint-menu-items-container {
    display:flex;
    border-right: 3px solid var(--Azul-Azul-Claro-Azul-4, #CED3FE);
    width: 58%;
    padding: 0.2rem 0.8rem;
    align-items: center;
    justify-content: space-between;
}

.webapp-paint-coach-materials-dropdown-container{
  position: relative;
}

.webapp-paint-coach-menu-dropdown, .webapp-paint-coach-materials-dropdown-container {
    display:flex;
    flex-direction: column;
    width: 20%;
    height:100%;
    justify-content: space-between;
    align-items: center;

}

.paint-bola-icon {
    padding:0.2rem;
    width: 2rem;
}
.webapp-paint-coach-menu-dropdown > span:first-child, .webapp-paint-coach-materials-dropdown-container > span:first-child {
    height: 30%;
}

.webapp-paint-background-select{
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 3px;
    border: 1px solid var(--Azul-Azul-Claro-Azul-5, #EBEDFF);
    height: 60%;
}

.webapp-paint-menu-dropdown-placeholder{
  display: flex;
  align-items: center;
  width: 100%;
  height: 50%;
  justify-content: space-between;
  padding: 0rem 0.5rem;
  border-radius: 3px;
  border: 1px solid var(--Azul-Azul-Claro-Azul-5, #EBEDFF);
}

.webapp-paint-stamp-placeholder{
  max-width: 50%;
}

.webapp-paint-coach-materials-dropdown-container > div > div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    border-radius: 3px;
    border: 1px solid var(--Azul-Azul-Claro-Azul-5, #EBEDFF);
    height: 70%;
    position: relative;
}

.webapp-coach-paint-materials-dropdown {
    position: absolute !important;
    top: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: fit-content !important;
    width: fit-content !important;
    padding: 0.2rem 0.6rem;
    z-index: 20;
    background-color: white;
    left: 0;
}

.coach-paint-materials-dropdown-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 0.4rem 0;
    cursor:pointer;
    border-bottom: 1px solid var(--Azul-Azul-Claro-Azul-4, #CED3FE);
}

.coach-paint-materials-dropdown-row > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.coach-paint-materials-dropdown-row img {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}

.webapp-paint-background-select > div:first-child {
    height: 100%;
    background-color: white;
    width:100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.2rem 0.1rem;
}
.webapp-paint-background-select > div > img:first-child {
    height: 100%;
    width: 80%;
    border-radius: 3px;
}

.webapp-paint-dropdown-arrow {
    height:20%;
    width: 20%;
    cursor:pointer;
}

.webapp-paint-menu-dropdown {
    position: absolute;
    top: 100%;
    width: 200%;
    padding: 0.2rem 0.3rem;
    z-index: 20;
    background-color: white;
}

.webapp-paint-menu-dropdown > div > img{
    width: 35%;
    height:80%;
    border-radius: 3px;
}

.webapp-paint-dropdown-item, .webapp-paint-dropdown-last-item{
    display: flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.6rem 0.6rem;
}



.webapp-paint-dropdown-item, .webapp-paint-dropdown-last-item {
    cursor: pointer;
    border-bottom: 1px solid rgba(2, 7, 50, 0.20);
}

.webapp-paint-coach-draggable-items {
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    align-items: center;
    height:100%;

}

.webapp-paint-coach-draggable-items > span:first-child {
    height: 30%;
}

.webapp-paint-coach-draggable-items > span:last-child {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.webapp-paint-coach-draggable-items > div {
    height: 70%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    border: 1px solid var(--Azul-Azul-Claro-Azul-5, #EBEDFF);
    background: #FFF;
}

.paint-draggable-items-baliza {
    display: flex;
    flex-direction: row !important;

}


.webapp-paint-menu-square-line{
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 3px solid var(--Azul-Azul-Claro-Azul-4, #CED3FE);
    padding: 0.2rem 0.8rem

}
.webapp-paint-menu-erase-pen {
    display: flex;
    padding: 0.2rem 0.8rem;
    align-items: center;
    justify-content: space-between;
    width: 30%;
}

.webapp-paint-grab-tool {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 3px solid var(--Azul-Azul-Claro-Azul-4, #CED3FE);
    padding: 0.2rem 0.8rem
}


.webapp-paint-menu-erase-pen > div, .webapp-paint-menu-square-line > div, .webapp-paint-grab-tool > div{
    display:flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

.webapp-paint-coach-draggable-items > span:last-child {
    cursor:pointer;
}

.webapp-paint-menu-erase-pen > div > span:first-child, .webapp-paint-menu-square-line > div > span:first-child, .webapp-paint-grab-tool > div > span:first-child {
    height: 30%;
}

.webapp-paint-menu-erase-pen > div > span:last-child, .webapp-paint-menu-square-line > div > span:last-child, .webapp-paint-grab-tool > div > span:last-child{
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.webapp-paint-menu-erase-pen span svg, .webapp-paint-menu-square-line span svg{
    width: 2rem;
    height: 2rem;
    color: #6978F9;
}

.webapp-paint-menu-erase-pen span svg:hover, .webapp-paint-menu-square-line span svg:hover, .webapp-paint-grab-tool span svg:hover {
    cursor: pointer;
}

.pen-color-change {
    border-radius: 20px;
    background: transparent;
    border: transparent;
    block-size: 32px;
    inline-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.pen-color-change::-webkit-color-swatch {
    border-radius: 20px;
}

.webapp-paint-menu-labels {
    color: var(--PRETO, #000);

    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }

.canvas-drawing-type-selected {
    border-radius: 4px;
    background: var(--azul-azul-claro-azul-4, #CED3FE);
}

.webapp-coach-paint-explaining-message {
    background-color: white;
    border-radius: 3px;
    padding: 0.2rem 0.4rem;
}


@media ( max-width: 1100px) {
    .webapp-coach-paint-menu-items {
        width: 100%;
        align-self: center;
    }
    .webapp-coach-paint-menu {
        flex-direction: column;
        justify-content: flex-start;
        gap: 5%;
    }
    .webapp-coach-paint-menu > .webapp-coach-paint-menu-return {
        width: 100% !important;
    }

    .webapp-coach-paint-menu-return {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        width:100%;
    }
    .webapp-coach-paint-menu-name {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: flex-start;
    }

    .webapp-coach-paint-menu-name div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
    }
}
