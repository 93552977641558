.filter-dropdown {
  /* display: flex; */
  color: var(--PRETO, #000);
  font-family: "Lato";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.filter-dropdown label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  cursor: pointer;
  /* display: block; */

  /* border-radius: 4px; */
}

.options-list{
  position: relative;
  height: fit-content;
  max-height: 10rem;
  width: 8rem;
  
  overflow-y: scroll;
  
  
  display: flex;
  flex-direction: column;
  
  position: absolute;

  padding: 1rem 0.6rem;
  border-radius: 8px;
  background-color: var(--BRANCO, #fff);
}

.options-list::-webkit-scrollbar {
  width: 8px;
}

.options-list::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-color: #898888;
  border-radius: 100px;
  background-clip: content-box;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

.options-list::-webkit-scrollbar-track {
  background-color: #EEEEEE;
  border-radius: 100px;

}

.option {
  color: var(--PRETO, #000);
  font-family: "Lato";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0.5rem 8px;
  /* margin: 0.2rem 0; */
  /* border-top: 1px solid #cccdd6;
  border-bottom: 1px solid #cccdd6; */
  /* border-radius: 4px; */
  cursor: pointer;
}

.option:hover {
  background-color: #ebedff;
}

.filter-selected {
  background-color: #ebedff;
}

.line-separation{
  height: 0.5px;
  color: rgba(2, 7, 50, 0.2);
}