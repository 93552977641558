.webapp-changepackages-page {
    width:100%;
    overflow: hidden;
    padding: 4vh 2vw;
    background: #F4F5FF;
    min-height:100vh;
    z-index: 0;
}

.webapp-paint-return h1{
    color: rgba(0, 0, 0, 0.60);
}

.webapp-changepackages-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    min-height:calc(100vh - 8vh - 1rem);
}

.webapp-changepackages-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
}

.webapp-changepackages-items h1 {
    font-size: 2rem;
    color: #EBEDFF;
    font-weight: 700;
    line-height: 2.375rem;
}

.changepackage-card{
    width: 26.7%;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
    padding: 1.563rem 0;
}

.current.changepackage-card {
    border-radius: 10px;
    border: 5px solid var(--Azul-Azul-Claro-Azul-1, #3C50FC);
    background: #EBEDFF;
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
}

.current button{
    cursor: auto;
    background: #3c4ffc49;
}


@media (max-width:1515px) {
    .package-name p {
        font-size: 1rem;
        text-align: left;
    }
    .preco-final {
        font-size: 1.5rem;
        line-height: normal;
    }
    .changepackage-card {
        width: 30%;
    }
}

.webapp-botao-comprar-changepackage {
    height: 2.5rem;
    border-radius: 6px;
    background: #3C50FC;
    border: none;
    display:flex;
    align-items: center;
    color: #FFFFFF ;
    font-family: 'Lato';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0.75rem 1.375rem;
}

.enable.webapp-botao-comprar-changepackage:hover {
    border: 1.5px solid var(--azul-azul-claro-azul-1, #3C50FC);
    background: var(--azul-azul-claro-azul-5, #EBEDFF);

    color: #3C50FC ;
    cursor:pointer;
}



.webapp-package-carousel-swipe {
    display: flex;
    justify-content: flex-end;
}


.webapp-packages-grid {
    display:flex;
    justify-content: space-between;
    width: 100%;
}

.carousel .slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel .slide{
    display: flex;
    align-items: center;
}

.webapp-packages-current-subscription{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    gap: 9.95%;
}

.webapp-packages-current-subscription-title{
    color: rgba(0, 0, 0, 0.60);
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%; /* 43.9px */
}

.month{
    grid-column: 1;
}

.semestre{
    grid-column: 2;
}

.year{
    grid-column: 3;
}

@media (max-width: 1100px) {
    .webapp-custom-changepackage-carousel .carousel .slider {
        width: 30% !important;
    }
}

@media ( max-width: 700px) {
    .webapp-custom-changepackage-carousel .carousel .slider {
        width: 40% !important;
    }
   
    .webapp-packages-items {
        gap: 2rem;
    }
}

@media ( max-width: 500px) {
    .webapp-packages-items {
        gap: 0rem;
    }
    .webapp-packages-items h1 {
        font-size: 1.825rem;
    }
   
    
}

@media ( max-width: 420px) {
    .webapp-register-icons {
        display:flex;
        gap: 0.8rem;
        height: 10vh;
        align-items: center;
        padding-left: 0.5rem;
        padding-top: 0.5rem;
    }
}

@media ( max-width: 380px) {
    .webapp-packages-grid .changepackage-card {
        width: 86vw;
    }
    .webapp-packages-items h1 {
        font-size: 1.5rem;
    }
}

@media (max-width: 1100px) {

   
    .control-dots {
        display: none;
    }
    .package-changecarousel-swipe {
        display: flex;
        justify-content: flex-end;
    }
    .package-name h2 {
        padding-left: 1.2rem;
    }
    .package-description {
        padding-left:1.2rem;
        padding-right: 1.2rem;
       
    }
    
    .package-info {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    
    }
    
    
    .package-info-key {
        font-size: 1rem;
        line-height: 1.25rem;
        text-align: left;
    }
    .free-trial-price {
        font-size: 1rem;
        line-height: normal;
    }
    .preco-final {
        font-size: 1.5rem;
        line-height: normal;
    }

    .botao-comprar {
        font-size: 1rem;
        line-height: normal;
        padding: 0.2rem 1.2rem;
    }
    .changepackage-card {
        width: 40vw;
    }
    .preco-final p {
        font-size: 1rem;
    }

    .custom-package-carousel .carousel .slider {
        width: 25% !important;
    }
    .package-carousel-swipe {
        padding-bottom: 3rem;

    }
    .package-carousel-swipe span{
        color: rgba(255, 255, 255, 0.70);
    }
    .swipe-arrow-right {
        font-size: 0.875rem;
        line-height: normal;
        height:17px;
    }

}
@media (max-width: 900px) {
   
    .packages-background-three {
        top:80%;   
    }
    .packages-page {
        width: 89.75vw;
        gap: 1.5rem;
        padding: 5vh 0;
    }
    .packages-page h1 {
        font-size: 1.5rem;
    }
    .package-name h2 {
        font-size: 1.5rem;
    }
}

@media ( max-width: 600px ) {
    .changepackage-card {
        width: 60vw;
    }
}
@media (max-width: 500px) {
    .changepackage-card {
        width: 70vw;
    }
}



