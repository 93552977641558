@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.chart-cardcombined02 {
  border-radius: 1.25rem;
  padding: 2rem;
  box-shadow: 0px 0px 10px 0px #0103140D;
  background: #FFF;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.chart-titlepos2 {
  color: var(--PRETO, #000);
  font-family: Lato;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  height: min-content;

}

.chart-wrapper-header{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.icon-person-text2{
  display: flex;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: right;
  gap: 0.5rem;
}

