.webapp-sessioninfo-content {
    width: 100%;
    padding: 4vh 2vw;
    min-height: 92vh;
    background: var(--Fundo-Website, #F4F5FF);
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
}

.webapp-ucstats-nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5vh;
    gap: 2rem;
}

.webapp-session-detail-menu-return {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.webapp-session-detail-menu-return button{
    border-radius: 50%;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    border: none;
    width: 2.75rem;
    height: 2.75rem;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.webapp-session-detail-menu-return h1 {
    color: rgba(0, 0, 0, 0.60);
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;
}

.webapp-session-detail-menu-return p {
    color: rgba(0, 0, 0, 0.60);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    align-self: flex-end;
}


.ongoing-box-ucstats {
    display: flex;
    align-items: center;
    align-self: flex-end;
    width: 130px;
    height: 27px;
    border-radius: 100px;
    border: 1px solid;
    background-color: #FF7D06;
    gap: 4%;
    color: #FFFFFF !important;
    padding-left: 10px;
}

.ongoing-box-ucstats span {
    font-size: 0.8rem;
}

.completed-box-ucstats {
    display: flex;
    align-items: center;
    align-self: flex-end;
    width: 130px;
    height: 27px;
    border-radius: 100px;
    border: 1px solid;
    background-color: #7FC035;
    ;
    gap: 4%;
    color: #FFFFFF !important;
    padding-left: 10px;
}

.completed-box-ucstats span {
    font-size: 0.8rem;
}


.webapp-coach-paint-menu-return>button {
    cursor: pointer;
}