.user-menu {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
    text-align: center;
    padding: 1.5rem;
    border-radius: 5px;
    position: absolute;
    gap:0.8rem;
    border: 0.01rem solid #d3d3d3;
}

.sep {
    gap: 0.5rem;
    display: flex;
    cursor: pointer;
}

.sep-open{
    transform: rotate(180deg);
}

.name-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 19px;
}



.logout-button {
    width: 100%;
    height: 2rem;
    color: white;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    border-radius: 6px;
    background: rgba(60, 80, 252, 1);
    border: 2rem none;
}

.edit-password-button {
    width: 100%;
    color: rgba(60, 80, 252, 1);
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    border-radius: 6px;
    background-color: white;
    border: 2rem none;
}

.edit-password-button:hover {
    background-color: rgba(235, 237, 255, 100%);
}

.divider {
    width: 100%;
    border: 0;
    border-top: 1px solid grey;
   
}