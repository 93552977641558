.game-info-container {
    background-color: white;
    width: 15rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 3px solid #6978F9;
    border-radius: 6px;
    gap:0.2rem;
    padding-bottom: 0.4rem;
    
}

.game-info-first {
    width: 100%;
    height: 4rem;
    display: flex;
    gap: 1rem;
    padding-left: 1rem;
    padding-top: 0.7rem;
    padding-right: 0.9rem;
}

.game-info-first-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.middle-bar {
    width: 3%;
    border: 1px;
    background-color: #6978F9;
    height: 80%;
    border-radius: 2px;
}


.game-info-first-right div {
    display: flex;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    gap: 0.3rem;
    color: black;
}

.game-info-first-right div img {
    width: 1rem;
}

.game-info-first-right {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.linha {
    position: relative;
    width: 100%;
    height: 0.1rem;
    background-color: grey;
}

.game-info-bottom {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.underline-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: grey;
    cursor: pointer;
    padding-left: 0.7rem;
    padding-top: 0.2rem;
}

.underline-text2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: grey;
    cursor: pointer;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    text-align: right;
}

.yellow-cards {
    gap: 1rem;
    display: flex;
    border: 2px solid #FFD702;
    border-radius: 15px;
    align-items: center;
    padding-right: 0.9rem;
    height: 1.5rem;
}

.cards-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.red-cards {
    gap: 1rem;
    display: flex;
    border: 2px solid #FA2E21;
    border-radius: 15px;
    align-items: center;
    padding-right: 0.9rem;
    height: 1.5rem;
}

.goals {
    gap: 1rem;
    display: flex;
    border: 2px solid #3C50FC;
    border-radius: 15px;
    align-items: center;
    padding-right: 0.9rem;
    height: 1.5rem;
}

.cards-left-number-yellow {
    width: 3rem;
    display: flex;
    background-color: #FFD702;
    border-radius: 15px;
    justify-content: center;
    height: 1.5rem;
}

.cards-left-number-red {
    width: 3rem;
    display: flex;
    background-color: #FA2E21;
    border-radius: 15px;
    justify-content: center;
    height: 1.5rem;
}

.cards-left-number-goals {
    width: 3rem;
    display: flex;
    background-color: #3C50FC;
    border-radius: 15px;
    justify-content: center;
    height: 1.5rem;
}

.show-more-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.goal-assist {
    gap: 1rem;
    display: flex;
    border: 2px solid #9DA7FD;
    border-radius: 15px;
    align-items: center;
    padding-right: 0.9rem;
    height: 1.5rem;
}

.cards-left-number-goal-assist {
    width: 3rem;
    display: flex;
    background-color: #9DA7FD;
    border-radius: 15px;
    justify-content: center;
    height: 1.5rem;
}

.goals-against {
  gap: 1rem;
  display: flex;
  border: 2px solid #6978F9;
  border-radius: 15px;
  align-items: center;
  padding-right: 0.9rem;
  height: 1.5rem
}

.cards-left-number-goals-against {
  width: 3rem;
  display: flex;
  background-color: #6978F9;
  border-radius: 15px;
  justify-content: center;
  height: 1.5rem;
}



.teams-info {
    padding: 0.7rem;
    display: flex;
}

.teams-info-container {
    padding-left: 0.4rem;
    display: flex;
    flex-direction: column;
    border-left: 7px solid #6978F9;
    border-radius: 6px;
    gap: 1rem;
}

.teams-info-container-group {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.teams-info-top-text {
    color: #040E64;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16.5px;
    line-height: 17px;
}

.teams-info-top-text2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
}