.webapp-trainings-page-change-folder-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: rgba(2, 7, 50, 0.40);
}

.webapp-change-folder-popup-container {
    padding: 1.8rem 1.5rem;
    height: fit-content;
    width: 25rem;
    z-index: 3;
    border-radius: 10px;
    background: var(--Branco, #FFF);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    /* shadow2 */
    box-shadow: 0px 0px 10px 0px rgba(1, 3, 20, 0.05);
}

.webapp-change-folder-popup-container h1 {
    color: rgba(0, 0, 0, 0.60);

    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 137.188%;
    /* 41.156px */
}

.webapp-change-name-folder-popup-input{
    border-radius: 2px;
    border: 1px solid var(--azul-azul-escuro-azul-9, #020732);
    height: 18%;
    padding: 0.5rem 0.875rem;
}

.webapp-change-folder-popup-container div {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    align-items: center;

}

.webapp-change-folder-button {
    border-radius: 6px;
    background: var(--azul-azul-claro-azul-1, #3C50FC);
    color: #FFF;
    border: none;
    padding: 0.75rem 1.375rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}