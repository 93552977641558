.page-confirm-player {
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 3rem;
    align-items: center;
}

.questionario-type-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    text-align: center;
    margin-bottom: 2rem;
}


.back-logo {
    display: flex;
    align-items: center;
    gap: 0.9rem;
    margin-right: 2rem;
}

.back-logo-text {
    color: rgba(0, 0, 0, 0.6);
    font-family: Lato;
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
}

.questions-type {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    width: 65%;
}

.forms-container {
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.insere-forms {
    justify-content: right;
    display: flex;
    align-items: flex-end;
    width: 80%;
    padding: 4rem 0rem 3rem 0rem;
}

.questions-type-button {
    width: 100%;
    height: 100%;
    color: rgba(60, 80, 252, 1);
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 6px;
    background: white;
    border: 0.74px solid rgba(60, 80, 252, 1);
    cursor: pointer;
}

.questions-type-buttonActive {
    width: 100%;
    height: 100%;
    color: white;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 6px;
    background: rgba(60, 80, 252, 1);
    border: none;
}

.sucess-box {
    height: 22.75rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    border-radius: 0.5rem;
}

.sucess-box-text {
    width: 18.375rem;
    font-family: Lato;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(0, 0, 0, 1);
}

.sucess-box-linha {
    width: 40vw;
    height: 0.74px;
    border: 1px solid #CED3FE;
}

.webapp-playerforms-buttons {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 1rem;
    justify-content: flex-end;
}

.webapp-playerforms-button {
    width: 100%;
    max-width: 12rem;
    height: 2.6rem;
    color: rgba(60, 80, 252, 1);
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    border-radius: 6px;
    background: rgba(244, 245, 255, 1);
    border: 0.74px solid rgba(60, 80, 252, 1);
    cursor: pointer;
}

.webapp-playerforms-button-active {
    color: white;
    background: rgba(60, 80, 252, 1);
    border: none;
}

.webapp-playerforms-button-enviar {
    margin-bottom: 2rem;
    color: white;
    background: rgba(60, 80, 252, 1);
    border: none;
    align-self: flex-end;
}

.seta-img {
    padding: 0 0 0 0.5rem;
    justify-content: center;
}

.webapp-playerforms-button-continuar {
    height: 3rem;
    color: white;
    text-align: center;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: rgba(60, 80, 252, 1);
    border: none;
    padding: 0 3rem;
    cursor: pointer;
}

.questionario-sent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    padding: 3rem;
    box-sizing: border-box;
    margin-top:5rem;
    color: #212529;
    background-color:rgba(235, 237, 255, 1);
    border-radius: 1rem;
    border: 2px solid rgba(157, 167, 253, 1)
}
.questionario-sent-text{
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    }


.webapp-home-page-login-button:hover,
.webapp-home-page-register:hover {
    cursor: pointer;
}


@media (max-width: 850px) {
    .webapp-logo {
        width: 80%;
    }

    .webapp-home-page-buttons {
        width: 40%;
    }
}

@media (max-width: 767px) {
    .forms-container {
        width: 95%;
    }

    .questionario-type-container{
        flex-direction: column;
        gap: 2rem;
        width: 100%;
        padding: 0 0.5rem;
    }
}

@media (max-width:500px) {
    .webapp-logo {
        width: 120%;
    }

    .webapp-home-page-buttons {
        width: 60%;
    }
}