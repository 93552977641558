.webapp-player-info{
    display: flex;
}

.webapp-player-info-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4rem 2rem;
    gap: 2rem;
}

.webapp-player-info-header{
    display: flex;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
}

.webapp-player-info-header h1{
    opacity: 0.6;
    color: black;
}

.webapp-player-info-content{
    display: flex;
    gap: 2rem;
}

.webapp-player-info-content-main{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
    padding: 3rem;
    background-color: white;
    border: 2px solid #CED3FE;
    border-radius: 1rem;
    max-width: 950px;
}

.webapp-player-info-content-main h1{
    color: #091DC9;
}

.webapp-player-info-content-main-info{
    display: flex;
    gap: 1rem;
}

.webapp-player-info-content-main-info-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    
}

.webapp-player-info-content-main-info-column:first-child{
    border-right: 2px solid rgba(0, 0, 0, 0.2);
    padding: 0 4rem 0 0;
} 

.webapp-player-info-content-main-info-column:last-child{
    padding: 0 0 0 4rem;
}

.webapp-player-info-content-main-info-column-item{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.webapp-player-info-content-main-info-column-item h4{
    color: #040E64;
}

.webapp-player-info-content-main-info-column-item p{
    height: 1.5rem;
}

.webapp-player-info-content-main-info-column-item input, .webapp-player-info-content-main-info-column-item select{
    border-radius: 0.25rem;
    background-color: #EBEDFF;
    border: none;
    text-align: center;
    height: 1.5rem;
}

.webapp-player-info-content-main-buttons{
    display: flex;
    gap: 1rem;
    align-self: flex-end;
}

.webapp-player-info-content-main-buttons-editmode{
    visibility: hidden;
}

.webapp-player-info-content-main-button{
    font-size: 1rem;
    border: none;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
}

.webapp-player-info-content-main-button:hover{
    text-decoration: underline;
    text-decoration-color: #3C50FC;
}

.webapp-player-info-content-button{
    padding: 0.5rem 1rem;
    border: 1px solid #3C50FC;
    border-radius: 0.25rem;
    background-color: #EBEDFF;
    color: #3C50FC;
    display: flex;
    align-items: center;
    height: fit-content;
    gap: 0.5rem;
}

.webapp-player-info-content-button img{
    height: 1rem;
    width: 1rem;
}